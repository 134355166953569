<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Vendors" title="Choices" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-7">
										<div class="mm-cart-text">
												<table >
													<thead style="font-size: medium;">
                                                        
                                                        <tr>
															<th>Vendor Name</th>
															<th style="text-align: left;">:</th>
															<td style="text-align: right;">{{ vendor.name }}</td>
														</tr> 
														
													</thead>
												</table>
                                           
                                        </div>
									</div>
									<div class="col-md-3">
										<router-link :to="{ name: 'add_choice', params: { id: vendor.id } }" class="btn btn-primary float-end">Add Choice</router-link>
                                    </div>

									<div class="col-md-2 float-end">
                                        <!-- <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                                    class='bx bx-chevrons-left'></i> Back</a> -->
										<router-link :to="{ name: 'vendors'}" class="btn btn-primary float-end">Back</router-link> 
                                    </div>
                                    
								</div>
							</div>

                            <!-- <div class="col-md-12 project-list filter-sort">
								<div class="row">
									
                                    <div class="col-md-12 float-end">
                                      
										<router-link :to="{ name: 'vendors'}" class="btn btn-primary float-end"><i class="icofont icofont-arrow-left"></i></router-link> 
                                    </div>
                                    
								</div>
							</div> -->

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-7 d-flex">
										<!-- <label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchVendorChoice()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label> -->
									</div>
									<div class="col-md-2">
										<!-- <select class="form-select" v-model="status" @change="fetchVendorChoice()">
											<option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Blocked</option>
										</select> -->
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchVendorChoice()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Id</th>
											<th>Choice</th>
											<th>Maximum Amount</th>
											<th>Options</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(vendor_choices.length)">
                                        <tr v-for="choice in vendor_choices" :key="choice.id" align="center">
                                            <td>{{ choice.id }}</td>
											<td>{{ choice.choice }}</td>
											
											<td>{{ choice.max_amount }}</td>
                                            <td>
												<button class="btn btn-primary btn-sm me-2 mb-2" @click="editChoice(choice)">Edit</button>    
												<a class="btn btn-danger btn-sm me-2 mb-2" @click="deleteChoice(choice)">Delete</a>
                                            </td>
                                        </tr>

									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Choices found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<!-- <nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fe(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword )" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav> -->
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

        <DynamicModal :title="modal_title">
			<template #body>
				<form @submit.prevent="updateChoice" enctype="multipart/form-data" @hidden="clearForm()">
					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Choice<span class="text-danger">*</span></label>
						<input class="form-control" type="text" placeholder="" v-model="choices.choice" v-bind:class="{ 'is-invalid': Boolean(errors.choice) }"/>
						<div v-for="(val, index) in errors.choice" :key="index" class="invalid-feedback">
						    <p class="mb-0">{{ val }}</p>
						</div>
						
					</div>
					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Maximum Amount<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" placeholder="" v-model="choices.max_amount" v-bind:class="{ 'is-invalid': Boolean(errors.max_amount) }"/>
						<div v-for="(val, index) in errors.max_amount" :key="index" class="invalid-feedback">
						    <p class="mb-0">{{ val }}</p>
						</div>
						
					</div>
					
					<div class="col-md-12 form-group mt-2">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Vendors Page",

        components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		
		data() {
			return {
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                modal_title: "Edit Choice",
				button_title: "Update",

				vendor_choices: [],

                vendor: {},

                choices: {
					id: "",
					choice: "",
					max_amount: "",
				},
				
				vendor_status: {
					id: "",
					status: "",
				},

				pagination: {},

			};
		},

		created() {
			this.fetchVendorChoice();
            this.fetchVendorDetails();
		},

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchVendorChoice(page_url = null) {
				this.getToken();
				let url = "/api/vendorChoices?vendor_id=" + this.$route.params.id + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.vendor_choices = responseData.vendor_choices;
						// this.pagination = responseData.vendors.links;
						
					})
					.catch((err) => console.log(err));
			},


            fetchVendorDetails(page_url = null) {
                this.getToken();
                let url = "/api/vendor_profile?vendor_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.vendor = responseData.vendors;

                    })
                .catch((err) => console.log(err));
            },
			
			updateChoice() {
				this.getToken();
				
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url = "api/updateVendorChoice";  
				this.loading = true;

				let formData = new FormData();
				formData.append("choice_id", this.choices.id);
                formData.append("choice", this.choices.choice);
                formData.append("max_amount", this.choices.max_amount);
					
				this.$axios
				.post(url, formData, config)
				.then((response) => {
					if (response.data.status) {
						this.$swal("", response.data.message, "success");
					} else {
						this.$swal("", response.data.message, "error");
					}
					this.clearForm();
					this.closeModal();
					this.fetchVendorChoice();
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
						this.errors = error.response.data.message;
                        this.$swal("", error.response.statusText, "error");
							
					} else {
						this.$swal("", "Something went wrong", "error");
					}
				});
			},

			openModal() {
				this.showModal();
				this.clearForm();
			},

			closeModal() {
				this.hideModal();
				this.clearForm();
			},

            editChoice(choice) {
				this.edit = true;
				this.choices.id = choice.id;
				this.choices.choice = choice.choice;
				this.choices.max_amount = choice.max_amount;
				
				this.modal_title = "Edit Choice";
				this.button_title = "Update";
				this.showModal();
			},

			deleteChoice(choice) {
				this.delete = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to delete this choice?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
						if(result.value){
							this.choice_id = choice.id;
							this.DeleteChoice();
						}
					});
			},

			DeleteChoice() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("api/deleteChoice?choice_id=" + this.choice_id, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchVendorChoice(this.active_page_url);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Edit Choice";
				this.button_title = "Add";
				this.choices.id = "";
				this.choices.choice = "";
				this.choices.max_amount = "";
				this.errors = {};
			},

		},
	};
</script>