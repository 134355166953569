<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="" title="Withdrawal Requests" />
		
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">
							
							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-3">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchWithdraw()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
                                    
                                    <div class="col-md-2">
                                        
                                    </div>

									<div class="col-md-3">
                                       
                                    </div>

                                    <div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchWithdraw()">
											<option value="">All</option>
                                            <option :value="1">Paid</option>
                                            <option :value="2">Pending</option>
											<option :value="3">Cancelled</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchWithdraw()"/>
									</div>
								</div>
							</div>
							
							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr>
											<th>ID</th>
											<th>Customer</th>              
											<th>Amount</th>
											<th>Status</th>
											<th>Requested On</th>
                                            <th>Options</th>
										</tr>
									</thead>
                                    <tbody v-if="Boolean(withdrawal_requests.length)">
                                        <tr v-for="withdrawal in withdrawal_requests" :key="withdrawal.id" align="center">
                                            <td>
                                                #{{ withdrawal.id }}
                                            </td>
											<td>
												<p>
                                                    {{ withdrawal.customer_name }}<br>
													{{ withdrawal.country_code }} {{ withdrawal.customer_mobile }} <br>
													{{ withdrawal.customer_email }}
                                                </p>
											</td>
												
                                            <td>{{ currency.currency }}{{ withdrawal.amount }}</td> 
											<td>
												<span class="badge rounded-pill bg-success" v-if="withdrawal.status==1">Paid</span>
                                 				<span class="badge rounded-pill bg-info" v-if="withdrawal.status==2">Pending</span>
												<span class="badge rounded-pill bg-danger" v-if="withdrawal.status==3">Rejected</span>
											</td>            
                                            <td>{{ $filters.formatDateTime(withdrawal.requested_date) }}</td>
                                            <td>
                                                <router-link :to="{ name: 'withdraw_request_details', params: { id: withdrawal.id } }" class="btn btn-primary mt-2 me-1 btn-sm">Details</router-link>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
									
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-3">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchWithdraw(page.url + '&limit=' + listing_length + '&keyword=' + keyword + '&status=' + status)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>

	export default {
		name: "User Page",
		
		data() {
			return {
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				withdrawal_requests: [],
				currency: [],
				
				pagination: "",
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
			this.fetchWithdraw();
			this.fetchCurrency();
		},

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchWithdraw(page_url = null) {
				this.getToken();
				let url = "/api/withdrawRequests?limit=" + this.listing_length +  "&keyword=" + this.keyword + "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.withdrawal_requests = responseData.withdrawal_requests.data;
						this.pagination = responseData.withdrawal_requests.links;
						
					})
					.catch((err) => console.log(err));
			},

			fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},
					
			clearForm() {
				this.edit = false;
				this.errors = {};
			},


		},
	};
</script>
    