<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
     <Breadcrumbs main="Users" title="Users Profile" />
         
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            
                            <div class="row mb-3">
                                <div class="col-12">

                                    <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>

                                </div>
                            </div>
                            
                            <div class="card">
                                <div class="card-body">

                                    <div class="table-responsive ">
                                        <table class="table mb-0">
                                            <tbody>

                                                <tr width="50%">
                                                    <th scope="row">User Id</th>
                                                    <th>:</th>
                                                    <td>#{{ users.id }}</td>

                                                </tr>

                                                <tr width="50%">
                                                    <th scope="row">Name</th>
                                                    <th>:</th>
                                                    <td>{{users.name}}</td>
                                                </tr>

                                                <tr>
                                                    <th scope="row">Contact</th>
                                                    <th>:</th>
                                                    <td>{{ users.country_code }} {{ users.mobile }} | {{ users.email }}</td>
                                                </tr>
 
                                                <tr>
                                                    <th scope="row">Status</th>
                                                    <th>:</th>
                                                    <td>
                                                        <span class="badge rounded-pill bg-success" v-if="users.status==1">Active</span>
                                                        <span class="badge rounded-pill bg-danger" v-else>Blocked</span>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row">Created On</th>
                                                    <th>:</th>
                                                    <td>{{ $filters.formatDateTime(users.converted_created_at) }}</td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="d-grid gap-2 mt-3">

                                            <button class="btn btn-primary" @click="openModal(users)">Change Password</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <DynamicModal title="Change Password">
			<template #body>
				<form @submit.prevent="UpdatePassword" enctype="multipart/form-data">

					<div class="row mt-2">
						<div class="col-md-12 form-group">
							<label class="control-label">New Password <span class="text-danger">*</span></label>
							<input class="form-control" v-model="password" type="text" placeholder="" />
						</div>
					</div>

					<div class="row mt-2">
						<div class="col-md-12 form-group">
							<label class="control-label">Confirm Password <span class="text-danger">*</span></label>
							<input class="form-control"  v-model="confirm_password" type="text" placeholder="" />
						</div>	
					</div>
					
					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >Update</button>
						<button class="btn btn-light" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

    
    </div>	
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

   export default {
		name: "User Profile Page",
        components: {
			DynamicModal
		},

		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		
		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,

				token: "",
				errors: {},
				activate: false,
         		deactivate: false,

         		active_page_url: null,

                users: {
                    id: "",
                },

                user_id: "",
                password: "",
				confirm_password: "",

			};
		},

        created() {
			this.fetchUserProfile();
		},

        methods: {

            getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchUserProfile(page_url = null) {
				this.getToken();
				let url = "/api/UserProfile?user_id=" + this.$route.params.id;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.users = responseData.users;
                        console.log(this.users);
						// this.pagination = responseData.users.links;
						
					})
					.catch((err) => console.log(err));
			},

            UpdatePassword() {
				this.getToken();
			
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url = "api/UpdateUserPassword";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("user_id", this.users.id);
				formData.append("password", this.password);
				formData.append("password_confirmation", this.confirm_password);

				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.fetchUserProfile(this.active_page_url);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									this.errors = error.response.data.message;
								}
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
			},
			
			openModal(users) {
				this.users.id = users.id;
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

            clearForm() {
				this.password = "";
				this.password_confirmation = "";
		
				this.errors = {};

			},


        }


   }


</script>