<template>
    <div>
        <Breadcrumbs main="" title="Settings" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">

					<div class="card">
						<div class="card-body">
							<div class="row">
								<h5>Admin details</h5>
							</div><br>
							
							<form @submit.prevent="updateProfile()" enctype="multipart/form-data">
								<div class="row">
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Name</label>
											<input class="form-control" type="text" placeholder="" v-model="admin.name" v-bind:class="{ 'is-invalid': Boolean(errors.name) }"/>
											<div v-for="(val, index) in errors.name" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Email</label>
											<input class="form-control" type="text" placeholder="" v-model="admin.email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }"/>
											<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									
								</div>

								<div class="row">
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Admin Image <span class="text-danger">(Leave this field empty if you don't want to add/edit the image)</span></label>
											
											<img v-if="preview_image" class="profile-image mt-2" :src="preview_image"/>
											<img v-else :src="$image_url + admin.profile_img" class="profile-image mb-2" alt="" />

											<!-- <img v-if="admin.profile_img" :src="$image_url + admin.profile_img" class="profile-image mb-2" alt="" /> -->
											<input ref="fileinput" type="file" @change="setFile($event)" accept="image/*" class="form-control image-class"  v-bind:class="{ 'is-invalid': Boolean(errors.image) }"/>
										
											<div v-for="(val, index) in errors.image" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									
									<div class="col-sm-4">
										
									</div>

									<div class="col-sm-2 float-end" style="margin-top: 30px;">
										<button type="submit" class="btn btn-primary  float-end">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="card">
						<div class="card-body">
							<div class="row">
								<h5>Change Password</h5>
							</div><br>
							
							<form @submit.prevent="updateSettings('password')">
								<div class="row">
									<div class="col-sm-5">
										<div class="mb-3">
											<label class="control-label">Password</label>
											<input class="form-control" type="password" placeholder="" v-model="password" v-bind:class="{ 'is-invalid': Boolean(errors.password) }"/>
											<div v-for="(val, index) in errors.password" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-5">
										<div class="mb-3">
											<label class="control-label">Confirm Password</label>														
											<input class="form-control" type="Password" placeholder="" v-model="confirm_password" v-bind:class="{ 'is-invalid': Boolean(errors.password_confirmation) }"/>
											<div v-for="(val, index) in errors.password_confirmation" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									
									<div class="col-sm-2" style="margin-top: 30px;">
										<button type="submit" class="btn btn-primary float-end">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="card" v-if="admin.role_id == 1">
						<div class="card-body">
							<div class="row">
								<div class="col-md-8">
									<h5>Other Details</h5>
								</div>
								<div class="col-md-4 float-end">
									<router-link :to="{ name: 'levels' }" class="btn btn-info float-end">Add Level Prices</router-link>
								</div>
							</div><br>
							
							<form @submit.prevent="updateSettings('other_details')">
								<div class="row">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Slider Video Link</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.video_url" v-bind:class="{ 'is-invalid': Boolean(errors.video_url) }"/>
											<div v-for="(val, index) in errors.video_url" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>

									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Welcome Screen Type</label>
											<select class="form-select" v-model="settings.splash_screen">
												<option value="">--Select--</option>
												<option value="1">Video</option>
												<option value="2">Image</option>
											</select>
										</div>
									</div>

									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Ticket Validity(In Day)</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.ticket_validity" v-bind:class="{ 'is-invalid': Boolean(errors.ticket_validity) }"/>
											<div v-for="(val, index) in errors.ticket_validity" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Splash Screen Video <span class="text-danger"></span></label>
											
											<input ref="fileinput" type="file" @change="setFile($event)" accept=".mp4" class="form-control image-class"  v-bind:class="{ 'is-invalid': Boolean(errors.video) }"/>
											
											<!-- <img v-if="preview_video" class="profile-image mt-2" :src="preview_image"/> -->
											<video v-if="settings.home_video" class="home-video mt-2" controls width="200">
												<source :src="$image_url + settings.home_video" type="video/mp4">
												Your browser does not support the video tag.
											</video>

											<video v-else class="home-video mt-2" controls width="200">
												<source :src="preview_video" type="video/mp4">
												Your browser does not support the video tag.
											</video>
											
											<div v-for="(val, index) in errors.video" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									
									<div class="col-sm-8 float-end" style="margin-top: 30px;">
										<div class="page-loader" v-if="!loading">
											<button type="submit" class="btn btn-primary float-end">Update</button>
										</div> 

										<div class="page-loader" v-else>
											<img src="@/assets/images/preloaderspin.gif"/>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="card" v-if="admin.role_id == 1">
						<div class="card-body">
							<div class="row">
								<h5>Maintenance mode</h5>
							</div><br>
							
							<form @submit.prevent="updateSettings('maintenance')" enctype="multipart/form-data">
								
								<div class="row mb-3">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Maintenance Android</label>
											<select v-model="settings.maintenance_android" class="form-select" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_android) }">
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
											<div v-for="(val, index) in errors.maintenance_android" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Maintenance Reason for Android</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.maintenance_reason_android" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_reason_android) }"/>
											<div v-for="(val, index) in errors.maintenance_reason_android" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-3">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Maintenance ios</label>
											<select v-model="settings.maintenance_ios" class="form-select" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_ios) }">
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
											<div v-for="(val, index) in errors.maintenance_ios" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Maintenance Reason for ios</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.maintenance_reason_ios" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_reason_ios) }"/>
											<div v-for="(val, index) in errors.maintenance_reason_ios" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
								</div>

								<div class="row mb-3">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Maintenance Website</label>
											<select v-model="settings.maintenance_web" class="form-select" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_web) }">
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
											<div v-for="(val, index) in errors.maintenance_web" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Maintenance Reason for Website</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.maintenance_reason_web" v-bind:class="{ 'is-invalid': Boolean(errors.maintenance_reason_web) }"/>
											<div v-for="(val, index) in errors.maintenance_reason_web" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
								</div>

								<div class="row float-end" >
									<div class="col-sm-1" style="margin-top: 30px;">
										<button type="submit" class="btn btn-primary">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="card" v-if="admin.role_id == 1">
						<div class="card-body">
							<div class="row">
								<h5>Version control</h5>
							</div><br>
							<form @submit.prevent="updateSettings('version')" enctype="multipart/form-data">
								<div class="row">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Android Application</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.play_store_version" v-bind:class="{ 'is-invalid': Boolean(errors.play_store_version) }"/>
											<div v-for="(val, index) in errors.play_store_version" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Force Update</label>
											<select v-model="settings.play_store_update" class="form-select" v-bind:class="{ 'is-invalid': Boolean(errors.play_store_update) }">
												<option value="">--Select--</option>
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">IOS Application</label>
											<input class="form-control" type="text" placeholder="" v-model="settings.app_store_version" v-bind:class="{ 'is-invalid': Boolean(errors.app_store_version) }"/>
											<div v-for="(val, index) in errors.app_store_version" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-sm-4">
										<div class="mb-3">
											<label class="control-label">Force Update</label>
											<select v-model="settings.app_store_update" class="form-select" v-bind:class="{ 'is-invalid': Boolean(errors.app_store_update) }">
												<option value="">--Select--</option>
												<option value="0">No</option>
												<option value="1">Yes</option>
											</select>
										</div>
									</div>
								</div>

								<div class="row float-end">
									<div class="col-sm-3 float-end" style="margin-top: 30px;">
										<button type="submit" class="btn btn-primary">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				
					<div class="card" v-if="admin.role_id == 1">
						<div class="card-body">
							<div class="row">
								<h5>Social media Links</h5>
							</div><br>
							<form enctype="multipart/form-data" >
							
								<div class="row" v-for="link in links" :key="link.id">
									<div class="col-md-6 mb-3 form-group">
										<label class="control-label">{{link.platform}}</label>
										<input class="form-control" v-model="link.link" v-bind:class="{ 'is-invalid': Boolean(errors.link) }" disabled/>

										<div v-for="(val, index) in errors.link" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									
									<div class="col-md-6">
										<button type="button" class="btn btn-primary me-2" style="margin-top: 30px;" @click="editLink(link)">Update</button>
									</div>

								</div>

							</form>
						</div>
					</div>

				</div>
			</div>
		</div>

		<DynamicModal :title=modal_title>
			<template #body>
				<form @submit.prevent="updateLink" enctype="multipart/form-data">

					<div class="col-md-12 form-group">
						<label class="control-label">{{link.platform}}<span class="text-danger">*</span></label>
						<input class="form-control" type="text" v-model="link.link" v-bind:class="{ 'is-invalid': Boolean(errors.link) }"/>

						<div v-for="(val, index) in errors.link" :key="index" class="invalid-feedback">
							<p class="mb-0">{{ val }}</p>
						</div>
						
					</div>

					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

		
	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "SettingsPage",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				loading: false,
				modal_title: "Update Social Media",
				button_title: "Update",

				android_version: null,
				android_version_update: "",
				android_version_partial_update: "",
				ios_version: null,
				facebook: "",
				twitter: "",
				instagram: "",
				x: "",
				name: "",
				email: "",
				phone: "",
				range:"",
				facebook:"https://www.facebook.com",
				youtube:"https://www.youtube.com",
				instagram:"https://www.instagram.com",
				x:"https://twitter.com/",
				maintenance: 0,
				maintenance_reason: "",
				password: "",
				confirm_password: "",
				link: "",
				amount: "",
				auto_approval: null,
				token: "",
				errors: {},

				settings: {
					id: "",
					address: "",
					vat: "",
					ticket_validity: "",
					tree_depth: null,
					commission_type: "",
					video_url: "",
					home_video:"",
					splash_screen:"",
					minimum_wallet_amount:"",
					maintenance_android:0,
					maintenance_ios: 0,
					maintenance_web: 0,
                    maintenance_reason_android: "",
                    maintenance_reason_ios: "",
					maintenance_reason_web: "",
					app_store_version: "",
					app_store_update: "",
					play_store_update: "",
					play_store_version: "",

				},

				image: "",
				preview_image: "",

				home_video: "",
				preview_video: "",

				admin: {
					user_id: "",
					role_id: "",
					name: "",
					profile_img: "",
					email: "",
					country_code: "",
					country_code_id: "",
					
				},

				links: [],

				link: {
					id: "",
					link: "",
				},

			};
		},

		created() {
			this.fetchSettings();
			this.fetchSocialMedia();

			this.videoUrl = this.$image_url + this.settings.home_video;
		},

		methods: {
			
			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchSettings(page_url = null) {
				this.getToken();
				let url = "/api/Settings";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.admin = responseData.admin;
						this.settings = responseData.settings;
						
					})
					.catch((err) => console.log(err));
			},

			fetchSocialMedia(page_url = null) {
				this.getToken();
				let url = "/api/getSocialMediaLink";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.links = responseData.link;
						
					})
					.catch((err) => console.log(err));
			},

			setFile(e) {
				this.image = e.target.files[0];
				this.preview_image = URL.createObjectURL(e.target.files[0]);

				this.home_video = e.target.files[0];
				this.preview_video = URL.createObjectURL(e.target.files[0]);
			},

			updateProfile() {
				this.getToken();
				this.errors = {};
				let formData = new FormData();
				formData.append("name", this.admin.name);
				formData.append("email", this.admin.email);
				formData.append("image", this.image);
				// formData.append("mobile", this.admin.mobile);
					const config = {
					headers: {
						"Content-type": "multipart/form-data",
						"Accept": "application/json",
						"Authorization": "Bearer " + this.token,
					},
					};
					this.loading = true;            
					
					this.$axios
					.post("api/UpdateAdmin", formData, config)
					.then((response) => {
						console.log(response);
						if (response.data.status) {
							var user = response.data.data.admin;
							console.log(user);
							var bestdeals_admin = {};
							if (localStorage.getItem('bestdeals_admin')) {
								bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin'));
							}

							bestdeals_admin.image = user.image;
							bestdeals_admin.name = user.name;
							bestdeals_admin.email = user.email;

							localStorage.setItem('bestdeals_admin', JSON.stringify(bestdeals_admin));
							
							this.$swal("", response.data.message, "success");
							// this.$router.push({ name: 'settings'});

						} else {
								this.$swal("", response.data.message, "error");
						}

						// this.clearForm();
						this.$router.go();  
						
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
							if (error.response.data) {
								this.errors = error.response.data.message;
							}
							this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
							this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
							this.$swal("", error.response.statusText, "error");
							this.$router.push({ name: "login" });
							} else {
							this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					})
					.finally(() => (this.loading = false));
			},

			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

			editLink(link) {
				this.link.id = link.id;
				this.link.platform = link.platform;
				this.link.link = link.link;

				this.errors = {};
				this.showModal();
			},

			updateLink() 
			{
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/UpdateSocialMedia";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("id", this.link.id); 
				formData.append("link", this.link.link); 
				
				this.$axios
				.post(url, formData, config)
				.then((response) => {
				if (response.data.status) {
				   this.$swal("", response.data.message, "success");
				} else {
				   this.$swal("", response.data.message, "error");
				}
				this.clearForm();
				this.closeModal();
				this.fetchSocialMedia();
				this.$router.push({ name: "settings" });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
						if (error.response.status == 422) {
							if (error.response.data) {
								this.errors = error.response.data.message;
							}
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 500) {
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 401) {
							this.$swal("", error.response.statusText, "error");
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					} else {
						this.$swal("", error.message, "error");
					}
				})
				.finally(() => (this.loading = false));
			},

			updateSettings(param) {
				this.getToken();
				let formData = new FormData();
				let url = "";
				if (param == "version") {
					formData.append("app_store_version", this.settings.app_store_version);
					formData.append("app_store_update", this.settings.app_store_update);
					formData.append("play_store_version", this.settings.play_store_version);
					formData.append("play_store_update", this.settings.play_store_update);

					url = "/api/UpdateVersionControl";
				
				} else if (param == "maintenance") {
					formData.append("maintenance_android", this.settings.maintenance_android);
					formData.append("maintenance_ios", this.settings.maintenance_ios);
					formData.append("maintenance_web", this.settings.maintenance_web);
					if(this.settings.maintenance_reason_android) {
						formData.append("maintenance_reason_android", this.settings.maintenance_reason_android);
					} else {
						formData.append("maintenance_reason_android", '');
					}
					if(this.settings.maintenance_reason_ios) {
						formData.append("maintenance_reason_ios", this.settings.maintenance_reason_ios);
					} else {
						formData.append("maintenance_reason_ios", '');
					}
					if(this.settings.maintenance_reason_web) {
						formData.append("maintenance_reason_web", this.settings.maintenance_reason_web);
					} else {
						formData.append("maintenance_reason_web", '');
					}
					
					url = "/api/UpdateMaintanance";
				
				} else if (param == "password") {
					formData.append("password", this.password);
					formData.append("password_confirmation", this.confirm_password);

					url = "/api/UpdatePassword";

				} else if (param == "other_details") {
					formData.append("ticket_validity", this.settings.ticket_validity);
					formData.append("video_url", this.settings.video_url);
					formData.append("home_video", this.home_video);
					formData.append("splash_screen", this.settings.splash_screen);

					url = "/api/UpdateOtherDetails";

				} else if (param == "media") {
					formData.append("youtube", this.settings.youtube);
					formData.append("facebook", this.settings.facebook);
					formData.append("instagram", this.settings.instagram);

					url = "/api/UpdateSocialMedia";

				} 

				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};

				// this.$router.go(); 
				this.$router.push({ name: "settings" });

				this.loading = true;
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchSettings();
						this.clearForm();
						if (param == "password") {
							this.password = "";
							this.confirm_password = "";
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									this.errors = error.response.data.message;
								}
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					})
					.finally(() => (this.loading = false));
			},
			clearForm() {
				this.password = "";
				this.password_confirmation = "";
				this.settings.maintenance_reason = "";
			
				this.image = "";
				this.preview_image = "";

				this.home_video = "";
				this.preview_video = "";

				this.errors = {};

			},


		},
	};
</script>