<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="CMS" title="FAQ" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add Faq</button>
									</div>
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchFAQs()">
												<option value="5">5</option>
												<option value="10">10</option>
												<option value="20">20</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchFAQs()">
											<option value="">All</option>
                                            <option :value=1>Active</option>
                                            <option :value=2>Blocked</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchFAQs()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
											<th>Question</th> 
											<th>Answer</th>   
											<th>Status</th>
											<th>Options</th>
										</tr>  
									</thead>

									<tbody v-if="Boolean(faqs.length)">
										<tr v-for="faq in faqs" :key="faq.id" align="center">
											<td>{{faq.question}}</td>
											<td> {{faq.answer}}</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="faq.status==1">Active</span>
												<span class="badge rounded-pill bg-danger" v-else>Blocked</span>
											</td>
											<td>
												<button class="btn btn-primary btn-sm me-2 mb-2" @click="editFaq(faq)">Edit</button>                       
												<button class="btn btn-danger btn-sm me-2 mb-2" @click="deactivateFaq(faq)" v-if="faq.status==1">Block</button>
												<button class="btn btn-success btn-sm me-2 mb-2" @click="activateFaq(faq)" v-else>Activate</button>
											</td>
										</tr>
									</tbody>

									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Faq found</td>
										</tr>
									</tbody>

								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="index">
									<a class="page-link" href="#" @click.prevent="fetchFAQs(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword)" tabindex="-1">
										<span v-if="index === 0">Prev</span>
										<span v-else-if="index === pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<DynamicModal :title="modal_title">
			<template #body>
				<form @submit.prevent="addFaq" enctype="multipart/form-data" @hidden="clearForm()">
					<div class="col-md-12 form-group">
						<label class="control-label">Question <span class="text-danger">*</span></label>
						<input class="form-control" type="text" placeholder="" v-model="question" v-bind:class="{ 'is-invalid': Boolean(errors.question) }"/>
							<div v-for="(val, index) in errors.question" :key="index" class="invalid-feedback">
								<p class="mb-0">{{ val }}</p>
							</div>
						
					</div>
					<div class="col-md-12 form-group">

					<label class="control-label">Answer<span class="text-danger">*</span></label>
							<textarea class="form-control" type="text" placeholder="" v-model="answer" v-bind:class="{ 'is-invalid': Boolean(errors.answer) }"></textarea>
								<div v-for="(val, index) in errors.answer" :key="index" class="invalid-feedback">
									<p class="mb-0">{{ val }}</p>
								</div>

					</div>

					<div class="col-md-12 form-group mt-2">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Faq Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				modal_title: "Add Faq",
				button_title: "Add",
				question:"",
				answer:"",
				keyword: "",
				status: "",
				showsearch: false,
				listing_length: 10,
				token: "",
        		faqs:[],
				errors: {},
				faq:{
					question:"",
					answer:"",
				},
				faq_status:{
					id:"",
					status:"",
				},
				faq_id:"",

                button_title: "Save",
				pagination: {},
				
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
      		this.fetchFAQs();
		},
		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
							
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
			},

			fetchFAQs(page_url = null) {
				this.getToken();
				let url = "/api/Faq?keyword=" + this.keyword + "&limit=" + this.listing_length+  "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
				.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
				.then((res) => {
					var responseData = res.data.data;
					console.log(responseData);
					this.faqs = responseData.faqs.data;
					this.pagination = responseData.faqs.links;
					
				})
				.catch((err) => console.log(err));
			},

	  		addFaq() {
				this.getToken();
				let formData = new FormData();
				formData.append("question", this.question);
				formData.append("answer", this.answer);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/AddFAQ";
					this.active_page_url = null;
				} else {
					url = "/api/UpdateFAQ";
					formData.append("id", this.faq_id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.fetchFAQs(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);

						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},

			activateFaq(faq) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to activate this Faq?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.faq_status.id = faq.id;
						this.faq_status.status = "1";
						this.updateFaqStatus();
					}
					
				});
			},

			deactivateFaq(faq) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to deactivate this Faq?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.faq_status.id = faq.id;
						this.faq_status.status = "2";
						this.updateFaqStatus();
					}
				});
			},

			updateFaqStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/UpdateFAQStatus?id=" + this.faq_status.id + "&status=" + this.faq_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchFAQs(this.active_page_url);					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},
			editFaq(faq) {
				this.edit = true;
				this.faq_id = faq.id;
				this.question = faq.question;
				this.answer = faq.answer;
				this.modal_title = "Edit Faq";
				this.button_title = "Update";
				this.showModal();
			},

			
			openModal() {
				this.showModal();
				this.edit = false;
			},

			closeModal() {
				this.hideModal();
				this.clearForm();
			},

            editSlider() {
                this.errors = {};
				this.modal_title = "Edit Faq";
				this.button_title = "Update";
				this.showModal();
            },
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add Faq";
				this.button_title = "Add";
				this.errors = {};
				this.question = "";
				this.answer = "";

			},

			

		},
	};
</script>