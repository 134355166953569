<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Campaign" title="Orders" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

                            <div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>
									</div>
								</div>
							</div>

                            <div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-9">
										<div class="mm-cart-text">
											<div class="col-md-12">
												<table >
													<thead style="font-size: medium;">
                                                        <tr>
															<th>
																<div class="mb-3" v-for="image in campaign_images" :key="image.id">
																	<div v-if="image.default_img == 1">
																		<img v-if="image.images" :src="$image_url + image.images" class="campaign-img mb-2" alt="" />
																	</div>
																</div>
                                                            </th>

															<td style="text-align: left;">
																<table >
																	<thead style="font-size: medium;">
																		<tr>
																			<th style="text-align: left;">Campaign Name</th>
																			<th style="text-align: left;">:</th>
																			<td style="text-align: left;">{{ campaign_detail.campaign_name }}</td>
																		</tr> 
																		<tr>
																			<th style="text-align: left;">Prize Name</th>
																			<th style="text-align: left;">:</th>
																			<td style="text-align: left;">{{ campaign_detail.prize_name }}</td>
																		</tr> 
																	</thead>
																</table>
															</td>
														
														</tr> 
														
													</thead>
												</table>
											</div>
                                           
                                        </div>
									</div>
                                    
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchCampaignOrders()">
												<option value="5">5</option>
												<option value="10">10</option>
												<option value="20">20</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchCampaignOrders()">
											<option value="">All</option>
                                            <option :value="1">Used</option>
                                            <option :value="2">Pending</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchCampaignOrders()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr>
                                            <th>Order Id</th>  
                                            <th>Customer Name</th>  
											<th>Contact</th>
											<th>Status</th>
											<th>Ordered On</th> 
											<th>Options</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(campaign_orders.length)">
                                        <tr v-for="order in campaign_orders" :key="order.id" align="center">
                                            <td>#{{ order.order_id }}</td>
                                            <td>{{ order.name }}</td>
											<td>{{ order.country_code }} {{ order.mobile }} <br>
												{{ order.email }}
											</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="order.status==1">Used</span>
                                 				<span class="badge rounded-pill bg-info" v-else>Pending</span>
											</td>
											<td>{{ $filters.formatDateTime(order.converted_created_at) }}</td>
                                            <td>
                                                <router-link :to="{ name: 'order_details', params: { id: order.order_id } }" class="btn btn-primary me-1 mb-2 btn-sm">Order Details</router-link>
                                            </td>
                                        </tr>
										
									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Campaign Orders found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchCampaignOrders(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword + '&campaign_id=' + this.$route.params.id)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: "Organization Page",
		
		data() {
			return {
				
				keyword: "",
				status: "",
				listing_length: 10,
				token: "",
				errors: {},


				currency: [],

				campaign_detail: {},
				campaign_orders: [],
				campaign_images: [],

				pagination: {},
				
			};
		},
		created() {
			this.fetchCampaignOrders();
			this.fetchCurrency();
		},
		methods: {

			getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchCampaignOrders(page_url = null) {
                this.getToken();
                let url = "/api/campaign_orders?campaign_id=" + this.$route.params.id + "&keyword=" + this.keyword + "&limit=" + this.listing_length+  "&status=" + this.status;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
						this.campaign_detail = responseData.campaign_detail;
						this.campaign_images = this.campaign_detail.images;
						this.campaign_orders = responseData.campaign_orders.data;
						console.log(this.campaign_orders);
						console.log(this.campaign_images);
                        this.pagination = responseData.campaign_orders.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},
			
			
			clearForm() {
				this.edit = false;
				
				this.errors = {};
			},

		},
	};
</script>