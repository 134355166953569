<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Campaigns" title="Completed Campaigns" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<!-- <div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<router-link :to="{ name: 'add_campaign' }" class="btn btn-primary me-1 mb-2 btn-sm">Add Campaign</router-link>
									</div>
								</div>
							</div> -->

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchCampaigns()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchCampaigns()">
											<option value="">All</option>
                                            <option :value="1">Active</option>
                                            <option :value="2">Blocked</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchCampaigns()" />
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr>
                                            <th>Image</th>  
                                            <th>Campaign & <br> Prize name</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Draw Date</th>
											<th>Limit</th>
											<th>Campaign Price</th>
											<th>Status</th>
											<th>Created On</th>
											<th>Updated On</th>
											<th>Options</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(campaigns.length)">
                                        <tr v-for="campaign in campaigns" :key="campaign.id">
                                            <td>
												<div class="mb-3" v-for="image in campaign.images" :key="image.id">
                                                    <div v-if="image.default_img == 1">
                                                        <img v-if="image.images" :src="$image_url + image.images" class="campaign-image mb-2" alt="" />
                                                    </div>
                                                    

                                                    <!-- <img src="@/assets/images/img/campaign1.png"  class="campaign-img"> -->
                                                </div>
                                                <!-- <img src="@/assets/images/img/campaign1.png"  class="campaign-img"> -->
                                            </td>
                                            <td>{{ campaign.campaign_name }} <br> {{ campaign.prize_name }}</td>
											<td>{{ $filters.formatDate(campaign.start_date) }}</td>
											<td>{{ $filters.formatDate(campaign.end_date) }}</td>
											<td>{{ $filters.formatDate(campaign.draw_date) }}</td>
											<td>{{ campaign.limit }}</td>
											<td>{{ currency.currency }}{{ campaign.campaign_price }}</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="campaign.status==1">Active</span>
                                 				<span class="badge rounded-pill bg-danger" v-else>Blocked</span>
											</td>
											<td>{{ $filters.formatDateTime(campaign.converted_created_at) }}</td>
											<td>{{ $filters.formatDateTime(campaign.converted_updated_at) }}</td>
                                            <td>
												<router-link :to="{ name: 'campaign_details', params: { id: campaign.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Details</router-link>
												<!-- <router-link :to="{ name: 'edit_campaign', params: { id: campaign.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Edit</router-link> <br> -->
												<router-link :to="{ name: 'campaign_orders', params: { id: campaign.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Orders</router-link>
												<router-link :to="{ name: 'campaign_tickets', params: { id: campaign.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Drawable Tickets</router-link> 
												<!-- <a class="btn btn-danger btn-sm  me-2 mb-2" @click="deactivateCampaign(campaign)" v-if="campaign.status == 1">Block</a>
												<a class="btn btn-success btn-sm  me-2 mb-2" @click="activateCampaign(campaign)" v-else>Activate</a> -->
                                            </td>
                                        </tr>

									</tbody>
                                    <tbody v-else>
										<tr>
											<td colspan="11" class="text-center">No Campaigns found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchCampaigns(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div>
</template>

<script>

	export default {
		name: "Campaign Page",
		
		data() {
			return {
				
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

				campaigns: [],
				currency: [],
				
				pagination: {},

				campaign_status: {
					id: "",
					status: "",
				},
         		
			};
		},
		created() {
			this.fetchCampaigns();
			this.fetchCurrency();

		},

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchCampaigns(page_url = null) {
				this.getToken();
				let url = "/api/completedCampaigns?keyword=" + this.keyword + "&limit=" + this.listing_length+  "&status=" + this.status ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.campaigns = responseData.campaigns.data;
						this.pagination = responseData.campaigns.links;
						
					})
					.catch((err) => console.log(err));
			},

			fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},
			
			activateCampaign(campaign) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to activate this Campaign?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.campaign_status.id = campaign.id;
						this.campaign_status.status = "1";
						this.updateCampaignStatus();
					}
					
				});
			},

			deactivateCampaign(campaign) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to block this Campaign?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.campaign_status.id = campaign.id;
						this.campaign_status.status = "2";
						this.updateCampaignStatus();
					}
				});
			},

			updateCampaignStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/campaign_status?campaign_id=" + this.campaign_status.id + "&status=" + this.campaign_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchCampaigns();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},

			
			clearForm() {
				this.edit = false;
				this.errors = {};
			},

		},
	};
</script>