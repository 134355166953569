<template>
	<div>
		  <Breadcrumbs main="Campaign" title="Edit Campaign" />
			 <div class="container-fluid">
				<div class="row">
				   <div class="col-sm-12">
					  <div class="card">
						 <div class="card-body">
 
							<div class="row mb-3">
							   	<div class="col-12">
									<a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
													class='bx bx-chevrons-left'></i> Back</a>
								</div>
							</div>
 
							<form  @submit.prevent="updateCampaign()" enctype="multipart/form-data">
								<div class="form theme-form">
									<div class="row">
										<div class="col-sm-12">
											<div class="mb-3">
												<label class="control-label">Campaign Name<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.campaign_name" v-bind:class="{ 'is-invalid': Boolean(errors.campaign_name) }"/>
                                                <div v-for="(val, index) in errors.campaign_name" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
									</div>
	
									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Product<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.product_name" v-bind:class="{ 'is-invalid': Boolean(errors.product_name) }"/>
                                                <div v-for="(val, index) in errors.product_name" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Product Price(AED) <span class="text-danger">*</span></label>
												<input class="form-control" type="number" placeholder="" min="2" v-model="campaign.campaign_price" v-bind:class="{ 'is-invalid': Boolean(errors.campaign_price) }"/>
                                                <div v-for="(val, index) in errors.campaign_price" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
									</div>

                                    <div class="row">
										<div class="col-sm-12">
											<div class="mb-3">
												<label class="control-label">Campaign Description<span class="text-danger">*</span></label>
												<textarea class="form-control" type="text" placeholder="" v-model="campaign.description" v-bind:class="{ 'is-invalid': Boolean(errors.description) }"></textarea>
                                                <div v-for="(val, index) in errors.description" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Prize Name<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.prize_name" v-bind:class="{ 'is-invalid': Boolean(errors.prize_name) }"/>
                                                <div v-for="(val, index) in errors.prize_name" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Sold Ticket View<span class="text-danger">*</span></label>
												<select class="form-select" v-model="campaign.sold_data_view">
													<option value="">--Select--</option>
													<option value="1">Percentage</option>
													<option value="2">Count</option>
													
												</select>
												<div v-for="(val, index) in errors.sold_data_view" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Start Date <span class="text-danger">*</span></label>
												<input class="form-control" type="date" placeholder="" v-model="campaign.start_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.start_date) }" :min="Today"/>
												<div v-for="(val, index) in errors.start_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">End date <span class="text-danger">*</span></label>
												<input
													class="form-control"
													type="date"
													placeholder=""
													v-model="campaign.end_date"
													:min="campaign.start_date"
													@keypress="numbersOnly"
													v-bind:class="{ 'is-invalid': Boolean(errors.end_date) }"
												/>
												<!-- <input class="form-control" type="date" placeholder="" v-model="campaign.end_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.end_date) }" :min="Today"/> -->
												<div v-for="(val, index) in errors.end_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>													
											</div>
										</div>
									</div>

                                    <div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Draw date <span class="text-danger">*</span></label>
												<input
													class="form-control"
													type="date"
													placeholder=""
													v-model="campaign.draw_date"
													:min="campaign.end_date"
													@keypress="numbersOnly"
													v-bind:class="{ 'is-invalid': Boolean(errors.draw_date) }"
												/>
												<!-- <input class="form-control" type="date" placeholder="" v-model="campaign.draw_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.draw_date) }" :min="Today"/> -->
												<div v-for="(val, index) in errors.draw_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>													
											</div>
										</div>
										
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Campaign Limit <span class="text-danger">*</span></label>
												<input class="form-control" type="number" placeholder="" v-model="campaign.limit" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.limit) }"/>
												<div v-for="(val, index) in errors.limit" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>												
											</div>
										</div>
										
									</div>

									<div class="row">
										<div class="row mb-3">
											<div class="col-2 mb-2 d-flex" v-for="(image, index) in images" :key="index">

												<div class="del-wrapper" style="position: relative">
													<!-- <a :href="$image_url + certificate.certificate" target="_blank" class="font-size-22"><p class="text-primary"><i class="fas fa-file-alt me-3"></i></p></a> --> 

													<a :href="$image_url + image.images" target="_blank"><img :src="$image_url + image.images" alt="" style="max-width: 100px"></a>
													<a href="#" class="del-icon text-danger" @click.prevent="deleteCampaign(index, image.id)"><i class="fa fa-times-circle"></i></a>
												</div>

											</div>
										</div>

										
                                        <div class="row mb-2"  v-for="(input, index) in inputs">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Images <span class="text-danger">*</span></label>
												<input class="form-control" type="file" @change="campaign_setFile($event, index)" placeholder=""/>
                                            </div>	

											<span class="ml-1 mt-2">
												<i class="fa fa-minus-circle text-danger" @click="deleteRow(index)" v-show="index || ( !index && inputs.length > 1)"></i>
												<i class="fa fa-plus-circle text-success" @click="addRow()" v-show="index == inputs.length-1"></i>
											</span>
                                        </div>
                                                                                
                                    </div>
		
										
									<div class="row mt-3">
										<div class="col-sm-12">
											<button class="btn btn-primary me-2 float-end" type="submit">Update</button>
											<!-- <router-link :to="{ name: 'registeredcustomer' }" class="btn btn-secondary me-2 float-end">Cancel</router-link> -->
										</div>
									</div>
	
								</div>
							</form>
						 </div>
					  </div>
				   </div>
				</div>
			 </div>
 
	</div>
 </template>

<script>

   export default {
		name: "CategoriesPage",
		

		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,

				token: "",
				errors: {},
				activate: false,
         		deactivate: false,

         		active_page_url: null,
                
                campaign_details: {},
                sold_data: {},
                images: [],

                currency: [],

				inputs: [
					{value: null, preview_image: null, image: null}
				],
				
				campaign_images: [],
				campaign_images_id: [],
				del_images_ids: [],

				image: "",
            	preview_image: "",

				vendors: [],

				campaign: {
					sold_data_view: "",
					campaign_name: "",
					product_name: "",
					campaign_price: "",
					description: "",
					prize_name: "",
					start_date: "",
					end_date: "",
					draw_date: "",
					limit: "",
				},

			};
		},

        created() {
			this.fetchCampaignDetails();
			this.fetchCurrency();
			this.fetchVendors();

		},

        methods: {

            getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

			fetchVendors(page_url = null) {
				this.getToken();
				let url = "/api/getVendors" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.vendors = responseData.vendors;
						
					})
					.catch((err) => console.log(err));
			},

            fetchCampaignDetails(page_url = null) {
                this.getToken();
                let url = "/api/campaign_details?campaign_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.campaign = responseData.campaign_details;
                        this.sold_data = responseData.sold_count;
                        this.images = this.campaign.images;
                        console.log(this.images);

                        // this.pagination = responseData.users.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

			addRow() {
              	this.inputs.push({ value: null });
			},

			deleteRow(index) {
				this.inputs.splice(index, 1);
				this.campaign_images.splice(index, 1);
			}, 

			campaign_setFile(e, index) {
				this.campaign_images.push(e.target.files[0]);
				this.inputs[index].image = e.target.files[0];
				this.inputs[index].preview_image = URL.createObjectURL(e.target.files[0]);
			},

			deleteCampaign(index, id) {
				this.del_images_ids.push(id);
				this.images.splice(index, 1);
			},

			setFile(e) {
				this.image = e.target.files[0];
				this.preview_image = URL.createObjectURL(e.target.files[0]);
			},

			updateCampaign() {
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/update_campaign";
				{
					{
					this.token;
					}
				}
				let formData = new FormData();
				formData.append("campaign_id", this.$route.params.id);
				formData.append("name", this.campaign.campaign_name);
				formData.append("product", this.campaign.product_name);
				formData.append("product_price", this.campaign.campaign_price);
				formData.append("description", this.campaign.description);
				formData.append("prize_name", this.campaign.prize_name);
				formData.append("sold_data_view", this.campaign.sold_data_view);
				formData.append("start_date", this.campaign.start_date);
				formData.append("end_date", this.campaign.end_date);
				formData.append("draw_date", this.campaign.draw_date);
				formData.append("limit", this.campaign.limit);

				formData.append('del_images_id', JSON.stringify(this.del_images_ids));
            

				for(var k = 0; k < this.campaign_images.length; k++) {
					let file = this.campaign_images[k];
					formData.append('images[' + k + ']', file);
				}

				this.$axios
					.post(url, formData, config)
					.then((response) => {
					if (response.data.status) {
					this.$swal("", response.data.message, "success");
					} else {
					this.$swal("", response.data.message, "error");
					}
					this.clearForm();
					this.$router.push({ name: "campaigns" });
					})
					.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
					} else {
							this.$swal("", "Something went wrong", "error");
					}
					});
			},

            openModal() {
                this.showModal();
            },

            closeModal() {
                this.hideModal();
            },

            changePassword(){
                this.clearForm();
                this.closeModal();
            },

			clearForm() {
			
				this.campaign.campaign_name = "";
				this.campaign.product_name = "";
				this.campaign.campaign_price = "";
				this.campaign.description = "";
				this.campaign.prize_name = "";
				this.campaign.sold_data_view = "";
				this.campaign.start_date = "";
				this.campaign.end_date = "";
				this.campaign.draw_date = "";
				this.campaign.limit = "";

				this.inputs = [
					{value: null, preview_image: null, image: null}
				];
				this.campaign_images = [];

				this.errors = {};

			},

        }

   }


</script>