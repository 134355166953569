<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="User" title="Wallet" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

                            <div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>
									</div>
								</div>
							</div>

                            <div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-9">
										<div class="mm-cart-text">
											<div class="col-md-9">
												<table >
													<thead style="font-size: medium;">
                                                        <tr>
															<th>Name</th>
															<th style="text-align: left;">:</th>
															<td style="text-align: right;">{{ user.name }}</td>
														
														</tr> 
														<tr>
															<th>Contact</th>
															<th style="text-align: left;">:</th>
															<td style="text-align: right;">{{ user.country_code }} {{ user.mobile }} | {{ user.email }}</td>
														</tr> 

														<tr>
															<th>Total Amount</th>
															<th style="text-align: left;">:</th>
															<td style="text-align: right;">{{currency.currency}} {{ user.wallet_amount }}</td>
														</tr> 
														
													</thead>
												</table>
											</div>
                                           
                                        </div>
									</div>
                                    
                                    <div class="col-md-3">
                                        <!-- <button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Update Wallet</button> -->
                                    </div>
								</div>
							</div>

							<!-- <div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add Course</button>
									</div>
								</div>
							</div> -->

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchUserWallet()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="type" @change="fetchUserWallet()">
											<option value="">All</option>
                                            <option value="1">Credit</option>
                                            <option value="2">Debit</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchUserWallet()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
											<th>Type</th>
                                            <th>Amount Type</th>  
											<th>Date</th>
											<th>Amount</th> 
											<th>Balance</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(wallet_logs.length)">
                                        <tr v-for="wallet in wallet_logs" :key="wallet.id" align="center">
                                            <td>
												<span class="recent-status font-success" v-if="wallet.type==1">Credit</span>
                                 				<span class="recent-status font-danger" v-else>Debit</span>
											</td>
                                            <td>
												<span class="recent-status font-success" v-if="wallet.amount_type==1">Referral</span>
                                 				<span class="recent-status font-info" v-if="wallet.amount_type==2">Deposit</span>
												 <span class="recent-status font-secondary" v-if="wallet.amount_type==3">Withdrawal</span>
                                 				<span class="recent-status font-primary" v-if="wallet.amount_type==4">Purchase</span>
												 <span class="recent-status font-danger" v-if="wallet.amount_type==5">Wallet Update</span>
											</td>
											<td>{{ $filters.formatDateTime(wallet.created_at) }}</td>
											<td>{{currency.currency}}{{ wallet.amount }}</td>
                                            <td>{{currency.currency}}{{ wallet.balance }}</td>
                                        </tr>

									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Transactions found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchUserWallet(page.url + '&limit=' + listing_length + '&type=' + type + '&keyword=' + keyword + '&user_id=' + this.$route.params.id)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<DynamicModal title="Update Wallet">
			<template #body>
				<form @submit.prevent="" enctype="multipart/form-data">
					<div class="row mb-2">
						<div class="col-md-12 form-group">
							<label class="control-label">Type <span class="text-danger">*</span></label>
							<select class="form-select">
								<option value="">--select--</option>
                                <option value="1">Credit</option>
                                <option value="2">Debit</option>
							</select>
						</div>						
					</div>
                   
					<div class="row mb-2">
						<div class="col-md-12 form-group">
							<label class="control-label">Amount<span class="text-danger">*</span></label>
							<input class="form-control" type="number" placeholder="" />
						</div>						
					</div>
					
					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >Update</button>
						<button class="btn btn-light" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Organization Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				modal_title: "Add Course",
				button_title: "Add",
				
				keyword: "",
				type: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				currency: [],
				wallet_logs: [],
				user: {},
				pagination: {},
				
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
			this.fetchUserWallet();
			this.fetchCurrency();

		},
		methods: {

			getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchUserWallet(page_url = null) {
                this.getToken();
                let url = "/api/UserWallet?user_id=" + this.$route.params.id + "&keyword=" + this.keyword + "&limit=" + this.listing_length+  "&type=" + this.type;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
						this.user = responseData.user;
						this.wallet_logs = responseData.wallet_logs.data;
						// this.campaign_orders = responseData.campaign_orders.data;
						// console.log(this.campaign_orders);
                        this.pagination = responseData.wallet_logs.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},
			
			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

            editCourse() {
                this.errors = {};
				this.modal_title = "Edit Course";
				this.button_title = "Update";
				this.showModal();
            },
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add Course";
				this.button_title = "Add";
				this.errors = {};
			},

			

		},
	};
</script>