<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Sliders" title="Sliders" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add Slider</button>
									</div>
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchSliders()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchSliders()">
											<option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Blocked</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchSliders()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Id</th>  
                                            <th>Sliders</th>
											<th>Type</th>
											<th>Device Type</th>
											<th>Status</th>
											<th>Created On</th>
											<th>Last Updated On</th>
                                            <th>Options</th>  
                                        </tr>
									</thead>
									<tbody v-if="Boolean(sliders.length)">
                                        <tr v-for="slider in sliders" :key="slider.id" align="center">
                                            <td>{{ slider.id }}</td>
                                            <td>
												<img v-if="slider.image" :src="$image_url + slider.image" class="slider-img" alt="" />
                                            </td>
											<td>
												<span v-if="slider.type == 1">Main Slider</span>
												<span v-if="slider.type == 2">Bottom Slider</span>
											</td>
											<td>
												<span v-if="slider.device_type == 1">Big Screen</span>
												<span v-if="slider.device_type == 2">Tablet</span>
												<span v-if="slider.device_type == 3">Mobile</span>
											</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="slider.status == 1">Active</span>
												<span class="badge rounded-pill bg-danger" v-if="slider.status == 2">Blocked</span>
											</td>
											<td>{{ $filters.formatDateTime(slider.created_at) }}</td>
											<td>{{ $filters.formatDateTime(slider.updated_at) }}</td>
                                            <td>
                                                <button class="btn btn-primary btn-sm me-2 mb-2" @click="editSlider(slider)">Edit</button>    
												<a class="btn btn-danger btn-sm me-2 mb-2" @click="deactivateSlider(slider)" v-if="slider.status == 1">Block</a>
												<a class="btn btn-success btn-sm me-2 mb-2" @click="activateSlider(slider)" v-else>Activate</a>                   
                                                <!-- <button class="btn btn-danger btn-sm me-2 mb-2" >Delete</button> -->
                                            </td>
                                        </tr>

                                    </tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Sliders found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchSliders(page.url + '&limit=' + listing_length + '&status=' + status )" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<DynamicModal :title="modal_title">
			<template #body>
				<form @submit.prevent="addSlider" enctype="multipart/form-data" @hidden="clearForm()">
					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Slider Type<span class="text-danger">*</span></label>
						<select class="form-select" v-model="sliders.type" v-bind:class="{ 'is-invalid': Boolean(errors.type) }">
							<option value="">--select--</option>
							<option value="1">Main Slider</option>
							<option value="2">Bottom Slider</option>
						</select>
						<div v-for="(val, index) in errors.type" :key="index" class="invalid-feedback">
                        	<p class="mb-0">{{ val }}</p>
                        </div>
						
					</div>
					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Device Type<span class="text-danger">*</span></label>
						<select class="form-select" v-model="sliders.device_type" v-bind:class="{ 'is-invalid': Boolean(errors.device_type) }">
							<option value="">--select--</option>
							<option value="1">Big Screen</option>
							<option value="2">Tablet</option>
							<option value="3">Mobile</option>
						</select>
						<div v-for="(val, index) in errors.device_type" :key="index" class="invalid-feedback">
                        	<p class="mb-0">{{ val }}</p>
                        </div>
						
					</div>
					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Slider <span class="text-danger">*</span></label>
						<input ref="fileinput" type="file" @change="setFile($event)" accept="image/*" class="form-control image-class" v-bind:class="{ 'is-invalid': Boolean(errors.image) }"/>
                                                
                        <img v-if="preview_image" class="profile-image mt-2" :src="preview_image"/>
                    	<img v-else-if="sliders.image" :src="$image_url + sliders.image" class="profile-image mt-2" alt="" />
						
					</div>

					<div class="col-md-12 form-group mt-2">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Pincode Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				modal_title: "Add Slider",
				button_title: "Add",
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				sliders: [],

				slider_status: {
					id: "",
					status: "",
				},

				sliders: {
					id: "",
					device_type: "",
					type: "",
					image: "",
				},

				image: "",
                preview_image: "",

				pagination: {},
				
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
			this.fetchSliders();
		},
		methods: {

			getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

			fetchSliders(page_url = null) {
				this.getToken();
				let url = "/api/Sliders?limit=" + this.listing_length+ "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.sliders = responseData.sliders.data;
						this.pagination = responseData.sliders.links;
						
					})
					.catch((err) => console.log(err));
			},

			setFile(e) {
                this.image = e.target.files[0];
                this.preview_image = URL.createObjectURL(e.target.files[0]);
            },

			addSlider() {
				this.getToken();
				let formData = new FormData();
				formData.append("image", this.image);
				formData.append("device_type", this.sliders.device_type);
				formData.append("type", this.sliders.type);
			
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/NewSlider";
					this.active_page_url = null;
				} else {
					url = "/api/UpdateSlider";
					formData.append("slider_id", this.sliders.id);
					// if(this.image) {
					// 	formData.append("image", this.image);
					// } else {
					// 	formData.append("image", this.sliders.image);
					// }
					formData.append("image", this.image);
					
					formData.append("device_type", this.sliders.device_type);
					formData.append("type", this.sliders.type);
					
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.fetchSliders(this.active_page_url);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									this.errors = error.response.data.message;
								}
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
			},

			activateSlider(slider) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to activate this Slider?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.slider_status.id = slider.id;
						this.slider_status.status = "1";
						this.updateSliderStatus();
					}
					
				});
			},

			deactivateSlider(slider) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to block this Slider?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.slider_status.id = slider.id;
						this.slider_status.status = "2";
						this.updateSliderStatus();
					}
				});
			},

			updateSliderStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/UpdateSliderStatus?slider_id=" + this.slider_status.id + "&status=" + this.slider_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchSliders();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},
			
			openModal() {
				this.showModal();
				this.clearForm();
			},

			closeModal() {
				this.hideModal();
				this.clearForm();
			},

            editSlider(slider) {
				this.edit = true;
				this.sliders.id = slider.id;
				this.sliders.image = slider.image;
				this.sliders.device_type = slider.device_type;
				this.sliders.type = slider.type;
				
				this.modal_title = "Edit Slider";
				this.button_title = "Update";
				this.showModal();
			},
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add Slider";
				this.button_title = "Add";
				this.sliders.id = "";
				this.sliders.image = "";
				this.sliders.device_type = "";
				this.sliders.type = "";
				this.errors = {};
			},

		},
	};
</script>