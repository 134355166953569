<template>
	<div>
		  <Breadcrumbs main="Organization" title="Assign Course" />
			 <div class="container-fluid">
				<div class="row">
				   <div class="col-sm-12">
					  <div class="card">
						 <div class="card-body">
 
							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-6">
										<div class="mm-cart-text">
											<table>
												<thead style="font-size: medium;">
                                                    <tr>
														<th>Name</th>
														<th style="text-align: left;">:</th>
														<td style="text-align: right;">{{ sub_admin.name }}</td>
													</tr> 
														
												</thead>
											</table>
                                           
                                        </div>
									</div>
                                    <div class="col-md-3">

                                    </div>
                                    <div class="col-md-1">
                                        <div class="example-popover btn btn-secondary float-end btn-sm" type="button" data-container="body" data-bs-toggle="tooltip" data-bs-placement="top" title="Change Password" @click="openModal(sub_admin)"><i class="icofont icofont-key"></i></div>
									</div>
									<div class="col-md-2">
                                        <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i class='bx bx-chevrons-left'></i> Back</a>
                                    		<!-- <button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add Lesson</button> -->
									</div>
								</div>
							</div>
 
							<!-- <form  enctype="multipart/form-data"> -->
								<div class="form theme-form">

                                    <div class="table-responsive product-table">
                                        <table class="display" id="basic-1">
                                            <thead>
                                                <tr align="center">
                                                    <!-- <th>
                                                        <input type="checkbox" v-model="selectAll" @change="toggleAllSelection" />
                                                    </th> -->
                                                    <th>Sl. No</th>
                                                    <th>Modules</th>  
                                                    <th> 
                                                        Permissions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="Boolean(all_modules.length)">
                                                <tr v-for="(all_module, index) in all_modules" :key="all_module.id" align="center">
                                                  
                                                    <td>#{{ from + index }}</td>
                                                    <td>{{ all_module.module }}</td>
                                                    <td>
                                                        <input type="checkbox" v-model="selected" :value="all_module.id" />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                            
									<div class="row mt-3">
										<div class="col-sm-12">
											<button class="btn btn-success me-3 float-end" @click="updateSubAdminModules(selected)">Save</button>
										</div>
									</div>
	
								</div>
							<!-- </form> -->
						 </div>
					  </div>
				   </div>
				</div>
			 </div>


            <DynamicModal :title="modal_title">
                <template #body>
                    <form @submit.prevent="UpdatePassword" enctype="multipart/form-data">

                        <div class="row mt-2">
                            <div class="col-md-12 form-group">
                                <label class="control-label">New Password <span class="text-danger">*</span></label>
                                <input class="form-control" v-model="password" type="password" placeholder="" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12 form-group">
                                <label class="control-label">Confirm Password <span class="text-danger">*</span></label>
                                <input class="form-control"  v-model="confirm_password" type="text" placeholder="" />
                            </div>	
                        </div>

                        <div class="col-md-12 form-group mt-3">
                            <button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
                            <button class="btn btn-light" type="button" @click="closeModal()">Close</button>
                        </div>
                    </form>
                </template>
            </DynamicModal>
 
	</div>
 </template>


<script>
import {
    Tooltip
} from 'bootstrap/dist/js/bootstrap.esm.min.js'
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

export default {
    name: "Assign Users Page",

    components: {
		DynamicModal
	},
	setup(){
		onMounted(() => {
			dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
		})

		const showModal = () => {
			dynamicmodal.show();
		}

		const hideModal = () => {
			dynamicmodal.hide();
		}

		return {
			showModal, hideModal
		}
	},
    

    data() {
        return {
            
            keyword: "",
            showsearch: false,
            token: "",
            errors: {},

            users: [],

            modules: [],
            sub_admin_modules: [],
            all_modules: [],
            sub_admin: {},

            listing_length: 50,
            keyword: "",
            selected:[],

            user_id: "",
            password: "",
			confirm_password: "",

            modal_title: "Change Password",
			button_title: "Update",
             // delete: false,
             // active_page_url: null,
        };
    },

    created() {
        this.fetchModules();

    },

    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        })
    },
    unmounted() {

        let popovers = document.getElementsByClassName('tooltip')
    },
    
    methods: {

        getToken() {
            if (localStorage.getItem('bestdeals_admin')) {
                var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                if(bestdeals_admin.token) {

                    this.token = bestdeals_admin.token;
                    this.user = bestdeals_admin;

                } else {
                    this.$swal("", "Failed to fetch token, please login again", "error");
                    this.$router.push({ name: "login" });
                }

            } else {
                this.$swal("", "Session expired, please login again", "error");
                this.$router.push({ name: "login" });
            }
        },

        fetchModules(page_url = null) {
			let url = "/api/subAdminModules?&id=" + this.$route.params.id + "&limit=" + this.listing_length; 
			page_url = page_url || url;

			this.getToken();
			this.$axios
			.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
			.then((res) => {
				var responseData = res.data.data;
				console.log(responseData);
                this.sub_admin_modules = responseData.subadmin_modules;
                this.selected = [];

                for (var i = 0; i < this.sub_admin_modules.length; i++) 
                {
                    this.selected.push(
                        this.sub_admin_modules[i].module_id
                    );
                }
                this.sub_admin = responseData.sub_admin;

                this.all_modules = responseData.all_modules.data;
                this.pagination = responseData.all_modules.links;
                this.from = responseData.all_modules.from;
                this.to = responseData.all_modules.to;
                this.total = responseData.all_modules.total;
				// this.users = responseData.users;
												
			})
			.catch((err) => console.log(err));
		},

        updateSubAdminModules(selected) 
        {
            this.getToken();
            this.errors = {};

            const config = {
                headers: {
                "Content-type": "multipart/form-data",
                Accept: "application/json",
                Authorization: "Bearer " + this.token,
                },
            };

            let url = "api/sub_admin/add_modules";

            let formData = new FormData();

            formData.append("subadmin_id", this.$route.params.id);
            var i = 0;
            selected.forEach((obj) => {
                formData.append("modules[" + i + "]", obj);
                i++;
            });

            this.$axios
                .post(url, formData, config)
                .then((response) => {
                    if (response.data.status) {
                        this.$swal("", response.data.message, "success");
                        this.fetchModules();
                    } else {
                        this.$swal("", response.data.message, "error");
                    }
                    this.clearForm();
                })
                .catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                    if (error.response.data) {
                        this.errors = error.response.data.message;
                        console.log(this.errors);
                    }
                    this.$swal("", error.response.statusText, "error");
                    } else if (error.response.status == 500) {
                    this.$swal("", error.response.statusText, "error");
                    } else if (error.response.status == 401) {
                    this.$swal("", error.response.statusText, "error");
                    this.$router.push({ name: "login" });
                    } else {
                    this.$swal("", "Something went wrong", "error");
                    }
                } else {
                    this.$swal("", error.message, "error");
                }
                })
                .finally(() => (this.loading = false));
        },

        openModal(sub_admin) {
			this.sub_admin.user_id = sub_admin.user_id;
			this.showModal();
		},

		closeModal() {
			this.hideModal();
		},

        UpdatePassword() {
			this.getToken();

			let formData = new FormData();
			formData.append("user_id", this.sub_admin.id);
			formData.append("password", this.password);
			formData.append("password_confirmation", this.confirm_password);

			const config = {
				headers: {
					"content-type": "multipart/form-data",
					Authorization: "Bearer " + this.token,
				},
			};
			let url = "api/UpdateSubadminPassword";
			{
				{
					this.token;
				}
			}

			this.$axios
			.post(url, formData, config)
			.then((response) => {
				if (response.data.status) {
					this.$swal("", response.data.message, "success");
				} else {
					this.$swal("", response.data.message, "error");
				}
				this.clearForm();
				this.closeModal();
				this.fetchModules(this.active_page_url);
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				if (error.response) {
					if (error.response.status == 422) {
						if (error.response.data) {
                            if (error.response.data.message) 
							{
                                if (error.response.data.message.password) 
							    {
                                    this.errors = error.response.data.message.password[0];
                                    this.$swal("", error.response.data.message.password[0], "error");
                                } 
                                else if(error.response.data.message.password_confirmation) 
							    {
                                    this.errors = error.response.data.message.password_confirmation[0];
                                    this.$swal("", error.response.data.message.password_confirmation[0], "error");
                                }
							}
							else {
								this.errors = error.response.data.message;
								this.$swal("", error.response.statusText, "error");
							}
							// this.errors = error.response.data.message;
						}
						// this.$swal("", error.response.statusText, "error");
					} else if (error.response.status == 500) {
						this.$swal("", error.response.statusText, "error");
					} else if (error.response.status == 401) {
						this.$swal("", error.response.statusText, "error");
					} else {
						this.$swal("", "Something went wrong", "error");
					}
				} else {
					this.$swal("", error.message, "error");
				}
			});
		},

       
        clearForm() {
			this.password = "";
			this.confirm_password = "";
			this.errors = {};
		},

    },
};

</script>