<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Campaign" title="Campaign Details" />
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                
                                <div class="row mb-3">
                                    <div class="col-12">

                                        <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                                class='bx bx-chevrons-left'></i> Back</a>

                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-xxl-5 col-md-5 box-col-5">
                                        <div class="appointment">
                                            <div class="dashboard-card card">
                                                <div class="card-header card-no-border">
                                                    <h5 class="m-0">Campaign Progress</h5>
                                                </div>
                                                <div class="card-body pt-0 mt-3">
                                                    <div class="dashboard-table table-responsive">
                                                        <div class="monthly-profit">
                                                            <apexchart height="271.3" type="donut" :options="ecomDashboard.options1" :series="ecomDashboard.series1"></apexchart>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="media">
                                                <div class="media-body">
                                                    <div class="mt-2 me-2 mb-2 ms-2"> 
                                                        <div class="row mt-3">
                                                            
                                                            <div class="table-responsive" style="margin-left: 5px; margin-right: 5px;">
                                                                <table width='100%' cellspacing='1' cellpadding='2'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Start Date</td>
                                                                            <td>:</td>
                                                                            <td>{{ $filters.formatDate(campaign_details.start_date) }}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>End Date</td>
                                                                            <td>:</td>
                                                                            <td>{{ $filters.formatDate(campaign_details.end_date) }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Draw Date</td>
                                                                            <td>:</td>
                                                                            <td>{{ $filters.formatDate(campaign_details.draw_date) }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Limit</td>
                                                                            <td>:</td>
                                                                            <td>{{ campaign_details.limit }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Campaign Price</td>
                                                                            <td>:</td>
                                                                            <td> {{ campaign_details.campaign_price }} {{ currency.currency }}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Sold Ticket View</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <span v-if="campaign_details.sold_data_view==1">Percentage</span>
                                                                                <span v-else>Count</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>

                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    

                                    <div class="col-lg-7 col-md-7">
                                        <div class="card">
                                            <h4 class="font-size-16 border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">
                                                Campaign Details
                                                <router-link :to="{ name: 'edit_campaign', params: { id: campaign_details.id } }" class="btn btn-light float-end">Edit</router-link>

                                            </h4>
                                            <div class="campaign-details">
                                                <div class="mb-3">
                                                    <h5>{{ campaign_details.campaign_name }}</h5>
                                                </div>

                                                <div class="mb-3">
                                                    <h5>{{ campaign_details.product_name }}</h5>
                                                </div>

                                                <div class="mb-3">
                                                    <span>{{ campaign_details.description }}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="card">
                                            <h4 class="font-size-16 border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">
                                                Prize Details
                                                <router-link :to="{ name: 'edit_campaign', params: { id: campaign_details.id } }" class="btn btn-light float-end">Edit</router-link>
                                            </h4>
                                            <div class="campaign-details">
                                                <div class="mb-3">
                                                    <h5>{{ campaign_details.prize_name }}
                                                        <router-link :to="{ name: 'edit_campaign', params: { id: campaign_details.id } }" class="btn btn-light float-end">Add Image</router-link>
                                                    </h5>
                                                </div>

                                                <div class="mb-3" v-for="image in images" :key="image.id">
                                                    <div v-if="image.default_img == 1">
                                                        <img v-if="image.images" :src="$image_url + image.images" class="campaign-image mb-2" alt="" />
                                                    </div>
                                                    

                                                    <!-- <img src="@/assets/images/img/campaign1.png"  class="campaign-img"> -->
                                                </div>

                                            </div>

                                        </div>
                                    </div> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DynamicModal title="Add image">
                <template #body>
                    <form @submit.prevent="" enctype="multipart/form-data">
                        <div class="col-md-12 form-group">
                            <label class="control-label">Image <span class="text-danger">*</span></label>
                            <input class="form-control" type="file" placeholder="" />
                           
                        </div>

                        <div class="col-md-12 form-group mt-2">
                            <button type="submit" class="btn btn-primary me-3">Add</button>
                            <button class="btn btn-light" @click="closeModal()">Close</button>
                        </div>
                    </form>
                </template>
            </DynamicModal>

    </div>	
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

   export default {
		name: "CategoriesPage",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,

				token: "",
				errors: {},
				activate: false,
         		deactivate: false,

         		active_page_url: null,

                ecomDashboard: {

                    options1: {
                        labels: ['Sold', 'Remaining'],

                        chart: {
                            type: 'donut',
                            height: 300,
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            position: 'bottom',
                            fontSize: '14px',
                            fontFamily: 'Rubik, sans-serif',
                            fontWeight: 500,
                            labels: {
                                colors: ["var(--chart-text-color)"],
                            },
                            markers: {
                                width: 6,
                                height: 6,
                            },
                            itemMargin: {
                                horizontal: 7,
                                vertical: 0
                            },
                        },
                        stroke: {
                            width: 10,
                            colors: ["var(--light2)"],
                        },
                        plotOptions: {
                            pie: {
                                expandOnClick: false,
                                donut: {
                                    size: '83%',
                                    labels: {
                                        show: true,
                                        name: {
                                            offsetY: 4,
                                        },
                                        total: {
                                            show: true,
                                            fontSize: '20px',
                                            fontFamily: 'Rubik, sans-serif',
                                            fontWeight: 500,
                                            label: [],
                                            formatter: () => 'Total'
                                        }
                                    },
                                }
                            }
                        },
                        states: {
                            normal: {
                                filter: {
                                    type: 'none',
                                }
                            },
                            hover: {
                                filter: {
                                    type: 'none',
                                }
                            },
                            active: {
                                allowMultipleDataPointsSelection: false,
                                filter: {
                                    type: 'none',
                                }
                            },
                        },
                        colors: ["#54BA4A", "var(--theme-deafult)", "#FFA941"],
                        responsive: [{
                                breakpoint: 1630,
                                options: {
                                    chart: {
                                        height: 360
                                    },
                                }
                            },
                            {
                                breakpoint: 1584,
                                options: {
                                    chart: {
                                        height: 400
                                    },
                                }
                            },
                            {
                                breakpoint: 1473,
                                options: {
                                    chart: {
                                        height: 250
                                    },
                                }
                            },
                            {
                                breakpoint: 1425,
                                options: {
                                    chart: {
                                        height: 270
                                    },
                                }
                            },
                            {
                                breakpoint: 1400,
                                options: {
                                    chart: {
                                        height: 320
                                    },
                                }
                            },
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        height: 250
                                    },

                                }
                            }
                        ]
                    },
                    series1: [],
                    
                },

                campaign_details: {},
                sold_data: {},
                images: [],

                currency: [],

			};
		},

        created() {
			this.fetchCampaignDetails();
			this.fetchCurrency();

		},

        methods: {

            getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchCampaignDetails(page_url = null) {
                this.getToken();
                let url = "/api/campaign_details?campaign_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.campaign_details = responseData.campaign_details;
                        this.sold_data = responseData.sold_count;
                        this.images = this.campaign_details.images;
                        console.log(this.images);

                        this.ecomDashboard.series1[0] = this.sold_data.sold_count;
						this.ecomDashboard.series1[1] = this.sold_data.remaining;

                        this.ecomDashboard.options1.plotOptions.pie.donut.labels.total.label[0] = this.sold_data.limit;
                            // this.pagination = responseData.users.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

            openModal() {
                this.showModal();
            },

            closeModal() {
                this.hideModal();
            },

            changePassword(){
                this.clearForm();
                this.closeModal();
            }

        }

   }


</script>