<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="" title="Subadmins" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add Subadmin</button>
									</div>
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchSubadmins()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchSubadmins()">
											<option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Blocked</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchSubadmins()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Sl. No</th>  
                                            <th>Name</th>
											<th>Contact</th>
											<th>Status</th>
											<th>Created On</th>
											<th>Updated On</th>
                                            <th>Options</th>  
                                        </tr>
									</thead>
									<tbody v-if="Boolean(subadmins.length)">
                                        <tr v-for="(subadmin, index) in subadmins" :key="subadmin.id" align="center">
                                            <td>{{ from + index }}</td>
                                            <td>{{ subadmin.name }}</td>
											<td>
												{{ subadmin.country_code }} {{ subadmin.mobile }} <br>
												{{ subadmin.email }}
											</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="subadmin.status == 1">Active</span>
												<span class="badge rounded-pill bg-danger" v-if="subadmin.status == 2">Blocked</span>
											</td>
											<td>{{ $filters.formatDateTime(subadmin.converted_created_at) }}</td>
											<td>{{ $filters.formatDateTime(subadmin.converted_updated_at) }}</td>
                                            <td>
                                                <button class="btn btn-primary btn-sm me-2 mb-2" @click="editSubadmin(subadmin)">Edit</button>    
												<a class="btn btn-danger btn-sm me-2 mb-2" @click="deactivateSubadmin(subadmin)" v-if="subadmin.status == 1">Block</a>
												<a class="btn btn-success btn-sm me-2 mb-2" @click="activateSubadmin(subadmin)" v-else>Activate</a>                   
												<router-link :to="{ name: 'subadmin_settings', params: { id: subadmin.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Settings</router-link> <br>
                                            </td>
                                        </tr>

                                    </tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Subadmins found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchSubadmins(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<DynamicModal :title="modal_title">
			<template #body>
				<form @submit.prevent="addSubadmin" enctype="multipart/form-data" @hidden="clearForm()">

					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Name<span class="text-danger">*</span></label>
						<input class="form-control" type="text" placeholder="" v-model="subadmin.name" v-bind:class="{ 'is-invalid': Boolean(errors.name) }"/>
						<div v-for="(val, index) in errors.name" :key="index" class="invalid-feedback">
							<p class="mb-0">{{ val }}</p>
						</div>
					</div>

					<div class="col-md-12 form-group mb-2">
						<label class="control-label">Mobile<span class="text-danger">*</span></label>
						<div class="input-group">
                            <span>
                                <select class="form-select" v-model="subadmin.country_code_id" @change="fetchCountryCodes()">
                                    <option value="">--select--</option>
                                    <option :value="code.id" v-for="code in country_codes" :key="code.id">
                                            {{code.country_code}}
                                    </option>
                                </select>
                            </span>
							<input class="form-control" type="text" maxlength="11" @keypress="numbersOnly" placeholder="" v-model="subadmin.mobile" v-bind:class="{ 'is-invalid': Boolean(errors.mobile) }"/>
							<div v-for="(val, index) in errors.mobile" :key="index" class="invalid-feedback">
								<p class="mb-0">{{ val }}</p>
							</div>
                        </div>
					</div>

                    <div class="col-md-12 form-group mb-2">
						<label class="control-label">Email<span class="text-danger">*</span></label>
						<input class="form-control" type="text" placeholder="" v-model="subadmin.email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }"/>
						<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
							<p class="mb-0">{{ val }}</p>
						</div>
					</div>

                    <div class="row" v-if="!edit">
                        <div class="col-md-6 form-group mb-2">
                            <label class="control-label">Password<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="" v-model="subadmin.password" v-bind:class="{ 'is-invalid': Boolean(errors.password) }"/>
                            <div v-for="(val, index) in errors.password" :key="index" class="invalid-feedback">
                                <p class="mb-0">{{ val }}</p>
                            </div>
                        </div>

                        <div class="col-md-6 form-group mb-2">
                            <label class="control-label">Confirm Password<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="" v-model="subadmin.password_confirmation" v-bind:class="{ 'is-invalid': Boolean(errors.password_confirmation) }"/>
                            
                        </div>
                    </div>

					<div class="col-md-12 form-group mt-2">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Pincode Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				modal_title: "Add Subadmin",
				button_title: "Add",
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				subadmins: [],

				subadmin_status: {
					id: "",
					status: "",
				},

				subadmin: {
					id: "",
					country_code_id: "",
					mobile: "",
					name: "",
                    password: "",
                    password_confirmation: ""
				},

                country_codes: [],

				image: "",
                preview_image: "",

				pagination: {},

				from: null,
				to: null,
				total: null,
				pagination: {},
				
         		// delete: false,
         		// active_page_url: null,
			};
		},

		created() {
			// this.fetchSliders();
            this.fetchSubadmins();
            this.fetchCountryCodes();
		},
		methods: {

			getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchCountryCodes(page_url = null) {
				this.getToken();
				let url = "/api/getCountryCodes" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.country_codes = responseData.country_codes;
						
					})
					.catch((err) => console.log(err));
			},

			fetchSliders(page_url = null) {
				this.getToken();
				let url = "/api/Sliders?limit=" + this.listing_length+ "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.sliders = responseData.sliders.data;
						this.pagination = responseData.sliders.links;
						
					})
					.catch((err) => console.log(err));
			},

            fetchSubadmins(page_url = null) {
				this.getToken();
				let url = "/api/sub_admins?limit=" + this.listing_length + "&status=" + this.status + "&keyword=" + this.keyword;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.subadmins = responseData.subadmin.data;
						this.pagination = responseData.subadmin.links;
						this.from = responseData.subadmin.from;
						this.to = responseData.subadmin.to;
						this.total = responseData.subadmin.total;
						
					})
					.catch((err) => console.log(err));
			},

			setFile(e) {
                this.image = e.target.files[0];
                this.preview_image = URL.createObjectURL(e.target.files[0]);
            },

			addSubadmin() {
				this.getToken();
				let formData = new FormData();
				formData.append("name", this.subadmin.name);
				formData.append("country_code_id", this.subadmin.country_code_id);
				formData.append("mobile", this.subadmin.mobile);
                formData.append("email", this.subadmin.email);
				formData.append("password", this.subadmin.password);
				formData.append("password_confirmation", this.subadmin.password_confirmation);
			
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/sub_admin/add";
					this.active_page_url = null;
				} else {
					url = "/api/sub_admin/edit";
					formData.append("id", this.subadmin.id);
                    formData.append("name", this.subadmin.name);
					formData.append("country_code_id", this.subadmin.country_code_id);
					formData.append("mobile", this.subadmin.mobile);
                    formData.append("email", this.subadmin.email);
					
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.fetchSubadmins(this.active_page_url);
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									if (error.response.data.message) 
									{
										this.errors = error.response.data.message[0];
										this.$swal("", error.response.data.message[0], "error");
									}
									else {
										this.errors = error.response.data.message;
										this.$swal("", error.response.statusText, "error");
									}
								}
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
			},

			activateSubadmin(subadmin) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to activate this Subadmin?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.subadmin_status.id = subadmin.id;
						this.subadmin_status.status = "1";
						this.updateStaffStatus();
					}
					
				});
			},

			deactivateSubadmin(subadmin) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to block this Subadmin?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.subadmin_status.id = subadmin.id;
						this.subadmin_status.status = "2";
						this.updateStaffStatus();
					}
				});
			},

			updateStaffStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/subadmin_status?subadmin_id=" + this.subadmin_status.id + "&status=" + this.subadmin_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchSubadmins();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},
			
			openModal() {
				this.showModal();
				this.clearForm();
			},

			closeModal() {
				this.hideModal();
				this.clearForm();
			},

            editSubadmin(subadmin) {
				this.edit = true;
				this.subadmin.id = subadmin.id;
				this.subadmin.name = subadmin.name;
				this.subadmin.country_code_id = subadmin.country_code_id;
				this.subadmin.mobile = subadmin.mobile;
                this.subadmin.email = subadmin.email;
				// this.subadmin.password = subadmin.password;
                // this.subadmin.password_confirmation = subadmin.password;
				
				this.modal_title = "Edit Subadmin";
				this.button_title = "Update";
				this.showModal();
			},
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add Subadmin";
				this.button_title = "Add";
				this.subadmin.id = "";
				this.subadmin.name = "";
				this.subadmin.country_code_id = "";
				this.subadmin.mobile = "";
                this.subadmin.email = "";
				this.subadmin.password = "";
                this.subadmin.password_confirmation = "";
				this.errors = {};
			},

		},
	};
</script>