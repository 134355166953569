<template>
    <div>
      <Breadcrumbs main="CMS" title="Terms & Conditions" />
  
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5 class="card-title">cK Editor</h5>
                </div> -->
                <div class="card-body">
                  <form class="form-horizontal" role="form" @submit.prevent="updateTerms()">
                    <label class="control-label">Title <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" placeholder="" v-model="terms.title" v-bind:class="{ 'is-invalid': Boolean(errors.title) }"/>
                        <div v-for="(val, index) in errors.title" :key="index" class="invalid-feedback">
                          <p class="mb-0">{{ val }}</p>
                        </div>                    
                      <label class="control-label mt-3">Content <span class="text-danger">*</span></label>
                      <ClientOnly>
                        <ckeditor :editor="editor" v-model="terms.content"></ckeditor>
                      </ClientOnly>
                    <div class="row mt-3">
                        <div class="12">
                            <button class="btn btn-primary me-2" type="submit">Update</button>
                        </div>
                    </div>
                  </form>
                </div>
            </div>
          </div>
  
        </div>
      </div>
  
    </div>
  </template>
  
<script>
  
  import CKEditor from '@ckeditor/ckeditor5-vue';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  
  export default {

    name: "Terms & Conditions Page",
    components: {
      ckeditor: CKEditor.component
    },
    data() {
      return {
        editor: ClassicEditor,
  
        token: "",
        errors: {},
  
        terms: {
          title: "",
          content: "",
  
        },
  
        terms: {},
      };
    },
  
    created() {
      this.fetchTerms();
    },

    methods: {
      getToken() {
          if (localStorage.getItem('bestdeals_admin')) {
            var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
            
            if(bestdeals_admin.token) {

              this.token = bestdeals_admin.token;
              this.user = bestdeals_admin;

            } else {
              this.$swal("", "Failed to fetch token, please login again", "error");
              this.$router.push({ name: "login" });
            }

          } else {
            this.$swal("", "Session expired, please login again", "error");
            this.$router.push({ name: "login" });
          }
        },
    
        fetchTerms(page_url = null) {
          this.getToken();
          let url = "/api/TermsAndConditions";
          page_url = page_url || url;
          this.$axios
            .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
            .then((res) => {
              var responseData = res.data.data;
              console.log(responseData);
              this.terms = responseData.terms_conditions;
              // console.log(this.accessories);
              
            })
            .catch((err) => console.log(err));
        },
    
        updateTerms() {
          this.getToken();           
          this.errors = {};
    
          const config = {
            headers: {
              "Content-type": "multipart/form-data",
              "Accept": "application/json",
              "Authorization": "Bearer " + this.token,
            },
          };
    
          let url = "api/UpdateTermsAndConditions";
    
          let formData = new FormData();
          formData.append("title", this.terms.title);
          formData.append("content", this.terms.content); 
            
          this.$axios
          .post(url, formData, config)
          .then((response) => {
            if (response.data.status) {
              this.$swal("", response.data.message, "success");
            } else {
              this.$swal("", response.data.message, "error");
            }
            this.$router.push({ name: 'terms'});
    
          })
    
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            if (error.response) {
              if (error.response.data.message) {
                this.errors = error.response.data.message;
                this.$swal("", error.response.data.message, "error");
              } else {
                this.$swal("", "Something went wrong", "error");
              }
            } else {
              this.$swal("", "Something went wrong", "error");
            }
          });
        //.finally(() => (this.loading = false));
      },

    }

  };
</script>
  
  
  <style>
  .ck-content {
    height: 500px;
  }
  </style>