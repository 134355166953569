<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img v-if="admin.profile_img"
        class="profile-image b-r-10"
        :src="$image_url + admin.profile_img"
        
        alt=""
      />
      <img v-else src="@/assets/images/dashboard/no-user.jpg" class="b-r-10" alt="" />

      <div class="media-body" style="margin-top: 6px;">
        <span>Admin<i class="middle fa fa-angle-down"></i></span>
        <!-- <p class="mb-0 font-roboto">
          Admin <i class="middle fa fa-angle-down"></i>
        </p> -->
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div" style="width: 400px;">
      <li>
        <!-- <i class="fa fa-paper-plane"></i> <router-link :to="{ name: 'notifications' }">Notifications</router-link> -->
      </li>
      <li>
        <i class="fa fa-gear"></i> <router-link :to="{ name: 'settings' }">Settings</router-link>
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>Log out</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>

  export default {
    name: 'Profile',

    data() {
			return {
				
				admin: {},

			};
		},

		created() {
			this.fetchProfile();
		},

    methods: {

      getToken() {
        if (localStorage.getItem('bestdeals_admin')) {
          var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin'));
          // console.log(bestdeals_admin.token);
          if(bestdeals_admin.token) {

            this.token = bestdeals_admin.token;
            this.user = bestdeals_admin;

          } else {
            this.$swal("", "Failed to fetch token, please login again", "error");
            this.$router.push({ name: "login" });
          }

        } else {
          this.$swal("", "Session expired, please login again", "error");
          this.$router.push({ name: "login" });
        }
      }, 

      fetchProfile(page_url = null) {
				this.getToken();
				let url = "/api/Settings";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.admin = responseData.admin;
						
					})
					.catch((err) => console.log(err));
			},

      logout() {
        let url = "api/logout";         
        this.getToken();
        this.loading = true;
        this.$axios
        .get(url, { headers: { Authorization: "Bearer " + this.token } })
        .then((res) => {
          console.log(res);
          var responseData = res.data.data;
          console.log(responseData);

          if(localStorage.getItem('bestdeals_admin')) {
            localStorage.removeItem("bestdeals_admin");    
          }         
          this.$swal("", "You have been successfully logged out", "success");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          if (error.response) {
            if(error.response.status == 422) {               
              if (error.response.data) {
                this.errors = error.response.data.message;
              } 
              this.$swal("", error.response.statusText, "error");
                        
            } else if(error.response.status == 500) {
              this.$swal("", error.response.statusText, "error");
                        
            } else {
              this.$swal("", "Something went wrong", "error");
            }
          } else {
            this.$swal("", error.message, "error");
          }
        })
        .finally(() => (this.loading = false));
      },

    },
  };
</script>
