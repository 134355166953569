<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Vendor" title="Tickets" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
                                        <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>
									</div>
                                    
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchVendorTickets()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchVendorTickets()">
											<option value="">All</option>
                                            <option value="1">Redeemed</option>
                                            <option value="2">Not Redeemed</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchVendorTickets()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Ticket Number</th>  
                                            <th>Customer Name</th>  
											<th>Contact</th>
											<th>Status</th>
											<th>Verified On</th> 
                                        </tr>
									</thead>
									<tbody v-if="Boolean(tickets.length)">
                                        <tr v-for="ticket in tickets" align="center">
                                            <td>{{ ticket.ticket_number }}</td>
                                            <td>{{ ticket.name }}</td>
											<td>{{ ticket.country_code }} {{ ticket.mobile }} <br>
												{{ ticket.email }}
											</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="ticket.status==1">Redeemed</span>
                                 				<span class="badge rounded-pill bg-danger" v-else>Not Redeemed</span>
											</td>
											<td>
												<span v-if="ticket.status==1" >{{ $filters.formatDateTime(ticket.converted_used_at) }}</span>
												<span v-else>Not Verified</span>
											</td>
                                            
                                        </tr>
									</tbody>

									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Vendors found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchVendorTickets(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword + '&vendor_id=' + this.$route.params.id )" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: "Vendor Ticket Page",

		data() {
			return {
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

				tickets: [],

				pagination: {},
				
			};
		},
		created() {
			this.fetchVendorTickets();
		},
		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchVendorTickets(page_url = null) {
				this.getToken();
				let url = "/api/vendor_tickets?vendor_id=" + this.$route.params.id + "&keyword=" + this.keyword + "&limit=" + this.listing_length + "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.tickets = responseData.tickets.data;
						this.pagination = responseData.tickets.links;
						
					})
					.catch((err) => console.log(err));
			},
			
			clearForm() {
				this.edit = false;
				this.errors = {};
			},

		},
	};
</script>