import { createWebHistory, createRouter } from "vue-router";
import Body from '../components/body';

/* Dashboards */
import Dashboard from '../pages/dashboard/dashboard';
//Users
import Users from '../pages/users/users.vue';
import UserProfile from '../pages/users/profile.vue';
import UserWallet from '../pages/users/wallet.vue';
import UserReferrals from '../pages/users/referrals.vue';
import UserDownlines from '../pages/users/downlines.vue';
import UserOrders from '../pages/users/orders.vue';

//Campaign
import Campaigns from '../pages/campaign/campaigns.vue';
import CompletedCampaigns from '../pages/campaign/completed-campaigns.vue';
import AddCampaign from '../pages/campaign/campaign-add.vue';
import EditCampaign from '../pages/campaign/edit-campaign.vue';
import CampaignDetails from '../pages/campaign/details.vue';
import CampaignOrders from '../pages/campaign/orders.vue';
import CampaignTickets from '../pages/campaign/tickets.vue';

//Orders
import Orders from '../pages/orders/orders.vue';
import OrderDetails from '../pages/orders/order-details.vue';

//Withdraw Request
import WithdrawRequest from '../pages/withdraw/withdraw.vue';
import WithdrawRequestDetails from '../pages/withdraw/withdraw-details.vue';

//Staffs
import Staffs from '../pages/staff/staffs.vue';
import StaffOrders from '../pages/staff/orders.vue';

//Sub Admin
import Subadmin from '../pages/subadmin/subadmin.vue';
import SubadminSettings from '../pages/subadmin/settings.vue';

//Sliders
import Sliders from '../pages/sliders/slider.vue';

//Vendors
import Vendors from '../pages/vendor/vendor.vue';
import VendorTickets from '../pages/vendor/tickets.vue';
import AddVendor from '../pages/vendor/add-vendor.vue';
import EditVendor from '../pages/vendor/edit-vendor.vue';
import VendorProfile from '../pages/vendor/profile.vue';
import VendorChoice from '../pages/vendor/choices.vue';
import AddChoice from '../pages/vendor/add-choice.vue';

// Reports
import UserReport from '../pages/reports/user.vue';
import OrderReport from '../pages/reports/order.vue';
import VendorReport from '../pages/reports/vendor.vue';

//CMS
import AboutUs from '../pages/cms/about.vue';
import Privacy from '../pages/cms/privacy.vue';
import Terms from '../pages/cms/terms.vue';
import Faq from '../pages/cms/faq.vue';
import Help from '../pages/cms/help.vue';
import UserAgreement from '../pages/cms/user-agreement.vue';

// Settings
import Settings from '../pages/settings/settings.vue';
import Level from '../pages/settings/levels.vue';

//Auth
import login from '../auth/login';
import Register from '../auth/register';


const routes = [
      {
        path: '/',
        component: Body,
    
        children: [
          {
            path: '',
            name: 'defaultRoot',
            component: Dashboard,
            meta: {
              title: ' Dashboard',
            }
          },

          {
            path: "users",
            name: "users",
            component: Users,
            meta: {
               title: "Users",
            },
          },
          {
            path: "/users/profile/:id",
            name: "users_profile",
            component: UserProfile,
            meta: { 
              name: "User Profile"
            },
          },     
          {
            path: "/users/wallet/:id",
            name: "users_wallet",
            component: UserWallet,
            meta: { 
              name: "User Wallet"
            },
          },    
          {
            path: "/users/referrals/:id",
            // path: "/users/referrals",
            name: "users_referrals",
            component: UserReferrals,
            meta: { 
              name: "User Referrals"
            },
          },   
          {
            path: "/users/downlines/:id/:parent_id/:level",
            // path: "/users/referrals",
            name: "users_downlines",
            component: UserDownlines,
            meta: { 
              name: "User Downlines"
            },
          },    
          {
            path: "/users/orders/:id",
            name: "users_orders",
            component: UserOrders,
            meta: { 
              name: "User Orders"
            },
          },    

          {
            path: "campaigns",
            name: "campaigns",
            component: Campaigns,
            meta: {
              title: "Campaigns",
            },
          },
          {
            path: "completed-campaigns",
            name: "completed-campaigns",
            component: CompletedCampaigns,
            meta: {
              title: "Completed Campaigns",
            },
          },
          {
            path: "campaign/add",
            name: "add_campaign",
            component: AddCampaign,
            meta: {
               title: "Add Campaign",
            },
          },
          {
            path: "campaign/edit/:id",
            name: "edit_campaign",
            component: EditCampaign,
            meta: {
               title: "Edit Campaign",
            },
          },
          {
            path: "campaign/details/:id",
            name: "campaign_details",
            component: CampaignDetails,
            meta: {
               title: "Campaign Details",
            },
          },
          {
            path: "campaign/orders/:id",
            name: "campaign_orders",
            component: CampaignOrders,
            meta: {
               title: "Campaign Orders",
            },
          },
          {
            path: "campaign/tickets/:id",
            name: "campaign_tickets",
            component: CampaignTickets,
            meta: {
               title: "Campaign Tickets",
            },
          },

          {
            path: "orders",
            name: "orders",
            component: Orders,
            meta: {
               title: "Orders",
            },
          },
          {
            path: "order/details/:id",
            name: "order_details",
            component: OrderDetails,
            meta: {
               title: "Order Details",
            },
          },

          {
            path: "withdraw_request",
            name: "withdraw_request",
            component: WithdrawRequest,
            meta: {
               title: "Withdraw Request",
            },
          },
          {
            path: "withdraw_request/details/:id",
            name: "withdraw_request_details",
            component: WithdrawRequestDetails,
            meta: {
               title: "Withdraw Request Details",
            },
          },
          
          {
            path: "sliders",
            name: "sliders",
            component: Sliders,
            meta: {
               title: "Sliders",
            },
          },

          {
            path: "vendors",
            name: "vendors",
            component: Vendors,
            meta: {
               title: "Vendors",
            },
          },
          {
            path: "vendor/tickets/:id",
            name: "vendor_tickets",
            component: VendorTickets,
            meta: {
               title: "Vendor Tickets",
            },
          },
          {
            path: "vendor/add",
            name: "add_vendor",
            component: AddVendor,
            meta: {
               title: "Add Vendor",
            },
          },
          {
            path: "vendor/edit/:id",
            name: "edit_vendor",
            component: EditVendor,
            meta: {
               title: "Edit Vendor",
            },
          },
          {
            path: "vendor/profile/:id",
            name: "vendor_profile",
            component: VendorProfile,
            meta: {
               title: "Vendor Profile",
            },
          },
          {
            path: "vendor/choice/:id",
            name: "vendor_choice",
            component: VendorChoice,
            meta: {
               title: "Vendor Choices",
            },
          },
          {
            path: "vendor/add_choice/:id",
            name: "add_choice",
            component: AddChoice,
            meta: {
               title: "Add Vendor Choices",
            },
          },

          {
            path: "staffs",
            name: "staffs",
            component: Staffs,
            meta: {
               title: "Staffs",
            },
          },
          {
            path: "/staffs/orders/:id",
            name: "staff_orders",
            component: StaffOrders,
            meta: { 
              name: "Staff Orders"
            },
          },   

          {
            path: "subadmins",
            name: "subadmins",
            component: Subadmin,
            meta: {
              title: "Subadmin",
            },
          },
          {
            path: "subadmin/settings/:id",
            name: "subadmin_settings",
            component: SubadminSettings,
            meta: {
              title: "Subadmin Permissions",
            },
          },

          {
            path: "settings",
            name: "settings",
            component: Settings,
            meta: {
               title: "Settings",
            },
          },
          {
            path: "levels",
            name: "levels",
            component: Level,
            meta: {
               title: "Levels",
            },
          },

        ]
      },
      {
        path: '/dashboard',
        component: Body,
        children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            title: 'Best Deals Dashboard',
          }
        },
        ]
      },
      
      {
        path: '/reports',
        component: Body,
        children: [
          {
            path: "user-report",
            name: "user-report",
            component: UserReport,
            meta: {
              title: "User Report",
            },
          },
          {
            path: "order-report",
            name: "order-report",
            component: OrderReport,
            meta: {
              title: "Order Report",
            },
          },
          {
            path: "vendor-report",
            name: "vendor-report",
            component: VendorReport,
            meta: {
              title: "Vendor Report",
            },
          },
          
        ]
      },

      {
        path: '/cms',
        component: Body,
        children: [
          {
            path: "aboutUs",
            name: "aboutUs",
            component: AboutUs,
            meta: {
              title: "About Us",
            },
          },
          {
            path: "privacy",
            name: "privacy",
            component: Privacy,
            meta: {
              title: "Privacy Policy",
            },
          },
          {
            path: "terms",
            name: "terms",
            component: Terms,
            meta: {
              title: "Terms & Conditions",
            },
          },
          {
            path: "faq",
            name: "faq",
            component: Faq,
            meta: {
              title: "Faq",
            },
          },
          {
            path: "help",
            name: "help",
            component: Help,
            meta: {
              title: "Help Center",
            },
          },
          {
            path: "user_agreement",
            name: "user_agreement",
            component: UserAgreement,
            meta: {
              title: "User Agreement",
            },
          },
        ]
      },

      {
        path: '/auth',
        children: [
        {
          path: 'login',
          name: 'login',
          component: login,
          meta: {
            title: ' Login | BEST DEALS',
          }
        },
        {
          path: 'register',
          name: 'register 1',
          component: Register,
          meta: {
            title: ' Register | BEST DEALS',
          }
        }
        ]
      },
      
]
const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  router.beforeEach((to, from, next) => {
    if(to.meta.title)
      document.title = to.meta.title;
    const  path = ['/auth/login','/auth/register'];
     if(path.includes(to.path) || localStorage.getItem('bestdeals_admin')){
      return next();
     }
     next('/auth/login');
});
  export default router