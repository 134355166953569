<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Withdraw" title="Withdraw Request Details" />
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                
                                <div class="row mb-3">
                                    <div class="col-12">

                                        <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                                class='bx bx-chevrons-left'></i> Back</a>

                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-xxl-5 col-md-5 box-col-5">

                                        <div class="card overflow-hidden">
                                            <div class="bg-soft bg-primary">
                                                <div class="row m-0">
                                                <div class="col-7">

                                                </div>
                                                <div class="col-5 align-self-end">
                                                    <img src="@/assets/images/details-img.png" alt="" class="img-fluid" width="150" height="150" />
                                                </div>
                                                </div>
                                            </div>
                                            <div class="card-body pt-2">
                                                <div class="row">
                                                <div class="col-sm-4"> 
                                                    <div class="avatar-md profile-user-wid mb-1">
                                                        <img src="@/assets/images/users/no-user.jpg" alt="" class="img-thumbnail rounded-circle" />
                                                    </div>
                                                </div>

                                                <div class="col-sm-8 align-self-center">
                                                    <h5 class="font-size-15">
                                                    {{withdrawal_request_detail.customer_name}}
                                                    </h5>
                                                    <p class="font-size-14 mt-3 mb-1">
                                                        <i class="fa fa-phone me-1 text-primary"></i>
                                                        {{ withdrawal_request_detail.country_code }} {{withdrawal_request_detail.customer_mobile}}
                                                    </p>
                                                    <p class="font-size-14 mb-1">
                                                        <i class="fa fa-envelope me-1 text-primary"></i>
                                                        {{withdrawal_request_detail.customer_email}}
                                                    </p>

                                                </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="media">
                                                <div class="media-body">
                                                    <div class="mt-2 me-2 mb-2 ms-2"> 
                                                        <div class="row mt-3">
                                                            
                                                            <div class="table-responsive" style="margin-left: 5px; margin-right: 5px;">
                                                                <table width='100%' cellspacing='1' cellpadding='2'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Requested Date</td>
                                                                            <td>:</td>
                                                                            <td>{{ $filters.formatDate(withdrawal_request_detail.requested_date) }}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Amount</td>
                                                                            <td>:</td>
                                                                            <td><b>{{ currency.currency }}{{ withdrawal_request_detail.amount }}</b></td>
                                                                        </tr>
                                                                        
                                                                    </tbody>

                                                                </table>
                                                            </div><br>

                                                            <div class="mt-2 me-2 mb-2 ms-2"> 
                                                                <h5 class="text-muted fw-medium mb-2">Request Status</h5>
                                                                <div class="row mt-3">
                                                                    <div class="col-md-4 col-lg-4 mt-2" style="font-size: large;">
                                                                        <span class="badge rounded-pill bg-success mt-2" v-if="withdrawal_request_detail.status==1">Paid</span>
                                                                        <span class="badge rounded-pill bg-info mt-2" v-if="withdrawal_request_detail.status==2">Pending</span>
                                                                        <span class="badge rounded-pill bg-danger mt-2" v-if="withdrawal_request_detail.status==3">Rejected</span>

                                                                    </div>
                                                                    <div class="col-md-8 col-lg-8 mt-2" >
                                                                        <span v-if="withdrawal_request_detail.status == 2"> <a class="btn btn-success mt-2 me-1 btn-sm" @click="TransferAmount(withdrawal_request_detail)">Complete</a></span>

                                                                        <span v-if="withdrawal_request_detail.status == 2"> <a class="btn btn-danger mt-2 me-1 btn-sm" @click="RejectRequest(withdrawal_request_detail)">Reject</a></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    

                                    <div class="col-lg-7 col-md-7">
                                        <div class="card">
                                            <div header-class="bg-transparent border-light" class="border border-light">
                                                <h4
                                                    class="font-size-16 bg-primary  border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">
                                                    Bank Account Details</h4>


                                                <div class="table-responsive ">
                                                    <table class="table table-nowrap mb-0">
                                                        <tbody>

                                                            <tr width="50%">
                                                                <th scope="row">Account Number</th>
                                                                <th>:</th>
                                                                <td>{{withdrawal_request_detail.account_number}}</td>

                                                            </tr>
                                                        
                                                            <tr width="50%">
                                                                <th scope="row">Bank Name</th>
                                                                <th>:</th>
                                                                <td>{{withdrawal_request_detail.bank_name}}</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">Account Holder Name</th>
                                                                <th>:</th>
                                                                <td>{{withdrawal_request_detail.holder_name}}</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">IBAN Number</th>
                                                                <th>:</th>
                                                                <td>{{withdrawal_request_detail.iban_number}}</td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                    </div> 
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DynamicModal title="Add image">
                <template #body>
                    <form @submit.prevent="" enctype="multipart/form-data">
                        <div class="col-md-12 form-group">
                            <label class="control-label">Image <span class="text-danger">*</span></label>
                            <input class="form-control" type="file" placeholder="" />
                           
                        </div>

                        <div class="col-md-12 form-group mt-2">
                            <button type="submit" class="btn btn-primary me-3">Add</button>
                            <button class="btn btn-light" @click="closeModal()">Close</button>
                        </div>
                    </form>
                </template>
            </DynamicModal>

    </div>	
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

   export default {
		name: "CategoriesPage",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,

				token: "",
				errors: {},
				activate: false,
         		deactivate: false,

         		active_page_url: null,

                 withdrawal_request_detail: {},
                sold_data: {},
                images: [],

                currency: [],

                request_status: {
					id: "",
					status: "",
				},

			};
		},

        created() {
			this.fetchRequestDetails();
			this.fetchCurrency();

		},

        methods: {

            getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchRequestDetails(page_url = null) {
                this.getToken();
                let url = "/api/withdrawRequestDetails?request_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.withdrawal_request_detail = responseData.withdrawal_request_detail;
                        
                        console.log(this.withdrawal_request_detail);
                        
                            // this.pagination = responseData.users.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						// console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

            TransferAmount(withdrawal_request_detail) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to confirm this Withdrwal Request?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.request_status.id = withdrawal_request_detail.id;
                        this.request_status.status = "1";
						this.updateRequestStatus();
					}
				});
			},

			RejectRequest(withdrawal_request_detail) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to reject this Withdrawal Request?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.request_status.id = withdrawal_request_detail.id;
                        this.request_status.status = "3";
						this.updateRequestStatus();
					}
				});
			},

            updateRequestStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/updateWithdrawRequest?request_id=" + this.request_status.id + "&status=" + this.request_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchRequestDetails();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},

            openModal() {
                this.showModal();
            },

            closeModal() {
                this.hideModal();
            },

            changePassword(){
                this.clearForm();
                this.closeModal();
            }

        }

   }


</script>