<template>
    <div>
        <Breadcrumbs main="CMS" title="Help Center" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">

					<div class="card">
						<div class="card-body">
							
							<form @submit.prevent="updateHelpCenter" enctype="multipart/form-data">
								<div class="row">
									<div class="col-sm-6">
										<div class="mb-3">
											<label class="control-label">Email<span class="text-danger">*</span></label>
											<input class="form-control" type="text" placeholder="" v-model="help_center.email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }"/>
											<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="mb-3">
											<label class="control-label">Mobile<span class="text-danger">*</span></label>
                                            <div class="input-group">
												<span class="col-md-lg-2">
													<select class="form-select" v-model="help_center.country_code_id" @change="fetchCountryCodes()">
														<option value="">--select--</option>
														<option :value="code.id" v-for="code in country_codes" :key="code.id">
															{{code.country_code}}
														</option>
													</select>
                                            	</span>
												<input class="form-control col-md-4" type="text" maxlength="11" @keypress="numbersOnly" placeholder="" v-model="help_center.mobile" v-bind:class="{ 'is-invalid': Boolean(errors.mobile) }"/>
												<div v-for="(val, index) in errors.mobile" :key="index" class="invalid-feedback">
													<p class="mb-0">{{ val }}</p>
												</div>
                                            </div>

											<!-- <label class="control-label">Mobile Number</label>
											<input class="form-control" type="text" placeholder="" maxlength="10" @keypress="numbersOnly" v-model="help_center.mobile" v-bind:class="{ 'is-invalid': Boolean(errors.mobile) }"/>
											<div v-for="(val, index) in errors.mobile" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
                                            </div>	 -->
										</div>
									</div>
									
								</div>

								<div class="row">
									<div class="col-sm-12">
										<div class="mb-3">
											<label class="control-label">Address</label>
											<textarea class="form-control" type="text" placeholder="" v-model="help_center.address" v-bind:class="{ 'is-invalid': Boolean(errors.address) }"></textarea>
											<div v-for="(val, index) in errors.address" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
                                            </div>
										</div>
									</div>
								</div>

                                <div class="row">
									<div class="col-sm-12">
										<div class="mb-3">
											<label class="control-label">Location <span class="text-danger">*</span></label>
											<GMapAutocomplete @place_changed="setPlace" class="form-control" 
													placeholder="Search here to pick the required latitude and longitude..."
													:value="help_center.location">
											</GMapAutocomplete>

											<GMapMap v-if="help_center.location"
												:center="center"
												:zoom="18"
												map-style-id="roadmap"
												:options="mapOptions"
												style="width: 100%; height: 30vmin"
												ref="mapRef"
												@click="handleMapClick">
												<GMapMarker 
													:position="marker.position"
													:clickable="true"
													:draggable="true"
													@drag="handleMarkerDrag"
													@click="panToMarker"
												/>
											</GMapMap > 

											<div v-for="(val, index) in errors.location" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>											
										</div>
												<!-- <div class="gmap_canvas">
													<iframe class="gmap_iframe" width="100%" height="50%" frameborder="0" scrolling="no" marginheight="0"
														marginwidth="0" :src="getMapUrl()"></iframe >
												</div> -->
										</div>
									</div>

									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Latitude <span class="text-danger">*</span></label>
												<input v-model="marker.position.lat" class="form-control" type="text" v-bind:class="{ 'is-invalid': Boolean(errors.latitude) }" minlength="3" maxlength="100" @keypress="charNumbersOnly"/>
													<div v-for="(val, index) in errors.latitude" :key="index" class="invalid-feedback">
														<p class="mb-0">{{ val }}</p>
													</div>													
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Longitude <span class="text-danger">*</span></label>
												<input v-model="marker.position.lng" type="text" class="form-control" v-bind:class="{ 'is-invalid': Boolean(errors.longitude) }" minlength="3" maxlength="100" @keypress="charNumbersOnly"/>
													<div v-for="(val, index) in errors.longitude" :key="index" class="invalid-feedback">
														<p class="mb-0">{{ val }}</p>
													</div>														
											</div>
										</div>
									</div>

                                <div class="col-sm-12" style="margin-top: 30px;">
									<button type="submit" class="btn btn-primary float-end">Update</button>
								</div>

							</form>
						</div>
					</div>
					
				</div>
			</div>
		</div>


		
	</div>
</template>

<script>
import {ref, onMounted} from "vue";
	export default {
		name: "SettingsPage",

		setup() {
			let mapRef = ref(null);
			// onMounted

		},

		data() {
			return {
				
				token: "",
				errors: {},

				help_center: {
					mobile: "",
					email: "",
					address: "",
					latitude: "",
					longitude: "",
					location: "",
					country_code_id: "",
					country_code: "",
				},

				mapRef:'',
				address: "",
				
				marker: { position: { lat: null, lng: null } },
				center: { lat: null, lng: null },
				current_place: null,

				mapOptions: {
					disableDefaultUI: true,
				},

				country_codes: [],

			};
		},

		created() {
			this.fetchHelpCenter();
			this.fetchCountryCodes();
		},

		methods: {

			geolocate() {
		      navigator.geolocation.getCurrentPosition((position) => {
		         this.marker.position = {
		           lat: position.coords.latitude,
		           lng: position.coords.longitude,
		         };
		         this.panToMarker();
      		});
    		},

		   
			handleMarkerDrag(e) {
      			this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    		},

    		panToMarker() {
				this.$refs.mapRef.panTo(this.marker.position);
    		},

    		handleMapClick(e) {
				this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
				console.log(e);
    		},	 

		 	setPlace(place) {
		    	this.current_place = place;
		    	if(this.current_place) {
		    		const marker = {
			    		lat: this.current_place.geometry.location.lat(),
			    		lng: this.current_place.geometry.location.lng(),
			    	};
			    	this.center = marker;
			    	this.marker.position = marker;
			    	this.help_center.location = place.formatted_address;
					this.help_center.latitude = marker.lat;
	    			this.help_center.longitude = marker.lng;
		    	}
			},

			numbersOnly(event) {
					let keyCode = event.keyCode;
					if (keyCode < 48 || keyCode > 57) {
						event.preventDefault();
					}
			},

			noSpaces(event) {
					let keyCode = event.keyCode;
					if (keyCode == 32) {
						event.preventDefault();
					} else {
						return;
					}
			},

			alphaSpacesOnly(event) {
					let keyCode = event.keyCode;
					if (
						
						(keyCode >= 65 && keyCode <= 90) ||
						(keyCode >= 97 && keyCode <= 122) ||
						// ((keyCode >= 48 && keyCode <= 57) || keyCode == 46)
						keyCode == 46 || keyCode == 32
					) {
						return;
					} else {
						event.preventDefault();
					}
			},

			charNumbersOnly(event) {
				let keyCode = event.keyCode;
				if ( keyCode == 46 || keyCode == 189 ) 
				{
					return;
				} else if (keyCode < 48 || keyCode > 57 || keyCode == 189) {
					event.preventDefault();
				}
			},
			
			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchCountryCodes(page_url = null) {
				this.getToken();
				let url = "/api/getCountryCodes" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.country_codes = responseData.country_codes;
						
					})
					.catch((err) => console.log(err));
			},

			fetchHelpCenter(page_url = null) {
				this.getToken();
				let url = "/api/HelpCenter";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
					var responseData = res.data.data;
					console.log(responseData);
					this.help_center = responseData.Help_Center;

					if(this.help_center.latitude){
						this.marker.position.lat = this.help_center.latitude;
					}
					if(this.help_center.longitude){
						this.marker.position.lng = this.help_center.longitude;
					}
					
					})
					.catch((err) => console.log(err));
			},

			getMapUrl() {
				return `https://maps.google.com/maps?q=${this.latitude},${this.longitude}&z=15&output=embed`;
			},

			updateHelpCenter() {
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/UpdateHelpCenter";
				{
					{
					this.token;
					}
				}
				let formData = new FormData();
				formData.append("mobile", this.help_center.mobile);
				formData.append("country_code_id", this.help_center.country_code_id);
				formData.append("email", this.help_center.email);
				formData.append("address", this.help_center.address);
				formData.append("latitude", this.marker.position.lat);
				formData.append("longitude", this.marker.position.lng);
				

				this.$axios
					.post(url, formData, config)
					.then((response) => {
					if (response.data.status) {
					this.$swal("", response.data.message, "success");
					} else {
					this.$swal("", response.data.message, "error");
					}
					this.$router.push({ name: "help" });
					})
					.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
					} else {
							this.$swal("", "Something went wrong", "error");
					}
					});
			},

			clearForm() {
				this.help_center.mobile = "";
				this.help_center.email = "";
				this.help_center.address = "";
				this.help_center.latitude = "";
				this.help_center.longitude = "";
				
				this.errors = {};

			},

			
		},
	};
</script>