<template>
	<div>
		  <Breadcrumbs main="Vendor" title="Edit Vendor" />
			 <div class="container-fluid">
				<div class="row">
				   <div class="col-sm-12">
					  <div class="card">
						 <div class="card-body">
 
							<div class="row mb-3">
							   <div class="col-12">
                                    <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>
							   </div>
							</div>
 
							<form @submit.prevent="updateVendor" enctype="multipart/form-data" @hidden="clearForm()">
								<div class="form theme-form">
									<div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Name <span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" placeholder="" v-model="vendors.name" v-bind:class="{ 'is-invalid': Boolean(errors.name) }"/>
												<div v-for="(val, index) in errors.name" :key="index" class="invalid-feedback">
													<p class="mb-0">{{ val }}</p>
												</div>
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Email<span class="text-danger">*</span></label>
                                            <input class="form-control" type="text" placeholder="" v-model="vendors.email" v-bind:class="{ 'is-invalid': Boolean(errors.email) }"/>
												<div v-for="(val, index) in errors.email" :key="index" class="invalid-feedback">
													<p class="mb-0">{{ val }}</p>
												</div>
                                        </div>						
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Mobile</label>
                                            <div class="input-group">
												<span>
													<select class="form-select" v-model="vendors.country_code_id" @change="fetchCountryCodes()">
														<option value="">--select--</option>
														<option :value="code.id" v-for="code in country_codes" :key="code.id">
															{{code.country_code}}
														</option>
													</select>
                                            	</span>
												<input class="form-control" type="text" maxlength="11" @keypress="numbersOnly" placeholder="" v-model="vendors.mobile" v-bind:class="{ 'is-invalid': Boolean(errors.mobile) }"/>
												<div v-for="(val, index) in errors.mobile" :key="index" class="invalid-feedback">
													<p class="mb-0">{{ val }}</p>
												</div>
                                            </div>
                                        </div>

										<div class="col-md-6 form-group mb-2">
											<label class="control-label">Icon <span class="text-danger">*</span></label>
											<input ref="fileinput" type="file" @change="setFile($event)" accept="image/*" class="form-control image-class" v-bind:class="{ 'is-invalid': Boolean(errors.image) }"/>
																	
											<img v-if="preview_image" class="profile-image mt-2" :src="preview_image"/>
											<img v-else-if="vendors.profile_img" :src="$image_url + vendors.profile_img" class="profile-image mt-2" alt="" />
											
										</div>
                                    </div>
										
									<div class="row mt-3">
										<div class="col-sm-12">
											<button class="btn btn-success me-2 float-end" type="submit">Update</button>
										</div>
									</div>
	
								</div>
							</form>
						 </div>
					  </div>
				   </div>
				</div>
			 </div>
 
	</div>
 </template>

<script>
	export default {
		name: "Add Campaign Page",
		

		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,
				token: "",
				errors: {},

				button_title: "Save",

				country_codes: [],

				vendors: {
					id: "",
					name: "",
					country_code_id: "",
					mobile: "",
					email: "",
					icon: "",
				},

				image: "",
                preview_image: "",

				// delete: false,
				// active_page_url: null,
			};
		},
		created() {
			this.fetchCountryCodes();
			this.fetchVendorDetails();
		},
		
		methods: {

			numbersOnly(event) {
					let keyCode = event.keyCode;
					if (keyCode < 48 || keyCode > 57) {
						event.preventDefault();
					}
			},

			noSpaces(event) {
					let keyCode = event.keyCode;
					if (keyCode == 32) {
						event.preventDefault();
					} else {
						return;
					}
			},

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchCountryCodes(page_url = null) {
				this.getToken();
				let url = "/api/getCountryCodes" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.country_codes = responseData.country_codes;
						
					})
					.catch((err) => console.log(err));
			},

			fetchVendorDetails(page_url = null) {
                this.getToken();
                let url = "/api/vendor_profile?vendor_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.vendors = responseData.vendors;

                    })
                .catch((err) => console.log(err));
            },

			setFile(e) {
                this.image = e.target.files[0];
                this.preview_image = URL.createObjectURL(e.target.files[0]);
            },

			updateVendor() {
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/update_vendor";
				{
					{
					this.token;
					}
				}
				let formData = new FormData();
				formData.append("vendor_id", this.vendors.id);
				formData.append("name", this.vendors.name);
				formData.append("country_code_id", this.vendors.country_code_id);
				formData.append("mobile", this.vendors.mobile);
				formData.append("email", this.vendors.email);
				formData.append("icon", this.image);
				
				this.$axios
					.post(url, formData, config)
					.then((response) => {
					if (response.data.status) {
					this.$swal("", response.data.message, "success");
					} else {
					this.$swal("", response.data.message, "error");
					}
					this.clearForm();
					this.$router.push({ name: "vendors" });
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									if (error.response.data.message.icon) 
									{
										this.errors = error.response.data.message.icon[0];
										this.$swal("", error.response.data.message.icon[0], "error");
									}
									else
									{
										this.errors = error.response.data.message;
										this.$swal("", error.response.statusText, "error");
									}
								}
								
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
				//.finally(() => (this.loading = false));
			},

			
			clearForm() {
				this.edit = false;
				this.name = "",
				this.country_code_id= "",
				this.mobile= "",
				this.email= "",
				this.password= "",
				this.password_confirmation= "",
				this.errors = {};
			},

		},
	};

</script>