<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
  <div>

    <div class="container-fluid">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" src="../assets/images/logo/login/logo.png" height="160" width="250" alt="looginpage" />
                  <img class="img-fluid for-dark" src="../assets/images/logo/login/logo_dark.png" height="160" width="250" alt="looginpage" />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required="" placeholder=""
                      v-model="email">
                      <span class="validate-error" v-if="!email || !validEmail(email)">{{
                        email.errormsg }}</span>

                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input class="form-control" type="password" name="login[password]" required=""
                        placeholder="*********" v-model="password">
                        <span class="validate-error" v-if="password.length < 7">
                        {{ password.errormsg}}
                      </span>

                      <!-- <div class="show-hide"><span class="show"> </span></div> -->
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="text-end mt-3">

                      <div class="page-loader" v-if="!loading">
                        <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="onSubmit">Sign in</button>
                      </div>

                      <div class="page-loader" v-else align="center">
                        <img  src="@/assets/images/preloaderspin.gif" width="35" height="35"/>
                      </div>
                      

                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DynamicModal title="Login">
			<template #body>
				<form @submit.prevent="verifyOtp" enctype="multipart/form-data">

					<div class="row mt-2">
						<div class="col-md-12 form-group">
							<label class="control-label">OTP <span class="text-danger">*</span></label>

              <div id="divOuter">
                <div id="divInner">
                  <input id="partitioned" type="text" maxlength="4" autocomplete="off" class="form-control" v-model="otp" placeholder="" />
                </div>
              </div>
							<!-- <input id="partitioned" maxlength="4" class="form-control" v-model="otp" type="text" placeholder="" /> -->
						</div>
					</div>
				
					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >Verify</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>

				</form>
			</template>
		</DynamicModal>

  </div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

export default {
  name: 'login',
    components: {
			DynamicModal
		},

		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

  data() {
    return {
      loading: false,

      email: "",
      password: "",   
      errors: {}, 
      token: "",
      otp: "",

    };
  },

  created() {

  },

  methods: {

    // login() {
    //   if (!this.user.password.value || this.user.password.value.length < 7) {
    //     this.user.password.errormsg = 'min length 7'
    //   } else { this.user.password.errormsg = '' }

    //   if (!this.user.email.value) {
    //     this.user.email.errormsg = 'empty not allowed'
    //   } else if (!this.validEmail(this.user.email.value)) {
    //     this.user.email.errormsg = 'Valid email required.'
    //   }
    //   else {
    //     this.user.email.errormsg = ''
    //   }
    //   if (!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value != 'admin@bestdeals.com' || this.user.password.value != '1234567') {
    //     alert("wrong credenstials")
    //   }
    //   if (!this.user.email.errormsg && !this.user.password.errormsg && this.user.email.value == 'admin@bestdeals.com' && this.user.password.value == '1234567') {

    //     this.result = { email: this.user.email.value, password: this.user.password.value }

    //     localStorage.setItem('bestdeals_admin', JSON.stringify({ email: this.user.email.value, useer: true }))
    //     this.logging = true
    //     this.$router.push('/')


    //   }
    // },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    submit() {
      this.$router.push({ name: "dashboard" });
    },
    onSubmit() {
        
        var data = JSON.stringify({
            "email": this.email,
            "password": this.password
        });

        const config = {
           headers: {
             "Content-Type": "application/json",
             "Accept": "application/json",
           },
        };

        this.errors = {};
        this.loading = true;
        this.$axios
        .post("api/login", data, config)
        .then((response) => {
           console.log(response);
           if(response.status == 200) {
              
            var responseData = response.data.data.details;
            
              console.log(responseData);
              if (responseData.token) {

                  localStorage.setItem('bestdeals_admin', JSON.stringify(responseData));   
                  this.token = responseData.token;
                  this.sendloginotp();

                  // this.$router.push({ name: "dashboard" });
                  
                  // this.$swal("", response.data.message, "success");        
              
              } else {
                 this.$swal("", "Failed to fetch token, please try again", "error");
              }

              localStorage.setItem('modules',JSON.stringify(responseData.modules));
           }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);

          this.loading = false;
          if (error.response) {
            if (error.response.status == 422) {
              if (error.response.data) {
                if (error.response.data.message.email) 
                {
                  this.errors = error.response.data.message.email[0];
                  this.$swal("", error.response.data.message.email[0], "error");
                }
                else if(error.response.data.message.password)
                {
                  this.errors = error.response.data.message.password[0];
                  this.$swal("", error.response.data.message.password[0], "error");
                }
                else {
                  this.errors = error.response.data.message;
                  this.$swal("", error.response.data.message, "error");
                }
                
              }
              // this.$swal("", error.response.statusText, "error");
            } else if (error.response.status == 500) {
              this.$swal("", error.response.statusText, "error");
            } else if (error.response.status == 401) {
              this.$swal("", error.response.statusText, "error");
              this.$router.push({ name: "login" });
            } else {
              this.$swal("", "Something went wrong", "error");
            }
          } else {
            this.$swal("", error.message, "error");
          }
        });
        
    },

    sendloginotp() {
			
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url = "api/send_login_otp";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("email", this.email);

				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
              this.openModal();

							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									this.errors = error.response.data.message;
								}
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					})
          .finally(() => (this.loading = false));
		},

    verifyOtp() {
			
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url = "api/verifyLoginOtp";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("email", this.email);
				formData.append("otp", this.otp);

				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.$router.push({ name: "dashboard" });
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
                  if (error.response.data.message.otp) 
                  {
                    this.errors = error.response.data.message.otp[0];
                    this.$swal("", error.response.data.message.otp[0], "error");
                  } else
                  {
                    this.errors = error.response.data.message;
                    this.$swal("", error.response.data.message, "error");
                  }
                }
								// this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
		},

      openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
        this.clearForm();
			},

      clearForm() {
				// this.email = "";
				this.otp = "";
		
				this.errors = {};

			},

  },
};
</script>
