export var menuItems={
  "data": [
    
    {
      "path": "/",
      "title": "Dashboard",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"fa fa-home",
      // "icony":"icon-dashboard",
      "type": "link",
      "active": false
    },

    {
      "path": "/users",
      "title": "Users",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icofont icofont-users",
      "type": "link",
      "active": false,
      "module_id": 1
    },
    
    {
      "title": "Campaigns",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"fa fa-volume-down",
      "type": "sub",
      "active": false,
      "module_id": 2,
      "children": [
        {
          "path": "/campaigns",
          "title": "Campaigns",
          "type": "link",
          "active":false
        },
        {
          "path": "/completed-campaigns",
          "title": "Completed Campaigns",
          "type": "link",
          "active":false
        },
      ]
    },

    // {
    //   "path": "/campaigns",
    //   "title": "Campaigns",
    //   "icon": "stroke-board",
    //   "iconf":"fill-board",
    //   "icony":"fa fa-volume-down",
    //   "type": "link",
    //   "active": false
    // },

    {
      "path": "/orders",
      "title": "Orders",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icon-write",
      "type": "link",
      "active": false,
      "module_id": 3,
    },

    {
      "path": "/withdraw_request",
      "title": "Withdrawal Requests",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"fa fa-money",
      "type": "link",
      "active": false,
      "module_id": 4,
    },

    {
      "path": "/sliders",
      "title": "Sliders",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icon-layers-alt",
      "type": "link",
      "active": false,
      "module_id": 5,
    },

    {
      "path": "/vendors",
      "title": "Vendors",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icofont icofont-users-alt-1",
      "type": "link",
      "active": false,
      "module_id": 6,
    },

    {
      "path": "/staffs",
      "title": "Staffs",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icofont icofont-users-social",
      "type": "link",
      "active": false,
      "module_id": 7,
    },

    {
      "path": "/subadmins",
      "title": "Subadmins",
      "icon": "stroke-board",
      "iconf":"fill-board",
      "icony":"icofont icofont-business-man",
      "type": "link",
      "active": false,
      "module_id": 10,
    },

    {
      "title": "Reports",
      "icon": "stroke-knowledgebase",
      "iconf":"fill-knowledgebase",
      "icony":"icon-files",
      "type": "sub",
      "active": false,
      "module_id": 8,
      "children": [
        {
          "path": "/reports/user-report",
          "title": "User Report",
          "type": "link",
          "active":false
        },
        {
          "path": "/reports/order-report",
          "title": "Order Report",
          "type": "link",
          "active":false
        },
        {
          "path": "/reports/vendor-report",
          "title": "Vendor Report",
          "type": "link",
          "active":false
        },
        // {
        //   "path": "/reports/shift-report",
        //   "title": "Shift Report",
        //   "type": "link",
        //   "active":false
        // },
        // {
        //   "path": "/reports/earnings-report",
        //   "title": "Earnings Report",
        //   "type": "link",
        //   "active":false
        // },
        
      ]
    },

    {
      "title": "CMS",
      "icon": "stroke-knowledgebase",
      "iconf":"fill-knowledgebase",
      "icony":"icon-mobile",
      "type": "sub",
      "active": false,
      "module_id": 9,
      "children": [
        {
          "path": "/cms/aboutUs",
          "title": "About Us",
          "type": "link",
          "active":false
        },
        {
          "path": "/cms/help",
          "title": "Help Center",
          "type": "link",
          "active":false
        },
        {
          "path": "/cms/privacy",
          "title": "Privacy Policy",
          "type": "link",
          "active":false
        },
        {
          "path": "/cms/terms",
          "title": "Terms & Conditions",
          "type": "link",
          "active":false
        },
        {
          "path": "/cms/faq",
          "title": "FAQ",
          "type": "link",
          "active":false
        },
        {
          "path": "/cms/user_agreement",
          "title": "User Agreement",
          "type": "link",
          "active":false
        },
      ]
    },

    {
      "path": "/settings",
      "title": "Settings",
      "icon": "stroke-internationalization",
      "iconf":"fill-internationalization",
      "icony":"icon-settings",
      "type": "link",
      "active": false
    },
    
  ]
}