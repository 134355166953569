<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Orders" title="Order Details" />
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                
                                <div class="row mb-3">
                                    <div class="col-12">

                                        <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                                class='bx bx-chevrons-left'></i> Back</a>

                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-body">
                                        <div header-class="bg-transparent border-light" class="border border-light">
                                            <h4 class="font-size-16 bg-primary  border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">
                                                Order Summery
                                            </h4>

                                            <div class="table-responsive" style="margin-left: 5px; margin-right: 5px;">
                                                <table width='100%' cellspacing='1' cellpadding='2'>
                                                    <tbody v-if ="order_products.length">
                                                        <tr>
                                                            <td align='left'><b>Campaign Name</b></td>
                                                            <td align='left'><b>Product Name</b></td>
                                                            <td align='left'><b>Prize Name</b></td>
                                                            <td align='left'><b>Product Price</b></td>
                                                            <td align='left'><b>Qty</b></td>
                                                            <td align='right'><b>Total</b></td>
                                                        </tr>
                                                        <br>

                                                        <tr v-for="products in order_products" :key="products.id">
                                                            <td align='left' class="font-info">
                                                                <router-link :to="{ name: 'campaign_details', params: { id: products.campaign_id } }">{{ products.campaign_name }}</router-link>
                                                            </td>
                                                            <td align='left'>{{ products.product_name }}</td>
                                                            <td align='left'>{{ products.prize_name }}</td>
                                                            <td align='left'>{{ currency.currency }}{{ products.campaign_price }}</td>
                                                            <td align='left'>{{ products.quantity }}</td>
                                                            <td align='right'>{{ currency.currency }}{{ products.quantity * products.campaign_price }}.00</td>
                                                        </tr>

                                                    </tbody>

                                                </table>

                                                <br>

                                                <table width='100%' cellspacing='0' cellpadding='2' border='0'>
                                                    <tbody>
                                                                        
                                                        <tr>
                                                            <td  align='right'><b>Total Amount</b></td>
                                                            <td  align='right'>{{ currency.currency }}{{ order_details.amount - order_details.vat_amount }}.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td  align='right'><b>VAT</b></td>
                                                            <td  align='right'>{{ currency.currency }}{{ order_details.vat_amount }}.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td  align='right'><b>Grand Total</b></td>
                                                            <td  align='right'>{{ currency.currency }}{{ order_details.amount }}.00</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-xxl-5 col-md-5 box-col-5">

                                        <div class="card">
                                            <div class="card-body">
                                                <div header-class="bg-transparent border-light" class="border border-light">
                                                    <h4 class="font-size-16 bg-primary  border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">Customer Details</h4>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="mb-1">
                                                                    <div class="row">
                                                                        <div class="col-10">

                                                                            <table width='100%' cellspacing='0' cellpadding='2' border='0'>
                                                                                <tbody>
                                                                                                    
                                                                                    <tr>
                                                                                        <td  align='left'><b>Name</b></td>
                                                                                        <td  align='right'>{{ order_details.name }}</td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td  align='left'><b>Contact</b></td>
                                                                                        <td  align='right'>{{order_details.country_code}} {{ order_details.mobile }}<br>
                                                                                            {{order_details.email}}
                                                                                        </td>
                                                                                    </tr>

                                                                                </tbody>

                                                                            </table>
                                                                        </div>
                                                                            
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    

                                    <div class="col-lg-7 col-md-7">
                                        <div class="card">
                                            <div class="card-body">
                                                <div header-class="bg-transparent border-light" class="border border-light">
                                                    <h4 class="font-size-16 bg-primary  border border-top-0 border-start-0 border-end-0 px-3 py-2 mb-3">
                                                        Ticket Details
                                                    </h4>

                                                    <div class="table-responsive" style="margin-left: 5px; margin-right: 5px;">
                                                        <table width='100%' cellspacing='1' cellpadding='2'>
                                                            <tbody v-if ="order_products.length">
                                                                <tr  align="center">
                                                                    <td align='left'><b>Vendor Name</b></td>
                                                                    <td align='left'><b>Ticket Number</b></td>
                                                                    <td align='left'><b>Expire Date</b></td>
                                                                    <td align='left'><b>Ticket Status</b></td>
                                                                </tr>
                                                                <br>

                                                                <tr v-for="ticket in tickets" :key="ticket.id" align="center">
                                                                    <td align='left'>
                                                                        {{ ticket.vendor_name }}
                                                                    </td>
                                                                    <td align='left'>{{ ticket.ticket_number }}</td>
                                                                    <td align='left'>{{ $filters.formatDate(ticket.expire_date) }}</td>
                                                                    <td>
                                                                        <span class="badge rounded-pill bg-info" v-if="ticket.ticket_status==1">Draw Pending</span>
                                                                        <span class="badge rounded-pill bg-warning" v-if="ticket.ticket_status==2">Pending to redeem</span>
                                                                        <span class="badge rounded-pill bg-success" v-if="ticket.ticket_status==3">Completed</span>
                                                                        <span class="badge rounded-pill bg-danger" v-if="ticket.ticket_status==4">Expired</span>
                                                                    </td>
                                                                </tr>

                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div> 

                                </div>

                                <!-- <div class="row">

                                    <div class="table-responsive product-table">
                                        <table class="display" id="basic-1">
                                            <thead>
                                                <tr>
                                                    <th>Campaign Id</th>  
                                                    <th>Campaign Name</th>  
                                                    <th>Campaign Description</th>
                                                    <th>Product Name</th>
                                                    <th>Prize Name</th>
                                                    <th>Campaign Image</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="Boolean(order_products.length)">
                                                <tr v-for="products in order_products" :key="products.id" align="center">
                                                    <td>#{{ products.campaign_id }}</td>
                                                    <td>{{ products.campaign_name }}</td>
                                                    <td>{{ products.description }}</td>
                                                    <td>{{ products.product_name }}</td>
                                                    <td>{{ products.prize_name }}</td>
                                                    <td v-for="image in products.images" :key="image.id">
                                                        <img v-if="image.default_img == 1" :src="$image_url + image" class="profile-image mb-2" alt="" />
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="8" class="text-center">No Orders found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DynamicModal title="Add image">
                <template #body>
                    <form @submit.prevent="" enctype="multipart/form-data">
                        <div class="col-md-12 form-group">
                            <label class="control-label">Image <span class="text-danger">*</span></label>
                            <input class="form-control" type="file" placeholder="" />
                           
                        </div>

                        <div class="col-md-12 form-group mt-2">
                            <button type="submit" class="btn btn-primary me-3">Add</button>
                            <button class="btn btn-light" @click="closeModal()">Close</button>
                        </div>
                    </form>
                </template>
            </DynamicModal>

    </div>	
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

    export default {
		name: "CategoriesPage",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				keyword: "",
				showsearch: false,
				listing_length: 10,

				token: "",
				errors: {},
				activate: false,
         		deactivate: false,

                order_details: {},
                order_products: [],
                currency: [],
                tickets: [],

         		active_page_url: null,

			};
		},

        created() {
			this.fetchOrderDetails();
            this.fetchCurrency();
		},

        methods: {

            getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchOrderDetails(page_url = null) {
                this.getToken();
                let url = "/api/OrderDetails?order_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data;
                        console.log(responseData);
                        this.order_details = responseData.data.order_details;
                        this.order_products = this.order_details.order_products;
                        this.tickets = this.order_details.tickets;
                        // console.log(this.order_products);
                            // this.pagination = responseData.users.links;
                            
                    })
                .catch((err) => console.log(err));
            },

            fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

            openModal() {
                this.showModal();
            },

            closeModal() {
                this.hideModal();
            },

            changePassword(){
                this.clearForm();
                this.closeModal();
            }
        }

    }


</script>