<template>
	<div>
		  <Breadcrumbs main="Campaign" title="Add Campaign" />
			 <div class="container-fluid">
				<div class="row">
				   <div class="col-sm-12">
					  <div class="card">
						 <div class="card-body">
 
							<div class="row mb-3">
							   <div class="col-12">
                                    <router-link :to="{ name: 'campaigns' }" class="btn btn-primary me-1 mb-2 btn-sm float-end">Back</router-link>
							   </div>
							</div>
 
							<form  @submit.prevent="addCampaign" enctype="multipart/form-data" @hidden="clearForm()">
								<div class="form theme-form">
									<div class="row">
										<div class="col-sm-12">
											<div class="mb-3">
												<label class="control-label">Campaign Name<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.name" v-bind:class="{ 'is-invalid': Boolean(errors.name) }"/>
                                                <div v-for="(val, index) in errors.name" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
									</div>
	
									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Product<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.product" v-bind:class="{ 'is-invalid': Boolean(errors.product) }"/>
                                                <div v-for="(val, index) in errors.product" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Product Price(AED) <span class="text-danger">*</span></label>
												<input class="form-control" type="number" placeholder="" min="2" v-model="campaign.product_price" v-bind:class="{ 'is-invalid': Boolean(errors.product_price) }"/>
                                                <div v-for="(val, index) in errors.product_price" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
									</div>

                                    <div class="row">
										<div class="col-sm-12">
											<div class="mb-3">
												<label class="control-label">Campaign Description<span class="text-danger">*</span></label>
												<textarea class="form-control" type="text" placeholder="" v-model="campaign.description" v-bind:class="{ 'is-invalid': Boolean(errors.description) }"></textarea>
                                                <div v-for="(val, index) in errors.description" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Prize Name<span class="text-danger">*</span></label>
												<input class="form-control" type="text" placeholder="" v-model="campaign.prize_name" v-bind:class="{ 'is-invalid': Boolean(errors.prize_name) }"/>
                                                <div v-for="(val, index) in errors.prize_name" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Sold Ticket View<span class="text-danger">*</span></label>
												<select class="form-select" v-model="campaign.sold_data_view">
													<option value="">--Select--</option>
													<option value="1">Percentage</option>
													<option value="2">Count</option>
													
												</select>
												<div v-for="(val, index) in errors.sold_data_view" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Start Date <span class="text-danger">*</span></label>
												<input class="form-control" type="date" placeholder="" v-model="campaign.start_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.start_date) }" :min="Today"/>
												<div v-for="(val, index) in errors.start_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">End date <span class="text-danger">*</span></label>
												<input
													class="form-control"
													type="date"
													placeholder=""
													v-model="campaign.end_date"
													:min="campaign.start_date"
													@keypress="numbersOnly"
													v-bind:class="{ 'is-invalid': Boolean(errors.end_date) }"
												/>
												<!-- <input class="form-control" type="date" placeholder="" v-model="campaign.end_date" :min="campaign.start_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.end_date) }" :min="Today"/> -->
												<div v-for="(val, index) in errors.end_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
													
											</div>
										</div>
									</div>

                                    <div class="row">
										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Draw date <span class="text-danger">*</span></label>
												<input
													class="form-control"
													type="date"
													placeholder=""
													v-model="campaign.draw_date"
													:min="campaign.end_date"
													@keypress="numbersOnly"
													v-bind:class="{ 'is-invalid': Boolean(errors.draw_date) }"
												/>
												<!-- <input class="form-control" type="date" placeholder="" v-model="campaign.draw_date" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.draw_date) }" :min="Today"/> -->
												<div v-for="(val, index) in errors.draw_date" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
													
											</div>
										</div>

										<div class="col-sm-6">
											<div class="mb-3">
												<label class="control-label">Campaign Limit <span class="text-danger">*</span></label>
												<input class="form-control" type="number" placeholder="" v-model="campaign.limit" @keypress="numbersOnly" v-bind:class="{ 'is-invalid': Boolean(errors.limit) }"/>
												<div v-for="(val, index) in errors.limit" :key="index" class="invalid-feedback">
                                                    <p class="mb-0">{{ val }}</p>
                                                </div>
												
											</div>
										</div>
										
									</div>

									<div class="row">
                                        <div class="row mb-2"  v-for="(input, index) in inputs">
                                            <div class="col-md-6 form-group">
                                                <label class="control-label">Images <span class="text-danger">*[Dimension must be 1250*450]</span></label>
												<input class="form-control" type="file" @change="campaign_setFile($event, index)" placeholder="" v-bind:class="{ 'is-invalid': Boolean(errors.images) }"/>
												
                                            </div>	

											<span class="ml-1 mt-2">
												<i class="fa fa-minus-circle text-danger" @click="deleteRow(index)" v-show="index || ( !index && inputs.length > 1)"></i>
												<i class="fa fa-plus-circle text-success" @click="addRow()" v-show="index == inputs.length-1"></i>
											</span>
											<div v-for="(val, index) in errors.images" :key="index" class="invalid-feedback">
												<p class="mb-0">{{ val }}</p>
											</div>
                                        </div>
										
                                                                                
                                    </div>
										
									<div class="row mt-3">
										<div class="col-sm-12">
											<button class="btn btn-success me-2 float-end" type="submit">Save</button>
										</div>
									</div>
	
								</div>
							</form>
						 </div>
					  </div>
				   </div>
				</div>
			 </div>
 
	</div>
 </template>

<script>
	export default {
		name: "Add Campaign Page",
		

		data() {
			const today_str = new Date().toJSON().slice(0,10).replace(/-/g,'-');
			const today = new Date(today_str);
			today.setDate(today.getDate());
			var todayDate = today.toISOString().split('T')[0];
			
			return {
				
				Today: todayDate,

				keyword: "",
				showsearch: false,
				listing_length: 10,
				token: "",
				errors: {},


				inputs: [
				
				],

				inputs: [
					{value: null, preview_image: null, image: null}
				],
				
				campaign_images: [],

				image: "",
            	preview_image: "",

				vendors: [],

				campaign: {
					sold_data_view: "",
					name: "",
					product: "",
					product_price: "",
					description: "",
					prize_name: "",
					start_date: "",
					end_date: "",
					draw_date: "",
					limit: "",
				},


				// delete: false,
				// active_page_url: null,
			};
		},

		created() {
			this.fetchVendors();

		},
		
		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			validateDates() {
				const startDate = new Date(this.campaign.start_date);
				const endDate = new Date(this.campaign.end_date);

				if (startDate > endDate) {
					this.errors.end_date = ['End date must be greater than Start date'];
				} else {
					this.errors.end_date = [];
				}
			},
				
			fetchVendors(page_url = null) {
				this.getToken();
				let url = "/api/getVendors" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.vendors = responseData.vendors;
						
					})
					.catch((err) => console.log(err));
			},

			addCampaign() {
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/add_campaign";
				{
					{
					this.token;
					}
				}
				let formData = new FormData();

				formData.append("name", this.campaign.name);
				formData.append("product", this.campaign.product);
				formData.append("product_price", this.campaign.product_price);
				formData.append("description", this.campaign.description);
				formData.append("prize_name", this.campaign.prize_name);
				formData.append("sold_data_view", this.campaign.sold_data_view);
				formData.append("start_date", this.campaign.start_date);
				formData.append("end_date", this.campaign.end_date);
				formData.append("draw_date", this.campaign.draw_date);
				formData.append("limit", this.campaign.limit);

				for(var k = 0; k < this.campaign_images.length; k++) {
					let file = this.campaign_images[k];
					formData.append('images[' + k + ']', file);
				}

				this.$axios
					.post(url, formData, config)
					.then((response) => {
					if (response.data.status) {
					this.$swal("", response.data.message, "success");
					} else {
					this.$swal("", response.data.message, "error");
					}
					this.clearForm();
					this.$router.push({ name: "campaigns" });
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							if (error.response.status == 422) {
								if (error.response.data) {
									this.errors = error.response.data.message;
								}
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 500) {
								this.$swal("", error.response.statusText, "error");
							} else if (error.response.status == 401) {
								this.$swal("", error.response.statusText, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", error.message, "error");
						}
					});
				//.finally(() => (this.loading = false));
			},

			// addRow() {
			// 	this.inputs.push({
			// 		name: "",
			// 		phone: "",
			// 		email: ""
			// 	});
			// },

			// deleteRow(index) {
			// 	this.inputs.splice(index, 1);
			// },

			addRow() {
              	this.inputs.push({ value: null });
			},

			deleteRow(index) {
				this.inputs.splice(index, 1);
				this.campaign_images.splice(index, 1);
			}, 

			campaign_setFile(e, index) {
				this.campaign_images.push(e.target.files[0]);
				this.inputs[index].image = e.target.files[0];
				this.inputs[index].preview_image = URL.createObjectURL(e.target.files[0]);
			},

			setFile(e) {
				this.image = e.target.files[0];
				this.preview_image = URL.createObjectURL(e.target.files[0]);
			},


			clearForm() {
			
				this.campaign.name = "";
				this.campaign.product = "";
				this.campaign.product_price = "";
				this.campaign.description = "";
				this.campaign.prize_name = "";
				this.campaign.sold_data_view = "";
				this.campaign.start_date = "";
				this.campaign.end_date = "";
				this.campaign.draw_date = "";
				this.campaign.limit = "";

				this.inputs = [
					{value: null, preview_image: null, image: null}
				];
				this.campaign_images = [];

				this.errors = {};

			},
				
			
		},
	};

</script>