<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="" title="Users" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<!-- <div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-12">
										<button class="btn btn-primary float-end" type="button" data-original-title="test" @click="openModal">Add User</button>
									</div>
								</div>
							</div> -->

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<!-- <select class="form-control" v-model="listing_length" @change="fetchUsers()"> -->
											<select class="form-select" v-model="listing_length" @change="fetchUsers()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<select class="form-select" v-model="status" @change="fetchUsers()">
											<option value="">All</option>
                                            <option :value="1">Active</option>
                                            <option :value="2">Blocked</option>
										</select>
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchUsers()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr>
                                            <th>User Id</th>  
                                            <th>Name</th>
											<th>Contact</th>
											<th>Created On</th>
											<th>Status</th> 
											<th>Options</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(users.length)">
                                        <tr v-for="user in users" :key="user.id">
                                            <td>#{{ user.id }}</td>
                                            <td>{{user.name}} </td>
											<td>{{user.country_code}} {{user.mobile}}<br>
												{{user.email}}
											</td>
											<td>{{ $filters.formatDateTime(user.converted_created_at) }}</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="user.status==1">Active</span>
                                 				<span class="badge rounded-pill bg-danger" v-else>Blocked</span>
											</td>
                                            <td>
												<router-link :to="{ name: 'users_profile', params: { id: user.id }  }" class="btn btn-primary me-1 mb-2 btn-sm">Profile</router-link>
												<router-link :to="{ name: 'users_orders', params: { id: user.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Orders</router-link> <br>
												<router-link :to="{ name: 'users_wallet', params: { id: user.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Wallet</router-link>
												<router-link :to="{ name: 'users_referrals', params: { id: user.id } }" class="btn btn-primary me-1 mb-2 btn-sm">Referrals</router-link> 
                                                <a class="btn btn-danger btn-sm  me-2 mb-2" @click="deactivateUser(user)" v-if="user.status == 1">Block</a>
												<a class="btn btn-success btn-sm  me-2 mb-2" @click="activateUser(user)" v-else>Activate</a>
                                            </td>
                                        </tr>

									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Users found</td>
										</tr>
									</tbody>

								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchUsers(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<DynamicModal :title="modal_title">
			<template #body>
				<form @submit.prevent="" enctype="multipart/form-data">
					<div class="row mt-2">
						<div class="col-md-6 form-group">
							<label class="control-label">Name <span class="text-danger">*</span></label>
							<input class="form-control" type="text" placeholder="" />
						</div>
						<div class="col-md-6 form-group">
							<label class="control-label">Mobile <span class="text-danger">*</span></label>
							<input class="form-control" type="text" placeholder="" />
						</div>
					
					</div>

					<div class="row mt-2">

						<div class="col-md-6 form-group">
							<label class="control-label">Email <span class="text-danger">*</span></label>
							<input class="form-control" type="text" placeholder="" />
						</div>	
						<div class="col-md-6 form-group">
							<label class="control-label">Unique Code</label>
							<input class="form-control" type="text" placeholder="GI221ST131" disabled/>
						</div>	

					</div>

					<div class="row mt-2">
						<div class="col-md-6 form-group">
							<label class="control-label">Organization <span class="text-danger">*</span></label>
							<select class="form-select">
								<option value="">All</option>
                                <option value="1">Appollo Calgary</option>
                                <option value="2">Appollo Denver</option>
							</select>
						</div>					
					</div>
					
					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "User Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				modal_title: "Add User",
				button_title: "Add",
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				users: [],

				pagination: {},

				user_status: {
					id: "",
					status: "",
				},
				
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
			this.fetchUsers();
		},
		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchUsers(page_url = null) {
				this.getToken();
				let url = "/api/Users?keyword=" + this.keyword + "&limit=" + this.listing_length+  "&status=" + this.status ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.users = responseData.users.data;
						this.pagination = responseData.users.links;
						
					})
					.catch((err) => console.log(err));
			},

			activateUser(user) {
				this.activate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to activate this User?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.user_status.id = user.id;
						this.user_status.status = "1";
						this.updateUserStatus();
					}
					
				});
			},

			deactivateUser(user) {
				this.deactivate = false;
				this.$swal({
					icon: 'warning',
					title:"Confirm",
					text:'Are you sure you want to block this User?',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					confirmButtonColor: '#e64942',
					cancelButtonText: 'No',
					position: "center",
					showConfirmButton: true,
					timer: '',
					toast: false,
					cancelButtonColor: '#efefef',
					}).then((result)=>{
					if(result.value){
						this.user_status.id = user.id;
						this.user_status.status = "2";
						this.updateUserStatus();
					}
				});
			},

			updateUserStatus() {
				const config = {
					headers: {
						Authorization: "Bearer " + this.token,
					},
				};
				this.$axios
					.get("/api/UserStatusUpdation?user_id=" + this.user_status.id + "&status=" + this.user_status.status, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.fetchUsers();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.$swal("", error.response.data.message, "error");
							} else {
								this.$swal("", "Something went wrong", "error");
							}
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},

			
			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

            editUser() {
                this.errors = {};
				this.modal_title = "Edit User";
				this.button_title = "Update";
				this.showModal();
            },
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add User";
				this.button_title = "Add";
				this.errors = {};
			},

			

		},
	};
</script>