<template>
	<div>
		  <Breadcrumbs main="Vendor" title="Add Choice" />
			 <div class="container-fluid">
				<div class="row">
				   <div class="col-sm-12">
					  <div class="card">
						 <div class="card-body">
 
							<div class="row mb-3">
							   <div class="col-12">
 
								  <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
										class='bx bx-chevrons-left'></i> Back</a>
 
							   </div>
							</div>

							<form class="form-horizontal" role="form" >
								<div class="form theme-form">

									<div class="card">
										<div class="card-body border border-light mb-3" header-class="bg-transparent border-light" v-for="(input, index) in inputs" :key="index">
										
											<div class="row mb-2">
												<div class="col-lg-6 xl-6 form-group mb-2">
													<label class="control-label">Choice<span class="text-danger">*</span></label>
													<input type="text" class="form-control" placeholder="" v-model="input.choice"  v-bind:class="{ 'is-invalid': Boolean(input.err && input.err.choice && input.err.choice.length) }">
													<div v-if="Boolean(input.err)">
														<div v-for="(val, index) in input.err.choice" :key="index" class="invalid-feedback">
															<p class="mb-0">{{ val }}</p>
														</div>   
													</div>
												</div>

												<div class="col-lg-3 xl-3 form-group">
													<label class="control-label">Max amount<span class="text-danger">*</span></label>
                                                    <input type="text" class="form-control" placeholder="" v-model="input.max_amount"  v-bind:class="{ 'is-invalid': Boolean(input.err && input.err.max_amount && input.err.max_amount.length) }">
													<div v-if="Boolean(input.err)">
														<div v-for="(val, index) in input.err.max_amount" :key="index" class="invalid-feedback">
															<p class="mb-0">{{ val }}</p>
														</div>   
													</div>
												</div>
											</div>

											<div class="form-group mt-2 col">                             
												<div class="float-end" style="margin-top: 29px;">
													<button class="btn btn-success btn-sm me-2" type="button" @click="add()" v-show="index == inputs.length-1"><i class="fa fa-plus"></i></button>
													<button class="btn btn-danger btn-sm rounded-0"  type="button" @click="remove(index)" v-show="index || ( !index && inputs.length > 1)"><i class="fa fa-trash"></i></button>
													<button v-if="input.is_apply" class="btn btn-primary ms-2" type="button" @click="apply(index)">Add Choice</button>
												</div>
											</div>

										</div>
									</div>
									
									<div class="row mt-3">
										<div class="col-sm-12">
											<button class="btn btn-primary me-2" type="button" @click="addChoice()" >Save</button>
										</div>
									</div>
	
								</div>
							</form>
 
						 </div>
					  </div>
				   </div>
				</div>
			 </div>

			<DynamicModal title="Add Quiz">
				<template #body>
					<div class="table-responsive product-table">
						<table class="display" id="basic-1">
							<thead>
								<tr>
									<th>Choice</th>
									<th>Is Answer</th>
								</tr>
							</thead>
							<tbody v-if="Boolean(choices.length)">
								<tr v-for="(choice) in choices" :key="choice.id">
									<td>{{ choice.choice }}</td>
									<td>
										<span class="badge rounded-pill bg-success" v-if="choice.max_amount == 1">Yes</span>
										<span class="badge rounded-pill bg-danger" v-if="choice.max_amount == 2">No</span>
									</td>
								</tr>

							</tbody>
						</table>
					</div>
					
					<div class="col-md-12 form-group mt-2">
						<button type="submit" class="btn btn-primary me-3" @click="addChoice()">Submit</button>
						<button class="btn btn-light" @click="closeModal()">Close</button>
					</div>
				</template>
			</DynamicModal>
 
	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Add Quiz Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},
		

		data() {
			return {
				
				modal_title: "Add Licence",
				button_title: "Add",
				
				keyword: "",
				showsearch: false,
				listing_length: 10,
				token: "",
				errors: {},

				quiz: {
					question: '',
					duration: '',
					category_id: '',
					explanation: '',
				},  

				inputs: [
					{
					choice: '',
					max_amount: '',
					
					is_apply: true,
						err: {
							// choice: '',
							// max_amount: '',
						},
					}
				],

				categories: [],
				
				choices: [],

				summary: [],

                button_title: "Save",
				
         		
			};
		},

		created() {
			this.fetchVendorDetails();
		},

		// computed: {
			
		// },

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchVendorDetails(page_url = null) {
                this.getToken();
                let url = "/api/vendor_profile?vendor_id=" + this.$route.params.id;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
                        this.vendor = responseData.vendors;

                    })
                .catch((err) => console.log(err));
            },


			add() {
				this.inputs.push(
					{
						choice: '',
						max_amount: '',
				
						is_apply: true,
						err: {
							// choice: '',
							// max_amount: ''
						},
					}
				);
			},

			remove(index) {
				var choice = this.inputs[index].choice;
				var max_amount = this.inputs[index].max_amount;
				this.inputs.splice(index, 1);
				this.summary.splice(index, 1);

				// this.summary = this.summary.filter(index => index.id !== id);

				// alert(this.summary);
				var i;
				i = this.summary.findIndex(function(element) {
					return choice === element.choice;
					return max_amount === element.max_amount;
				});

			}, 

			getAvailableOptions(index) {
				
				const selectedAnswers = this.inputs.map(input => input.max_amount);
				const availableOptions = [];

				// If 'Yes' is already selected, only 'No' should be available
				if (!selectedAnswers.includes(1)) {
					availableOptions.push({ value: 1, label: 'Yes' });
				}
				availableOptions.push({ value: 2, label: 'No' });

				return availableOptions;
			},
			
			apply(index) {
				
				let err = {
					choice: [],
					max_amount: []
				};
				
				this.inputs[index].err = err;

				let is_valid = true;
				if(this.inputs[index].choice == '') {
					this.inputs[index].err.choice[0] = 'Please add choice';
					is_valid = false;
				} 

				if(this.inputs[index].max_amount == '') {
					this.inputs[index].err.max_amount[0] = 'Please add the maximum amount';
					is_valid = false;
				}  

				if(is_valid) {

					var obj = {};
					obj.choice = this.inputs[index].choice;
					obj.max_amount = this.inputs[index].max_amount;
					this.summary.push(obj);  
					this.inputs[index].is_apply = false;
					console.log(this.summary);
					
					this.inputs[index].err = {};
				}
			},

			quizChoice() {
                this.errors = {};
				this.choices = this.summary;
				this.modal_title = "Edit Licence";
				this.button_title = "Update";
				this.showModal();
            },

			addChoice() {

				this.getToken();
				this.errors = {};
				
				const config = {
					headers: {
						"Content-type": "multipart/form-data",
						"Accept": "application/json",
						"Authorization": "Bearer " + this.token,
					},
				};
				
				let url = "api/addVendorChoice";  
				this.loading = true;

				let formData = new FormData();
				formData.append("vendor_id", this.$route.params.id);
					var i = 0;
					this.summary.forEach(obj => {
						
						formData.append('choices[' + i + ']', obj.choice);
						formData.append('max_amount[' + i + ']', obj.max_amount);
						i++;
					});
				
					this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$swal("", response.data.message, "success");
						} else {
							this.$swal("", response.data.message, "error");
						}
						this.clearForm();
						this.closeModal();
						this.$router.push({ name: 'vendor_choice', params: { id: this.$route.params.id } })
					})
					.catch((error) => {
						console.log(error);
						console.log(error.response);
						if (error.response) {
							this.errors = error.response.data.message;
                            this.$swal("", error.response.statusText, "error");
							
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					});
			},
			
			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

            
			
			clearForm() {
				this.edit = false;
				this.modal_title = "Add Quiz";
				this.button_title = "Add";
				this.errors = {};
			},

		},
	};

</script>