<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
	<div>
		<Breadcrumbs main="Reports" title="Vendor Report" />
 
		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">
		
							<div class="col-md-12 project-list filter-sort">
								<div class="row">
											
									<div class="col-md-4">
										<label class="control-label">Start Date:<span class="text-danger">*</span></label>
										<input class="form-control" type="date" v-model="start_date" :min="min" :max="max" value-as-string @input="fetchVendorReport()">
									</div>
									<div class="col-md-4">
										<label class="control-label">End Date:<span class="text-danger">*</span></label>
										<input class="form-control" type="date" v-model="end_date" :min="min" :max="max" value-as-string @input="fetchVendorReport()">
									</div>
									
									<div class="col-md-4 float-end" style="margin-top: 30px;">

										<div class="page-loader" v-if="!loading">
											<div class="btn btn-info" v-if="Boolean(vendors.length)" @click="generatePDF()">Download</div>
										</div>

										<div class="page-loader float-end" v-else>
											<img  src="@/assets/images/preloaderspin.gif" width="35" height="35"/>
										</div>

									</div>
								</div>

							</div>
							
						</div>
					</div>

					<!-- <div class="row mb-3">
						<div class="col-lg-3">
								<div class="card">
									<div class="report-card">
										<h6>Total Amount: 500AED</h6>
									</div>
								</div>     
													
						</div>
					</div> -->

					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-9">
										<label class="d-flex listing-length"><span>Show</span>
										<select class="form-select" v-model="listing_length" @change="fetchVendorReport()">
											<option value="20">20</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</select>
										<span>entries</span>
										</label>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchVendorReport()"/>
									</div>
								</div>
							</div>
		
							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Name</th>
											<th>Contact</th>
											<th>Created On</th>
										</tr>
									</thead>

									<tbody v-if="Boolean(vendors.length)">
										<tr v-for="vendor in vendors" :key="vendor.id" align="center">
                                            <td>{{ vendor.name }}</td>
											<td>{{ vendor.country_code }} {{ vendor.mobile }} <br>
												{{ vendor.email }}
											</td>
											<td>{{ $filters.formatDateTime(vendor.converted_created_at) }}</td>
										</tr>

									</tbody>
									
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchVendorReport(page.url + '&from_date=' + start_date + '&to_date=' + end_date +  '&limit=' + listing_length)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
		
						</div>
					</div>
				</div>
			</div>
		</div>
 
 
	  
   </div>
</template>


<script>

	export default {
		name: "Coupons eport",
		
		data() {

			const now = new Date()
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
			const today_str = new Date().toJSON().slice(0,10).replace(/-/g,'-')
			const minDate = new Date('2021-01-01') 
	      
			return {
				loading: false,

				accessories: [],
				pagination: {},
				start_date: '2021-01-01',
				end_date: today_str,
				
				min: minDate,
				max: today,
				keyword: "",
				listing_length: 20,
				token: "",
				i: 1,				

				vendors: [],
				currency: [],
				
			};
		},
		mounted() {},

		created() {
			this.fetchVendorReport();
			this.fetchCurrency();
		},

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

			fetchVendorReport(page_url = null) {
				this.getToken();
				let url = "/api/vendor_report?keyword=" + this.keyword + "&limit=" + this.listing_length+ "&from_date=" + this.start_date+ "&to_date=" + this.end_date;
				page_url = page_url || url;
				this.active_page_url = page_url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.vendors = responseData.vendors.data;
						this.pagination = responseData.vendors.links;
					})
					.catch((err) => console.log(err));
			},
			generatePDF() {
				this.getToken();
				let page_url = "/api/vendor_report_download?keyword=" + this.keyword + "&limit=" + this.listing_length + "&status=" + this.status + "&from_date=" + this.start_date + "&to_date=" + this.end_date ;
				
				this.loading = true;
				this.$axios
				.get(page_url, { headers: { Authorization: "Bearer " + this.token }, responseType: "arraybuffer", })
				.then((response) => {

					let blob = new Blob([response.data], { type: "application/pdf" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "VendorReport" + this.start_date + "_" + this.end_date + ".pdf";
					link.click();
						
				})
				.catch((err) => console.log(err))
				.finally(() => (this.loading = false));
			},

		},
	};
</script>
