<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="Campaigns" title="Drawable Tickets" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list page-subtitle">
								<div class="row">
									<div class="col-md-10">
										<div class="btn btn-info me-1 mb-2 btn-sm" v-if="Boolean(tickets.length)" @click="generatePDF()">Download</div>
									</div>
                                    <div class="col-md-2">
										<a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                            class='bx bx-chevrons-left'></i> Back</a>									
									</div>
								</div>
							</div>

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchDrawableTickets()">
												<option value="5">5</option>
												<option value="10">10</option>
												<option value="20">20</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<!-- <select class="form-select">
											<option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Blocked</option>
										</select> -->
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchDrawableTickets()" />
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr align="center">
                                            <th>Ticket Number</th>  
                                            <th>Customer Name</th>  
											<th>Contact</th>
											<th>Ordered On</th> 
                                        </tr>
									</thead>
									<tbody v-if="Boolean(tickets.length)">
                                        <tr v-for="ticket in tickets" :key="ticket.id" align="center">
                                            <td>{{ ticket.ticket_number }}</td>
                                            <td>{{ ticket.name }}</td>
											<td>{{ ticket.country_code }} {{ ticket.mobile }} <br>
												{{ ticket.email }}
											</td>
											<td>{{ $filters.formatDateTime(ticket.converted_created_at) }}</td>
                                            
                                        </tr>

									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Tickets Found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchDrawableTickets(page.url + '&limit=' + listing_length + '&keyword=' + keyword + '&campaign_id=' + this.$route.params.id)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: "User Page",
		
		data() {
			return {
				
				keyword: "",
				listing_length: 10,
				token: "",
				errors: {},

				tickets: [],

				pagination: {},
				
			};
		},
		created() {
			this.fetchDrawableTickets();
		},
		methods: {

			getToken() {
                if (localStorage.getItem('bestdeals_admin')) {
                    var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
                        
                    if(bestdeals_admin.token) {

                        this.token = bestdeals_admin.token;
                        this.user = bestdeals_admin;

                    } else {
                        this.$swal("", "Failed to fetch token, please login again", "error");
                        this.$router.push({ name: "login" });
                    }

                } else {
                    this.$swal("", "Session expired, please login again", "error");
                    this.$router.push({ name: "login" });
                }
            },

            fetchDrawableTickets(page_url = null) {
                this.getToken();
                let url = "/api/drawable_tickets?campaign_id=" + this.$route.params.id + "&keyword=" + this.keyword + "&limit=" + this.listing_length ;
                page_url = page_url || url;
                this.$axios
                    .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
                    .then((res) => {
                        var responseData = res.data.data;
                        console.log(responseData);
						this.campaign_detail = responseData.campaign_detail;
						this.tickets = responseData.drawable_tickets.data;
                        this.pagination = responseData.drawable_tickets.links;
                            
                    })
                .catch((err) => console.log(err));
            },

			generatePDF() {
				this.getToken();
				let page_url = "/api/drawable_tickets_download?campaign_id=" + this.$route.params.id + "&keyword=" + this.keyword ;
				this.$axios
				.get(page_url, { headers: { Authorization: "Bearer " + this.token }, responseType: "arraybuffer", })
				.then((response) => {

					let blob = new Blob([response.data], { type: "application/pdf" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = "DrawableTickets" + "_of_Campaign_" + this.$route.params.id + ".pdf";
					link.click();
						
				})
				.catch((err) => console.log(err));
			},
			
		
			clearForm() {
				this.edit = false;
				
				this.errors = {};
			},
			
		},
	};
</script>