<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="" title="Dashboard" />

		<div class="row">
			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Users</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icon icon-3x icofont icofont-ui-user"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ today_users }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_week_users }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_users }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ total_users }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Enrolls(Referral)</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icon icon-3x icofont icofont-ui-user"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today's</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ today_referrals }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_week_referrals }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_referrals }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400"> {{ total_referrals }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Campaigns</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icofont icofont-3x icofont-hospital"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ today_campaigns }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_week_campaigns }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_campaigns }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ total_campaigns }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Vendors</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icon icon-3x icofont icofont-ui-note"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today's</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{today_vendors}}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_week_vendors }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_vendors }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ total_vendors }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

		<div class="row">
			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Withdraw request</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icon icon-3x icofont icofont-ui-user"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ today_requests }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_requests }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_requests }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400"> {{ this_month_requests }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="card mini-stats-wid">
					<div class="card-body">
						<div class="media mb-3">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Orders</p>
							</div>

							<!-- <div class="float-end mm-cart-image text-primary" style="height: 52px; width: 50px;">
								<i class="icon icon-3x icofont icofont-ui-user"></i>
							</div> -->
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Today's</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ today_orders }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Week</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_week_orders }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">This Month</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ this_month_orders }}</h6>
								</span>
							</div>
						</div>

						<div class="media mb-2">
							<div class="media-body">
								<p class="mb-0 ">Total</p>
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400">{{ total_orders }}</h6>
								</span>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="row-md-2">
					<div class="card-dashboard">
						<div class="media mb-2 media-dashboard">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Total Wallet Amount</p>							
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400"> AED{{ total_wallet_amount }}</h6>
								</span>
							</div>
						</div>
							<!-- <div class="media">
								<div class="media-body">
									<p class="tild-head text-primary" style="font-weight: 800;">Orders</p>
								</div>
							</div> -->
					</div>
				</div>
				<div class="row-md-2">
					<div class="card-dashboard">
						<div class="media mb-2 media-dashboard">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Ongoing Campaigns</p>							
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400"> {{ ongoing_campaigns }}</h6>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-md-2">
					<div class="card-dashboard">
						<div class="media mb-2 media-dashboard">
							<div class="media-body">
								<p class="tild-head text-primary" style="font-weight: 800;">Completed Campaigns</p>						
							</div>

							<div class="align-self-center ">
								<span class="float-end me-3">
									<h6 class="font-weight-400"> {{ completed_campaigns }}</h6>
								</span>
							</div>
						</div>
					</div>
				</div>

			</div>

			

		</div>

			<div class="row">
				

				<div class="col-xxl-6 col-md-6 box-col-6">
					<div class="appointment">
						<div class="dashboard-card card recent-order">
							<div class="card-header card-no-border">
								<div class="header-top">
									<h5 class="m-0">Recent Campaigns</h5>
									
								</div>
							</div>
							<div class="card-body pt-0 mt-3">										
								<div class="tab-content" id="v-pills-tabContent">
									<div class="tab-pane fade show active" id="v-pills-shirt" role="tabpanel" aria-labelledby="v-pills-shirt-tab">
										<div class="dashboard-table employee-table table-responsive">
											<table class="table" bgcolor="aqua">
												<thead>
													<tr align="center">
														<th>Campaign & <br> Prize name</th>
														<th>Start Date</th>
														<th>End Date</th>  
														<th>Sold Out</th>  
														<th>Max Limit</th>  
														<th>Status</th>  
													</tr>
												</thead>
												<tbody v-if="Boolean(recent_campaigns.length)">
													<tr v-for="campaign in recent_campaigns" :key="campaign.id" align="center">
														<td>{{ campaign.campaign_name }} <br> {{ campaign.prize_name }}</td>
														<td>{{ $filters.formatDate(campaign.start_date) }}</td>
														<td>{{ $filters.formatDate(campaign.end_date) }}</td>
														<td>{{ campaign.sold_count }}</td>
														<td>{{ campaign.limit }}</td>
														<td class="f-w-500">
															<span class="recent-status font-success" v-if="campaign.status==1">Active</span>
                                 							<span class="recent-status font-danger" v-else>Blocked</span>
															
														</td>
													</tr>
													
												</tbody>

											</table>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="col-md-6 "> -->
				<div class="col-xxl-6 col-md-6 box-col-6">
					<div class="appointment">
						<div class="dashboard-card card recent-order">
							<div class="card-header card-no-border">
								<div class="header-top">
									<h5 class="m-0">Recent Requests</h5>
									
								</div>
							</div>
							<div class="card-body pt-0 mt-3">										
								<div class="tab-content" id="v-pills-tabContent">
									<div class="tab-pane fade show active" id="v-pills-shirt" role="tabpanel" aria-labelledby="v-pills-shirt-tab">
										<div class="dashboard-table employee-table table-responsive">
											<table class="table" bgcolor="aqua">
												<thead>
													<tr  align="center">
														<th class="f-light">Name</th>
														<th class="f-light">Contact</th>
														<th class="f-light">Amount</th>
														<th class="f-light">Status</th>
													</tr>
												</thead>
												<tbody v-if="Boolean(recent_requests.length)">
													<tr v-for="request in recent_requests" :key="request.id" align="center">
														<td>
															<router-link :to="{ name: 'withdraw_request_details', params: { id: request.id } }" >{{ request.name }}</router-link>
														</td>
														<td> {{ request.country_code }} {{ request.mobile }} <br>
															{{ request.email }}</td>
														<td>{{ currency.currency }}{{ request.amount }}</td>
														<td class="f-w-500">
															<span class="badge rounded-pill bg-success" v-if="request.status==1">Paid</span>
															<span class="badge rounded-pill bg-info" v-if="request.status==2">Pending</span>
															<span class="badge rounded-pill bg-danger" v-if="request.status==3">Rejected</span>
															
														</td>
																
													</tr>

												</tbody>
											</table>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="row">
				<div class="col-xxl-12 col-md-12 col-sm-12 box-col-12">
					<div class="appointment">
						<div class="dashboard-card card recent-order">
							<div class="card-header card-no-border">
								<div class="header-top">
									<h5 class="m-0">Orders</h5>
										
								</div>
							</div>
							
							<div class="card-body pt-0">
								<div class="row m-0 overall-card overview-card">
									<div class="col-xl-12 col-md-12 col-sm-12 p-0 box-col-12">
										<div class="chart-right">
											<div class="row">
												<div class="col-xl-12">
													<div class="card-body p-0">
														<div class="current-sale-container order-container">
															<div class="overview-wrapper">
																<apexchart height="265" type="line" :options="ecomDashboard.options2" :series="ecomDashboard.series2"></apexchart>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<!-- <div class="col-xxl-6 col-md-6 box-col-6">
					<div class="appointment">
						<div class="dashboard-card card">
							<div class="card-header card-no-border">
								<h5 class="m-0">Check Out Type</h5>
							</div>
							<div class="card-body pt-0 mt-3">
								<div class="dashboard-table table-responsive">
									<div class="monthly-profit">
										<apexchart height="271.3" type="donut" :options="ecomDashboard.options1" :series="ecomDashboard.series1"></apexchart>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> -->

				<div class="col-xxl-6 col-md-6 box-col-6">
					<div class="appointment">
						<div class="dashboard-card card">
							<div class="card-header card-no-border">
								<h5 class="m-0">Campaign Status</h5>
							</div>
							<div class="card-body pt-0 mt-3">
								<div class="dashboard-table table-responsive">
									<div class="monthly-profit">
										<apexchart height="271.3" type="donut" :options="ecomDashboard.options3" :series="ecomDashboard.series3"></apexchart>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<DynamicModal title="Feedback">
				<template #body>
					<form @submit.prevent="" enctype="multipart/form-data">
						<div class="row">
							<div class="col-md-12 form-group">
								<!-- <h5 class="control-label">Feedback</h5> -->
								<!-- <p class="tild-body"> Service Support</p> -->
							</div>
						</div>

						<div class="row">
							<div class="col-md-12 form-group mt-3">
								<!-- <h5 class="control-label">Comment</h5> -->
								<p class="tild-body">As a passionate stargazer, this course provided an enthralling journey through the celestial wonders. The lectures, backed by captivating visuals and practical experiments, made complex astronomical concepts easy to grasp. The interactive sessions and engaging discussions with peers added a new dimension to my learning experience. Profoundly insightful and expertly curated, this course ignited a profound fascination for the universe within me. Highly recommended for both enthusiasts and aspiring astronomers!</p>
							</div>
						</div>
						
					</form>
				</template>
			</DynamicModal>

	</div>
</template>

<script>
import '@/assets/css/custom.css';
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Dashbord1",

		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {
				
				errors: {}, 
				token : "",
				

				ecomDashboard: {

					options1: {
						labels: ['Draw', 'Giftcard'],

						chart: {
							type: 'donut',
							height: 300,
						},
						dataLabels: {
							enabled: false
						},
						legend: {
							position: 'bottom',
							fontSize: '14px',
							fontFamily: 'Rubik, sans-serif',
							fontWeight: 500,
							labels: {
								colors: ["var(--chart-text-color)"],
							},
							markers: {
								width: 6,
								height: 6,
							},
							itemMargin: {
								horizontal: 7,
								vertical: 0
							},
						},
						stroke: {
							width: 5,
							colors: ["var(--light2)"],
						},
						plotOptions: {
							pie: {
								expandOnClick: false,
								donut: {
									size: '83%',
									labels: {
										show: true,
										name: {
											offsetY: 4,
										},
										total: {
											show: true,
											fontSize: '20px',
											fontFamily: 'Rubik, sans-serif',
											fontWeight: 500,
											label: [],
											formatter: () => 'Total'
										}
									},
								}
							}
						},
						states: {
							normal: {
								filter: {
									type: 'none',
								}
							},
							hover: {
								filter: {
									type: 'none',
								}
							},
							active: {
								allowMultipleDataPointsSelection: false,
								filter: {
									type: 'none',
								}
							},
						},
						colors: ["#54BA4A",  "#6A5ECF"],
						responsive: [{
								breakpoint: 1630,
								options: {
									chart: {
										height: 360
									},
								}
							},
							{
								breakpoint: 1584,
								options: {
									chart: {
										height: 400
									},
								}
							},
							{
								breakpoint: 1473,
								options: {
									chart: {
										height: 250
									},
								}
							},
							{
								breakpoint: 1425,
								options: {
									chart: {
										height: 270
									},
								}
							},
							{
								breakpoint: 1400,
								options: {
									chart: {
										height: 320
									},
								}
							},
							{
								breakpoint: 480,
								options: {
									chart: {
										height: 250
									},

								}
							}
						]
					},
					series1: [],


					options3: {
						labels: ['Ongoing', 'Completed', 'Upcoming', 'Draw pending'],

						chart: {
							type: 'donut',
							height: 300,
						},
						dataLabels: {
							enabled: false
						},
						legend: {
							position: 'bottom',
							fontSize: '14px',
							fontFamily: 'Rubik, sans-serif',
							fontWeight: 500,
							labels: {
								colors: ["var(--chart-text-color)"],
							},
							markers: {
								width: 6,
								height: 6,
							},
							itemMargin: {
								horizontal: 7,
								vertical: 0
							},
						},
						stroke: {
							width: 5,
							colors: ["var(--light2)"],
						},
						plotOptions: {
							pie: {
								expandOnClick: false,
								donut: {
									size: '83%',
									labels: {
										show: true,
										name: {
											offsetY: 4,
										},
										total: {
											show: true,
											fontSize: '20px',
											fontFamily: 'Rubik, sans-serif',
											fontWeight: 500,
											label: [],
											formatter: () => 'Total'
										}
									},
								}
							}
						},
						states: {
							normal: {
								filter: {
									type: 'none',
								}
							},
							hover: {
								filter: {
									type: 'none',
								}
							},
							active: {
								allowMultipleDataPointsSelection: false,
								filter: {
									type: 'none',
								}
							},
						},
						colors: ["#FFA941", "#59CE12", "#3F81CF", "#15F5E7"],
						responsive: [{
								breakpoint: 1630,
								options: {
									chart: {
										height: 360
									},
								}
							},
							{
								breakpoint: 1584,
								options: {
									chart: {
										height: 400
									},
								}
							},
							{
								breakpoint: 1473,
								options: {
									chart: {
										height: 250
									},
								}
							},
							{
								breakpoint: 1425,
								options: {
									chart: {
										height: 270
									},
								}
							},
							{
								breakpoint: 1400,
								options: {
									chart: {
										height: 320
									},
								}
							},
							{
								breakpoint: 480,
								options: {
									chart: {
										height: 250
									},

								}
							}
						]
					},
					series3: [],


					options2: {

						chart: {
							height: 300,
							type: 'line',
							stacked: false,
							toolbar: {
								show: false
							},
							dropShadow: {
								enabled: true,
								top: 2,
								left: 0,
								blur: 4,
								color: '#000',
								opacity: 0.08
							}
						},
						stroke: {
							width: [2, 2, 2, 2],
							curve: 'smooth'
						},
						grid: {
							show: true,
							borderColor: 'var(--chart-border)',
							strokeDashArray: 0,
							position: 'back',
							xaxis: {
								lines: {
									show: true
								}
							},
							padding: {
								top: 0,
								right: 0,
								bottom: 0,
								left: 0
							},
						},
						plotOptions: {
							bar: {
								columnWidth: '50%'
							}
						},
						colors: ["#FF3364"],
						// "#FF3364", "#0dcaf0"
						fill: {
							type: 'gradient',
							gradient: {
								shade: 'light',
								type: "vertical",
								opacityFrom: 0.6,
								opacityTo: 0,
								stops: [0, 100]
							}
						},
						labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						// labels: months
						
						markers: {
							discrete: [
								{
									seriesIndex: 0,
									dataPointIndex: 0,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								
								{
									seriesIndex: 0,
									dataPointIndex: 1,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 2,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 3,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								},
								{
									seriesIndex: 0,
									dataPointIndex: 4,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 5,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 6,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 7,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								},
								{
									seriesIndex: 0,
									dataPointIndex: 8,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 9,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 10,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								{
									seriesIndex: 0,
									dataPointIndex: 11,
									fillColor: '#FF3364',
									strokeColor: 'var(--white)',
									size: 5,
									sizeOffset: 3
								}, 
								
								
							],
							hover: {
								size: 5,
								sizeOffset: 0
							}
						},
						xaxis: {
							type: 'category',
							tickAmount: 10,
							tickPlacement: 'between',
							tooltip: {
								enabled: false,
							},
							axisBorder: {
								color: 'var(--chart-border)',
							},
							axisTicks: {
								show: false
							}
						},
						legend: {
							show: false,
						},
						yaxis: {
							min: 0,
							tickAmount: 6,
							tickPlacement: 'between',
						},
						tooltip: {
							shared: false,
							intersect: false,
						},
						responsive: [{
							breakpoint: 1200,
							options: {
								chart: {
									height: 250,
								}
							},
						}]

					},
					series2: [
						{
							name: 'Orders',
							type: 'area',
							data: []
						},
						
						
					],

				},
				
				today_campaigns: "",
				this_week_campaigns: "",
				this_month_campaigns: "",
				total_campaigns: "",

				today_orders: "",
				this_week_orders: "",
				this_month_orders: "",
				total_orders: "",

				today_users: "",
				this_week_users: "",
				this_month_users: "",
				total_users: "",

				today_referrals: "",
				this_week_referrals: "",
				this_month_referrals: "",
				total_referrals: "",

				today_vendors: "",
				this_week_vendors : "",
				this_month_vendors : "",
				total_vendors : "",

				today_requests: "",
				this_week_requests : "",
				this_month_requests : "",
				total_requests : "",

				total_wallet_amount: "",
				ongoing_campaigns: "",
				completed_campaigns: "",

				today_total_shifts: "",

				earning_type: "",
				income_type: "",
				expense_type: "",

				recent_campaigns: [],
				recent_requests: [],
				recent_expense: [],

				facility_shifts: [],

				facilities: [],
				facility_id: "",

				currency: [],

				expense: {
					svgIcon : "expense",
					title :  "Expense",
					total_expense : "",
				},
				
				income: {
					svgIcon : "orders",
					title :  "Income",
					total_income : "",
				},

				months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

			};

		},

		created() {
			this.fetchDashboard();
			this.fetchCurrency();
		},

		methods: {

			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},



			fetchDashboard(page_url = null) {
				let url = "/api/dashboard";
				page_url = page_url || url;

				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);

						this.today_campaigns = responseData.today_campaigns;
						this.this_week_campaigns = responseData.this_week_campaigns;
						this.this_month_campaigns = responseData.this_month_campaigns;
						this.total_campaigns = responseData.total_campaigns;

						this.today_orders = responseData.today_orders;
						this.this_week_orders = responseData.this_week_orders;
						this.this_month_orders = responseData.this_month_orders;
						this.total_orders = responseData.total_orders;

						this.today_users = responseData.today_users;
						this.this_week_users = responseData.this_week_users;
						this.this_month_users = responseData.this_month_users;
						this.total_users = responseData.total_users;

						this.today_referrals = responseData.today_referrals;
						this.this_week_referrals = responseData.this_week_referrals;
						this.this_month_referrals = responseData.this_month_referrals;
						this.total_referrals = responseData.total_referrals;

						this.today_vendors = responseData.today_vendors;
						this.this_week_vendors = responseData.this_week_vendors;
						this.this_month_vendors = responseData.this_month_vendors;
						this.total_vendors = responseData.total_vendors;

						this.today_requests = responseData.today_requests;
						this.this_week_requests = responseData.this_week_requests;
						this.this_month_requests = responseData.this_month_requests;
						this.total_requests = responseData.total_requests;

						this.total_wallet_amount = responseData.total_wallet_amount;
						this.ongoing_campaigns = responseData.ongoing_campaigns;
						this.completed_campaigns = responseData.completed_campaigns;

						this.recent_campaigns = responseData.recent_campaigns;
						this.recent_requests = responseData.recent_requests;
						// this.recent_expense = responseData.recent_expense;

						// this.completed_campaign_percentage = responseData.facility_shifts;
						
						this.ecomDashboard.series1[0] = responseData.draw_tickets;
						this.ecomDashboard.series1[1] = responseData.gift_card_tickets;

						this.ecomDashboard.series3[0] = responseData.ongoing_campaign;
						this.ecomDashboard.series3[1] = responseData.completed_campaign;
						this.ecomDashboard.series3[2] = responseData.upcoming_campaigns;
						this.ecomDashboard.series3[3] = responseData.draw_pending_campaigns;

						this.ecomDashboard.series2[0].data = responseData.completed_month_orders;
						// this.ecomDashboard.series2[1].data = responseData.completed_month_expense;
						
						this.ecomDashboard.options1.plotOptions.pie.donut.labels.total.label[0] = responseData.total_campaign_tickets;
						this.ecomDashboard.options3.plotOptions.pie.donut.labels.total.label[0] = responseData.total_campaigns;

						// this.income.total_income = responseData.total_income.total_income;
						// this.expense.total_expense = responseData.total_expense.total_expense;
						// console.log(this.income.total_income);
						// console.log(this.expense.total_expense);
						
					})
					.catch((err) => console.log(err));
			},

			fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},

			// fetchFacilities(page_url = null) {
			// 	let url = "/api/fetchFacilities";
			// 	page_url = page_url || url;

			// 	this.getToken();
			// 	this.$axios
			// 		.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
			// 		.then((res) => {
			// 			var responseData = res.data.data;
			// 			console.log(responseData);
			// 			this.facilities = responseData.facilities;
						
			// 		})
			// 		.catch((err) => console.log(err));
			// },
			
			 
		},
		
	};
</script>

