<template>
  <div>
    <head>
      <title>BEST DEALS - Home page</title>
    </head>
    <div class="loader-wrapper" v-if="loading">
      <div class="loader-index"><span></span></div>
      <svg>
        <defs></defs>
        <filter id="goo">
          <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
          <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></fecolormatrix>
        </filter>
      </svg>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      sessionTimeout: 5 * 60 * 1000, // 1 minute
      lastActivity: Date.now(),
      sessionCheckInterval: null,
      loading: true,
      token: null,
      visibilityCheckInterval: null,
      inactivityTimeout: 5 * 60 * 1000, // 1 minute
    };
  },
  watch: {
    '$route'() {
      // Toggle loading state on route change
      setTimeout(() => {
        this.loading = !this.loading;
      }, 1000);
      this.loading = !this.loading;
    },
  },
  mounted() {
    this.loading = false;
    this.setupSessionCheck();
    this.setupVisibilityCheck();

    // Track user activity
    window.addEventListener('mousemove', this.updateActivity);
    window.addEventListener('keypress', this.updateActivity);

    // Handle tab/window close
    window.addEventListener('beforeunload', this.handleTabClose);
  },
  beforeUnmount() {
    clearInterval(this.sessionCheckInterval);
    clearInterval(this.visibilityCheckInterval);
    window.removeEventListener('mousemove', this.updateActivity);
    window.removeEventListener('keypress', this.updateActivity);
    window.removeEventListener('beforeunload', this.handleTabClose);
  },
  methods: {
    updateActivity() {
      this.lastActivity = Date.now();
      localStorage.setItem('lastActivity', this.lastActivity);
    },
    checkSession() {
      const lastActivity = localStorage.getItem('lastActivity');
      if (lastActivity) {
        const timeSinceLastActivity = Date.now() - parseInt(lastActivity, 10);
        if (timeSinceLastActivity > this.sessionTimeout) {
          this.handleSessionExpired();
        }
      } else {
        this.handleSessionExpired();
      }
    },
    handleSessionExpired() {
      // Handle session expiration by clearing session data and redirecting to login
      this.clearSessionData();
      this.$router.push('/auth/login');
    },
    setupSessionCheck() {
      // Set up an interval to check the session periodically
      this.sessionCheckInterval = setInterval(this.checkSession, 5 * 60 * 1000); // Check every 1 minute
    },
    handleTabClose() {
      // Clear session data when the tab or window is closed
      this.clearSessionData();
    },
    setupVisibilityCheck() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.visibilityCheckInterval = setTimeout(() => {
          this.handleSessionExpired();
        }, this.inactivityTimeout);
      } else {
        clearTimeout(this.visibilityCheckInterval);
      }
    },
    logout() {
      const url = "api/logout";         
      this.getToken();
      this.loading = true;
      axios
        .get(url, { headers: { Authorization: "Bearer " + this.token } })
        .then((res) => {
          const responseData = res.data.data;
          if (localStorage.getItem('bestdeals_admin')) {
            localStorage.removeItem("bestdeals_admin");    
          }         
          this.$swal("", "You have been successfully logged out", "success");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          const errorMessage = error.response?.statusText || error.message || "Something went wrong";
          this.$swal("", errorMessage, "error");
        })
        .finally(() => (this.loading = false));
    },
    clearSessionData() {
      localStorage.removeItem('lastActivity');
      localStorage.removeItem('bestdeals_admin');
    },
    getToken() {
      const bestdealsAdmin = localStorage.getItem('bestdeals_admin');
      if (bestdealsAdmin) {
        const parsedAdmin = JSON.parse(bestdealsAdmin);
        if (parsedAdmin.token) {
          this.token = parsedAdmin.token;
          this.user = parsedAdmin;
        } else {
          this.$swal("", "Failed to fetch token, please login again", "error");
          this.$router.push({ name: "login" });
        }
      } else {
        this.$swal("", "Session expired, please login again", "error");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
