<style>
	 @import '@/assets/css/custom.css';
</style>

<template>
    <div>
        <Breadcrumbs main="" title="Orders" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-body">

							<div class="col-md-12 project-list filter-sort">
								<div class="row">
									<div class="col-md-8 d-flex">
										<label class="d-flex listing-length"><span>Show</span>
											<select class="form-select" v-model="listing_length" @change="fetchOrders()">
												<option value="20">20</option>
												<option value="50">50</option>
												<option value="100">100</option>
											</select>
											<span>entries</span>
										</label>
									</div>
									<div class="col-md-2">
										<!-- <select class="form-select" v-model="status" @change="fetchOrders()">
											<option value="">All</option>
                                            <option :value="1">Completed</option>
                                            <option :value="2">Pending</option>
											<option :value="3">Cancelled</option>
										</select> -->
									</div>
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchOrders()"/>
									</div>
								</div>
							</div>

							<div class="table-responsive product-table">
								<table class="display" id="basic-1">
									<thead>
										<tr>
                                            <th>Order Id</th>  
                                            <th>Customer Name</th>  
											<th>Contact</th>
                                            <th>Total Amount</th>
											<th>Status</th>
											<th>Payment Type</th>
                                            <th>Ordered On</th> 
											<th>Options</th>
                                        </tr>
									</thead>
									<tbody v-if="Boolean(orders.length)">
                                        <tr v-for="order in orders" :key="order.order_id" align="center">
                                            <td>#{{ order.order_id }}</td>
                                            <td>{{ order.name }}</td>
											<td>{{ order.country_code }} {{ order.mobile }} <br>
												{{ order.email }}
											</td>
											<td>{{ currency.currency }}{{ order.amount }}</td>
											<td>
												<span class="badge rounded-pill bg-success" v-if="order.order_status==1">Completed</span>
                                 				<span class="badge rounded-pill bg-info" v-if="order.order_status==2">Pending</span>
												<span class="badge rounded-pill bg-danger" v-if="order.order_status==3">Cancelled</span>
											</td>
											<td>
												<span class="font-warning" v-if="order.payment_type==1">Wallet</span>
                                 				<span class="font-primary" v-if="order.payment_type==2">Online</span>
												<span class="font-secondary" v-if="order.payment_type==3">Cash</span>
											</td>
											<td>{{ $filters.formatDateTime(order.converted_created_at) }}</td>
                                            <td>
                                                <router-link :to="{ name: 'order_details', params: { id: order.order_id } }" class="btn btn-primary me-1 mb-2 btn-sm">Details</router-link>
                                            </td>
                                        </tr>

									</tbody>
									<tbody v-else>
										<tr>
											<td colspan="8" class="text-center">No Orders found</td>
										</tr>
									</tbody>
								</table>
							</div>

							<!----pagination starts --->
							<nav aria-label="Page navigation" class="mt-2">
								<ul class="pagination">
									<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
										<a class="page-link" href="#" @click.prevent="fetchOrders(page.url + '&limit=' + listing_length + '&status=' + status + '&keyword=' + keyword)" tabindex="-1">
											<span v-if="index == 0">Prev</span>
											<span v-else-if="index == pagination.length - 1">Next</span>
											<span v-else>{{ page.label }}</span>
										</a>
									</li>
								</ul>
							</nav>
							<!----pagination ends --->
							
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

	export default {
		name: "User Page",
		
		data() {
			return {
				
				keyword: "",
				status: "",
				listing_length: 20,
				token: "",
				errors: {},

                button_title: "Save",

				orders: [],
				currency: [],
				
				pagination: "",
         		// delete: false,
         		// active_page_url: null,
			};
		},
		created() {
			this.fetchOrders();
			this.fetchCurrency();
		},

		methods: {

			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchOrders(page_url = null) {
				this.getToken();
				let url = "/api/Orders?limit=" + this.listing_length +  "&keyword=" + this.keyword + "&status=" + this.status;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.orders = responseData.orders.data;
						this.pagination = responseData.orders.links;
						
					})
					.catch((err) => console.log(err));
			},

			fetchCurrency(page_url = null) {
				this.getToken();
				let url = "/api/WelcomePage" ;
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.currency = responseData.default_currency[0];
						
					})
					.catch((err) => console.log(err));
			},
					
			clearForm() {
				this.edit = false;
				this.errors = {};
			},


		},
	};
</script>