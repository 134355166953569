<template>
  <div>
    <Breadcrumbs main="CMS" title="About Us" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form class="form-horizontal" role="form" @submit.prevent="updateAboutUs()">
                <label class="control-label">Title <span class="text-danger">*</span></label>
                <input class="form-control" type="text" placeholder="" v-model="about_us.title" v-bind:class="{ 'is-invalid': Boolean(errors.title) }"/>
                <div v-for="(val, index) in errors.title" :key="index" class="invalid-feedback">
                  <p class="mb-0">{{ val }}</p>
                </div>
                    
                <label class="control-label mt-3">Content <span class="text-danger">*</span></label>
                <ClientOnly>
                  <ckeditor :editor="editor" v-model="about_us.content"></ckeditor>
                </ClientOnly>
                <div class="row mt-3">
                  <div class="12">
                    <button class="btn btn-primary me-2" type="submit">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {

  name: "Privacy Policy Page",
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      editor: ClassicEditor,

      token: "",
      errors: {},

      about_us: {
        title: "",
        content: "",

      },

    };
  },

  created() {
    this.fetchAboutUs();
  },

  methods: {

    getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
      },

    fetchAboutUs(page_url = null) {
      this.getToken();
      let url = "/api/AboutUs";
      page_url = page_url || url;
      this.$axios
        .get(page_url, { headers: { Authorization: "Bearer " + this.token } })
        .then((res) => {
          var responseData = res.data.data;
          console.log(responseData);
          this.about_us = responseData.about_us;
          
        })
        .catch((err) => console.log(err));
    },

    updateAboutUs() {
      this.getToken();           
      this.errors = {};

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          "Accept": "application/json",
          "Authorization": "Bearer " + this.token,
        },
      };

      let url = "api/UpdateAboutUs";

      let formData = new FormData();
      formData.append("title", this.about_us.title);
      formData.append("content", this.about_us.content); 
         
      this.$axios
      .post(url, formData, config)
      .then((response) => {
        if (response.data.status) {
          this.$swal("", response.data.message, "success");
        } else {
          this.$swal("", response.data.message, "error");
        }
        this.$router.push({ name: 'aboutUs'});

      })

      .catch((error) => {
        console.log(error);
        console.log(error.response);
        if (error.response) {
          if (error.response.data.message) {
            this.errors = error.response.data.message;
            this.$swal("", error.response.data.message, "error");
          } else {
            this.$swal("", "Something went wrong", "error");
          }
        } else {
          this.$swal("", "Something went wrong", "error");
        }
      });
    //.finally(() => (this.loading = false));
  },
      
  },

};
</script>


<style>
.ck-content {
  height: 500px;
}
</style>
