<template>
    <div>
        <Breadcrumbs main="Settings" title="Level Price" />

		<div class="container-fluid">
			<div class="row">
				<div class="col-sm-12">

					<div class="card">
						<div class="card-body">
                            <div class="row mb-3">
                                <div class="col-12">

                                    <a href="#" @click.prevent="$router.go(-1)" class="btn btn-primary float-end"><i
                                                    class='bx bx-chevrons-left'></i> Back</a>

                                </div>
                            </div>
                            
							<div class="row">
								<h5>Level Price in AED</h5>
							</div><br>
							<form  enctype="multipart/form-data" >
							
								<div class="row" v-for="level in levels" :key="level.id">
									<div class="col-md-3 mb-3 form-group">
										<label class="control-label">Level: {{level.level}} Commission<span class="text-danger">*</span></label>
										<input class="form-control" v-model="level.value" v-bind:class="{ 'is-invalid': Boolean(errors.value) }" disabled/>

										<div v-for="(val, index) in errors.value" :key="index" class="invalid-feedback">
											<p class="mb-0">{{ val }}</p>
										</div>
									</div>
									
									<div class="col-md-2">
										<button type="button" class="btn btn-primary me-2" style="margin-top: 30px;" @click="editLevel(level)">Update</button>
									</div>

									<div class="col-md-7">
									</div>

								</div>

							</form>

							<div class="row  mt-5">
								<h5>MLM Tree Image</h5>
							</div><br>
							<form  enctype="multipart/form-data" >
							
								<div class="row">
									<div class="col-md-6 form-group">
										<input ref="fileinput" type="file" @change="setFile($event)" accept="image/*" class="form-control image-class" v-bind:class="{ 'is-invalid': Boolean(errors.image) }"/>
                                                
										<img v-if="preview_image" class="profile-image mt-2" :src="preview_image"/>
										<img v-else-if="mlmImage.image" :src="$image_url + mlmImage.image" class="profile-image mt-2" alt="" />
									</div>
									
									<div class="col-md-6">
										<button type="button" class="btn btn-primary me-2" @click="updateImage(mlmImage)">Update</button>
									</div>

								</div>

							</form>
						</div>
					</div>
				
				</div>
			</div>
		</div>

		<DynamicModal :title=modal_title>
			<template #body>
				<form @submit.prevent="updateLevels" enctype="multipart/form-data">

					<div class="col-md-12 form-group">
						<label class="control-label">Level {{level.level}}<span class="text-danger">*</span></label>
						<input class="form-control" type="text" v-model="level.value" v-bind:class="{ 'is-invalid': Boolean(errors.value) }"/>

						<div v-for="(val, index) in errors.value" :key="index" class="invalid-feedback">
							<p class="mb-0">{{ val }}</p>
						</div>
						
					</div>

					<div class="col-md-12 form-group mt-3">
						<button type="submit" class="btn btn-primary me-3" >{{ button_title }}</button>
						<button class="btn btn-light" type="button" @click="closeModal()">Close</button>
					</div>
				</form>
			</template>
		</DynamicModal>

		
	</div>
</template>

<script>
import { onMounted } from 'vue'
import {Modal} from "bootstrap"
import DynamicModal from '@/components/dynamic_modal.vue'
	let dynamicmodal;

	export default {
		name: "Commission Page",
		components: {
			DynamicModal
		},
		setup(){
			onMounted(() => {
				dynamicmodal = new Modal(document.getElementById('dynamicmodal'));
			})

			const showModal = () => {
				dynamicmodal.show();
			}

			const hideModal = () => {
				dynamicmodal.hide();
			}

			return {
				showModal, hideModal
			}
		},

		data() {
			return {

				modal_title: "Update Commission",
				button_title: "Update",
				
				token: "",
				errors: {},

				levels: [],

				level: {
					id: "",
					level: "",
					value: "",
				},

				image: "",
                preview_image: "",

				mlmImage: "",


			};
		},

		created() {
			this.fetchLevels();
			this.fetchMlmImage();
		},

		methods: {
			
			getToken() {
				if (localStorage.getItem('bestdeals_admin')) {
					var bestdeals_admin =  JSON.parse(localStorage.getItem('bestdeals_admin')); 
					
					if(bestdeals_admin.token) {

						this.token = bestdeals_admin.token;
						this.user = bestdeals_admin;

					} else {
						this.$swal("", "Failed to fetch token, please login again", "error");
						this.$router.push({ name: "login" });
					}

				} else {
					this.$swal("", "Session expired, please login again", "error");
					this.$router.push({ name: "login" });
				}
          	},

			fetchLevels(page_url = null) {
				this.getToken();
				let url = "/api/CommissionSettings";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.levels = responseData.commission_settings;
						// this.settings = responseData.settings;
						
					})
					.catch((err) => console.log(err));
			},

			fetchMlmImage(page_url = null) {
				this.getToken();
				let url = "/api/MlmTreeImage";
				page_url = page_url || url;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						console.log(responseData);
						this.mlmImage = responseData.mlm_tree_img;
						
					})
					.catch((err) => console.log(err));
			},

			setFile(e) {
                this.image = e.target.files[0];
                this.preview_image = URL.createObjectURL(e.target.files[0]);
            },
			
			openModal() {
				this.showModal();
			},

			closeModal() {
				this.hideModal();
			},

			editLevel(level) {
				this.level.id = level.id;
				this.level.level = level.level;
				this.level.value = level.value;

				this.errors = {};
				this.showModal();
			},

			updateLevels() 
			{
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/UpdateCommissionSettings";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("level_id", this.level.id); 
				formData.append("value", this.level.value); 
				
				this.$axios
				.post(url, formData, config)
				.then((response) => {
				if (response.data.status) {
				   this.$swal("", response.data.message, "success");
				} else {
				   this.$swal("", response.data.message, "error");
				}
				this.clearForm();
				this.closeModal();
				this.fetchLevels();
				this.$router.push({ name: "levels" });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
						if (error.response.status == 422) {
							if (error.response.data) {
								this.errors = error.response.data.message;
							}
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 500) {
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 401) {
							this.$swal("", error.response.statusText, "error");
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					} else {
						this.$swal("", error.message, "error");
					}
				})
				.finally(() => (this.loading = false));
			},

			
			updateImage() 
			{
				this.getToken();

				const config = {
					headers: {
					"Content-type": "multipart/form-data",
					Accept: "application/json",
					Authorization: "Bearer " + this.token,
					},
				};

				let url = "api/UpdateMLMTreeImage";
				{
					{
					this.token;
					}
				}

				let formData = new FormData();
				formData.append("image_id", this.mlmImage.id); 
				formData.append("image", this.image); 
				
				this.$axios
				.post(url, formData, config)
				.then((response) => {
				if (response.data.status) {
				   this.$swal("", response.data.message, "success");
				} else {
				   this.$swal("", response.data.message, "error");
				}
				this.clearForm();
				this.fetchLevels();
				this.$router.push({ name: "levels" });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					if (error.response) {
						if (error.response.status == 422) {
							if (error.response.data) {
								this.errors = error.response.data.message;
							}
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 500) {
							this.$swal("", error.response.statusText, "error");
						} else if (error.response.status == 401) {
							this.$swal("", error.response.statusText, "error");
						} else {
							this.$swal("", "Something went wrong", "error");
						}
					} else {
						this.$swal("", error.message, "error");
					}
				})
				.finally(() => (this.loading = false));
			},

			clearForm() 
			{
				this.errors = {};
			},
			
		},

	};
</script>